
// ClevDoc Contact API 

const commonFormData = {
  email: "",
  name: "",
  phone: "",
  message: ""
};
export const ContactAPI = async (formData, type) => {
  try {
    const mergedFormData = { ...commonFormData, ...formData, type };

    const response = await fetch('https://client-clevdoc-testing-db68938bf14c.herokuapp.com/v1/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(mergedFormData)
    });

    if (response.ok) {
      return {
        status: response.status,
        data: await response.json()
      };
    } else {
      let errorMessage ;
      try { 
        const errorData = await response.json();
        if (errorData.error) {
          errorMessage = errorData.error[0];
          // 
        }
      } catch (error) {
        console.error('Error parsing error response:', error);
      }
      
      return {
        status: response.status,
        errorMessage: errorMessage
      };
    }
  } catch (err) {
    return {
      status: 500, // Internal Server Error
      errorMessage: 'Internal server error'
    };
  }
};

// Borrower Individual Get Data API

export const fetchIndividualData = async (jwtToken) => {
  try {
      const response = await fetch(
          `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/user`,
          {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${jwtToken}`,
              },
          }
      );

      if (response.ok) {
          const data = await response.json();
          return data;
      } else {
          throw new Error(`Failed to fetch user data: ${response.statusText}`);
      }
  } catch (error) {
      throw new Error('Error retrieving user data:', error);
  }
};

// Borrower Organization Get Data API

export const fetchOrganizationData = async (jwtToken) => {
  try {
      if (!process.env.REACT_APP_PLATFORM_API_BASE_URL) {
          console.error('API base URL not found.');
          return;
      }

      const response = await fetch(
          `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/organization`,
          {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${jwtToken}`,
              },
          }
      );

      if (response.ok) {
          const data = await response.json();
          return data;
      } else {
          throw new Error(`Failed to fetch organization data: ${response.statusText}`);
      }
  } catch (error) {
      throw new Error('Error retrieving organization data:', error);
  }
};