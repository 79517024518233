import React from 'react'
import PlatformsDetails from '../Elements/PlatformsDetails'
import UsesBenefits from '../Elements/UsesBenefits'
import Demo from '../Elements/Demo'
import FAQs from '../Elements/FAQs'

const BankingLanding = () => {
    return (
        <>
            <PlatformsDetails />
            <UsesBenefits />
            {/* <Demo /> */}
            <FAQs />
        </>
    )
}

export default BankingLanding