export const UploadNotice = async (jwtToken, formData, loanId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/upload?loan_id=${loanId}`,
            {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            }
        );

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Failed to post organization data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Failed to load data.', error);
    }
};


export const ServeNotice = async (jwtToken, noticeData) => {
    const secondApiBody = {
        source: noticeData.source,
        subject: noticeData.subject,
        loan: noticeData.loanId,
        type: noticeData.type,
        envelope_id: noticeData.envelope_id
    };
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/notice/serve`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                },
                body: JSON.stringify(secondApiBody),
            }
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Failed to post organization data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Failed to load data.', error);
    }
};


export const NoticeStatusUpdate = async (jwtToken, statusPayload) => {
    const dataToSend = {
        loan_id: statusPayload?.loanId,
        type: statusPayload?.type,
        status: statusPayload?.status,
        bank_id: statusPayload?.bank_id
    }
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/loans/notice_status/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwtToken}`,
                },
                body: JSON.stringify(dataToSend),
            }
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Failed to post organization data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Failed to load data.', error);
    }
};

export const NoticeStatus = async (jwtToken, loanId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/loans/sent_notices_status/${loanId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                },
            }
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Failed to post organization data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Failed to load data.', error);
    }
};


export const GetEnvId = async (jwtToken, envPayload) => {
    const dataToSend = {
        loan_id: envPayload?.loan_id,
        type: envPayload?.type
    }
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/genrateEnvelopeId`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwtToken}`,
                },
                body: JSON.stringify(dataToSend),
            }
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Failed to post organization data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Failed to load data.', error);
    }
};