import React from 'react'
import { FaFacebookF, FaLinkedinIn, FaWhatsapp} from 'react-icons/fa'
import { FiTwitter } from "react-icons/fi";
import { Link } from 'react-router-dom'

const SocialIcons = () => {
    return (
        <>
            <div className="flex space-x-4">
                {/* Whatsapp */}
                <Link
                    to=""
                    // target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className=' bg-white text-green-700 rounded-full p-1 hover:text-white hover:bg-green-700'>
                        <FaWhatsapp size={26} className="inline-block" />
                    </div>
                </Link>

                {/* LinkedIn */}
                <Link
                    to="https://in.linkedin.com/company/clevdoc"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className='bg-white text-blue-400 rounded-full p-1 hover:text-white hover:bg-blue-400'>
                        <FaLinkedinIn size={26} className="inline-block " />
                    </div>
                </Link>

                {/* Instagram */}
                <Link
                    to="https://twitter.com/@clevdoc"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className=' bg-white text-blue-700 rounded-full p-1 hover:text-white hover:bg-blue-700'>
                    <FiTwitter size={27} className="inline-block" />
                    </div>
                </Link>

                {/* Facebook */}
                <Link
                    to="https://www.facebook.com/clevdoc/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className=' bg-white text-blue-600 rounded-full p-1 hover:text-white hover:bg-blue-600'>
                        <FaFacebookF size={26} className="inline-block" />
                    </div>
                </Link>
            </div>
        </>
    )
}

export default SocialIcons