import Swal from "sweetalert2";

export function showFailed(title, text){
    Swal.fire({
        icon: "error",
        title: title,
        text: text,
      });
}

export function showWarning(title, text){
  Swal.fire({
      icon: "warning",
      title: title,
      text: text,
    });
}