import React, { useEffect, useState } from 'react'
import { FaPhoneAlt, FaSpinner } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import SocialIcons from '../../../Landing-Page/Elements/SocialIcons';
import { ContactAPI } from '../../../../API/ClevDocContact';

const BankingDashboardFooter = () => {
    const phoneNumber = '1800-XXX-2000';

    const handlePhoneNumberClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    }
    const [formData, setFormData] = useState({
        email: ''
    })
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (successMessage || errorMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                setErrorMessage('');
            }, 5000); // 5 seconds
            return () => clearTimeout(timer);
        }
    }, [successMessage, errorMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    }

    const handleSubmit = async (e, type) => {
        e.preventDefault();
        

        const validationErrors = {};

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setIsLoading(true);
            try {
                const response = await ContactAPI(formData, type);
                setIsLoading(false);
                if (response.status === 200) {
                    setFormData({
                        email: ''
                    });
                    setSuccessMessage('Subscribed!');
                    setErrorMessage('');
                    
                } else {
                    if (response.errorMessage) {
                        
                        setFormData({
                            email: ''
                        });
                        setErrorMessage(response.errorMessage);
                    } else {
                        console.error('API call failed with status:', response.status);
                        setErrorMessage('An error occurred while processing your request.');
                    }
                }
                
            } catch (error) {
                setFormData({
                    email: ''
                });
                console.error('Error calling API:', error);
            }
        }
    }

    return (
        <>
            <footer className="bg-bgclr5 pb-4">
                <div className="mx-auto w-full max-w-screen-xl">
                    <div className="grid grid-cols-1 gap-2 px-4 py-6 md:py-8 md:pl-10 md:pr-12 mb-6 md:grid-cols-5">
                        <div>
                            <h2 className="mb-6 text-xl font-bold text-txtclr4 dark:text-white"><span className='underline underline-offset-[7px] decoration-2'>Solu</span>tions</h2>
                            <ul className="text-white font-semibold font-md">
                                <li className="mb-2">
                                    <Link to='/banking' className="hover:underline">Banking</Link>

                                </li>
                                <li className="mb-2">
                                    {/* <Link to='/legal' className="hover:underline"> */}
                                    Legal
                                    {/* </Link> */}
                                </li>
                                <li className="mb-2">
                                    {/* <Link to='/v-doc' className="hover:underline"> */}
                                    Document
                                    {/* </Link> */}
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-xl font-bold text-txtclr4 dark:text-white"><span className='underline underline-offset-[7px] decoration-2'>Impor</span>tant Links</h2>
                            <ul className="text-white font-semibold font-md">
                                <li className="mb-2">
                                    <Link to='/banking/borrowerDashboard/certificate' className="hover:underline">Proof of Registration</Link>

                                </li>
                                <li className="mb-2">
                                    <Link to='/banking/borrowerDashboard/inbox' className="hover:underline">Inbox</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to='/banking/borrowerDashboard/linkedLoans' className="hover:underline">Linked Loans</Link>
                                </li>
                                {/* <li className="mb-2">
                                    <Link to='/banking/borrowerDashboard/' className="hover:underline">Account Settings</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-xl font-bold text-txtclr4 dark:text-white"><span className='underline underline-offset-[7px] decoration-2'>Abou</span>t Us</h2>
                            <ul className="text-white font-semibold font-md">
                                <li className="mb-2">
                                    {/* <Link to='/' className="hover:underline"> */}
                                    Platform
                                    {/* </Link> */}
                                </li>
                                <li className="mb-2">
                                    {/* <Link to='/' className="hover:underline"> */}
                                    Uses/Benefits
                                    {/* </Link> */}
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-xl font-bold text-txtclr4 dark:text-white"><span className='underline underline-offset-[7px] decoration-2'>Con</span>tact </h2>
                            <ul className="text-white font-semibold font-md">
                                <li className="mb-2">
                                    <Link to='/contact' className="hover:underline">Contact Us</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to='/banking/borrowerDashboard/help' className="hover:underline">Help</Link>
                                </li>
                                <li className="mb-2">
                                    {/* <Link to='//banking/borrowerDashboard/' className="hover:underline"> */}
                                    FAQs
                                    {/* </Link> */}
                                </li>
                                <li className="mb-2">
                                    <Link to='/privacy&policy' className="hover:underline">
                                        Privacy
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    <Link to='/t&c' className="hover:underline">
                                        Terms of use
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className=''>
                            <h2 className="mb-6 text-xl font-bold text-txtclr4 dark:text-white"><span className='underline underline-offset-[6px] decoration-2'>News</span>Letter</h2>
                            <form onSubmit={(e) => handleSubmit(e, 'subscribe')}>
                                <div className='pb-3'>
                                    {errorMessage && (
                                        <div className="text-red-600 font-semibold mb-1">{errorMessage}</div>
                                    )}
                                    {successMessage && (
                                        <div className="text-green-600 font-semibold mb-1">
                                            {successMessage}
                                        </div>
                                    )}
                                    <input
                                        type="email"
                                        placeholder='Enter Email'
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="input-style"
                                        required
                                    />
                                    <button
                                        type="submit"
                                        className="text-center mt-2 bg-bgclr4 hover:bg-bg-btnhover text-white font-bold py-2 md:px-4 px-4 rounded-md focus:outline-none focus:shadow-outline"
                                    >
                                        {isLoading ? <FaSpinner size={20} className="animate-spin text-3xl" /> : 'Subscribe'}
                                    </button>
                                </div>
                            </form>
                            <hr className='flex w-3/4 pb-4 mt-2' />
                        </div>
                    </div>
                    <div className='md:flex justify-between'>
                        <div className='md:flex pl-4'>
                            <h1 className='text-xl font-bold mb-2 md:mb-0 text-txtclr4 pr-5'>Follows Us at</h1>
                            <SocialIcons />
                        </div>
                        <div className='md:flex pl-4 pt-3 md:pt-0 md:pl-0 pr-12'>
                            <div>
                                <h1 className='text-xl font-bold mb-2 md:mb-0 text-txtclr4 pr-5'>Get the App</h1>
                            </div>
                            <div className='flex gap-3'>
                                <img src="https://cdn.clevdoc.com/assets/footer/app-store-btn2.jpg" className='w-32 rounded' alt="Unavailable" />
                                <img src="https://cdn.clevdoc.com/assets/footer/google-play-btn2.jpg" className='w-32 rounded' alt="unavailable" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default BankingDashboardFooter