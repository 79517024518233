import React from 'react'

const TermCondition = () => {
    return (
        <>

            <div className='bg-bgclr8 md:pt-28 lg:pt-32 xl:pt-28 pt-28'>
                <div className='container mx-auto'>

                    <div className='md:px-32 py-10 px-5'>
                        <div className='flex md:text-[54px] text-[40px]'>
                            <h1>Term of Use</h1>
                        </div>
                        <div className='pl-1 -mt-2 mb-1 text-red-600'>
                            <span>________</span>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                We, at ClevDoc Platforms Inc. (hereinafter referred to as the “ClevDoc Platforms”, “We”
                                or “Us”) is a Partnership Firm, having its corporate office at Plot Number 858, Meta Mata
                                House, Lower Ground Floor, Udyog Vihar Phase V, Sector 19, Gurugram, Haryana –
                                122016. These Terms of Use govern your access to and use of our website. By accessing or
                                using our site, you agree to comply with these terms and conditions. Please read them
                                carefolly before proceeding.
                            </p>
                            <p className='mt-6'>
                                If you do not agree with any part of these terms, you may not access the website or use any of
                                its services. These terms outline your rights and responsibilities when using our platform and
                                provide important information about your legal rights, so we encourage you to review them
                                thoroughly. Thoroughly review this policy to understand how we manage your information.

                            </p>
                            <p className='mt-6'>
                                By accessing our Website or Application, you acknowledge and accept the conditions
                                outlined in this Policy. If these terms are unacceptable to you, kindly refrain from using our
                                Application or Website. Please note that this Policy is subject to periodic changes in order to
                                maintain conformity with the prevailing laws.
                            </p>
                            <p className='mt-6'>
                                By accessing and using the website or Application, users agree to abide by the following
                                terms and conditions. These terms and conditions are subject to revision or modification by
                                us. at any time without prior notice to the user. Continued use of the site after such changes
                                constitutes acceptance of the updated terms and conditions.
                            </p>
                            <ol className='list-decimal md:ml-10 ml-5 mt-6'>
                                <li className='mt-3'> The domain www.clevdoc.com is the property of ClevDoc Platforms. All content
                                    available on this website, comprising software, text, images, graphics, video, and audio, is
                                    the exclusive and proprietary material owned by us. We hold foll rights to this content,
                                    and any use, reproduction, or distribution of this material requires explicit authorization
                                    from us.
                                </li>
                                <li className='mt-3'> The name and logo of ClevDoc Platforms Inc. are trademarks owned by us. Any use of
                                    these trademarks without prior written consent from us is strictly prohibited and may not
                                    be used in any manner whatsoever.</li>
                                <li className='mt-3'> Unauthorized use of any material from the website, including copying, modifying,
                                    reproducing, republishing, uploading, transmitting, posting, or distributing in any form, is
                                    strictly prohibited without prior written consent from us. All rights not explicitly granted
                                    herein are reserved by us.
                                </li>
                                <li className='mt-3'> Any unauthorized use of materials on the website may constitute a violation of copyright,
                                    trademark, and other applicable laws, potentially leading to criminal or civil penalties. We
                                    reserve the right to monitor access to the site for compliance with these terms and
                                    applicable laws.
                                </li>
                                <li className='mt-3'> We reserve the right to terminate a user's access to the site at any time, and for any reason. Upon termination, provisions related to disclaimers of warranty, information accuracy, and indemnification will remain in effect..
                                </li>
                                <li className='mt-3'> We do not provide any express or implied warranties, including but not limited to
                                    merchantability or fitness for a particolar purpose, concerning the information, data,
                                    statements, or products available on the site.
                                </li>
                                <li className='mt-3'> The website, along with all its content, materials, information, software, products, and services offered, is provided 'as is' and 'as available' without any warranties or guarantees.</li>
                                <li className='mt-3'> By using the services and content provided on the site, the user agrees to indemnify, defend, and hold us harmless from any losses, expenses, damages, or costs, arising from or related to the user's misuse of the site's content and services.</li>
                                <li className='mt-3'> While the information presented on the site is sourced from believed reliable sources, ClevDoc Platforms Inc. disclaims any warranties regarding the accuracy, completeness, or adequacy of such information.</li>
                                <li className='mt-3'> We hold no responsibility for third-party content accessed through the site, including opinions, advice, statements, and advertisements. Users assume all risks associated with using such content, and any dealings with third parties are at the user's own risk. We cannot be held responsible for any loss or damage resolting from interactions with third parties.</li>
                                <li className='mt-3'> We do not provide any warranty that: (a) the site will meet individual requirements; (b) the site will be continuously available, timely, secure, or free from errors; (c) the resolts obtained from using the site or its services will be accurate or reliable.</li>
                                <li className='mt-3'> We stands indemnified from any loss, harm, cost or claim that may arise due to any act,
                                    omission or commission committed by any user or visitor of this Website or Platform
                                    and therefore disclaims itself from any liability thereof.
                                    </li>
                                <li className='mt-3'> In no event shall ClevDoc Platforms Inc. be liable for any indirect, consequential, or
                                    punitive damages, arising out of or in connection with your use of our platform.
                                </li>
                                <li className='mt-3'> We reserve the right to terminate or suspend your access to our platform at any time,
                                    without prior notice, for any reason whatsoever.
                                </li>
                                <li className='mt-3'> We reserve the right to amend these terms at any time.</li>

                                <h1 className='flex md:text-[35px] text-[30px] font-semibold text-txtclr3 md:-ml-10 -ml-7 mt-10'>Refund Policy</h1>

                                <li className='mt-3'> By using our services, you acknowledge that charges may be incurred before, during, or
                                    after receiving the services. We reserve the right to collect payment for the services
                                    rendered.
                                </li>
                                <li className='mt-3'> Charges may be levied for the usage of our proprietary technology and any third-party
                                    technology utilized in providing our services.
                                </li>
                                <li className='mt-3'> Charges and fees paid by you are generally non-refundable, unless otherwise agreed upon
                                    with us.
                                </li>
                                <li className='mt-3'> We reserve the right to establish, remove, and/or revise charges for any or all of our
                                    services at our discretion.</li>
                                <li className='mt-3'>Your request for a refund must meet the criteria of encountering technical issues or errors
                                    that substantially impair your ability to use the platform as intended, and our support team
                                    is unable to resolve the issue within a reasonable timeframe.
                                </li>
                                <li className='mt-3'> Refunds are not guaranteed and will be granted at our sole discretion. Our team will
                                    review your request and respond to you as soon as possible, typically within 7 working
                                    days.
                                </li>
                                <li className='mt-3'> If your refund request is approved, we will initiate a refund to your original method of
                                    payment within 7 working days from the date of the approval.
                                </li>
                                <li className='mt-3'> Any applicable processing fees or charges incurred during the refund process will be
                                    deducted from the refunded amount.
                                </li>
                                <li className='mt-3'> If you have any questions about our refund policy, please feel free to contact us at
                                    support@clevdoc.com.</li>

                                <h1 className='flex md:text-[35px] text-[30px] font-semibold text-txtclr3 md:-ml-10 -ml-7 mt-10'>Boiler Plate</h1>

                                <li className='mt-3'> If any provision of these terms is found invalid or unenforceable, the remaining
                                    provisions shall remain in foll force and effect.
                                </li>
                                <li className='mt-3'>The platform may contain links to third-party websites or services, and disclaim
                                    responsibility for the content or actions of these third parties.
                                </li>
                                <li className='mt-3'> The platform may be accessed from locations outside your country of operation and that
                                    by accessing the platform, they agree to comply with local laws and regolations.
                                </li>
                                <li className='mt-3'>  We shall not be liable for any failure or delay in performance due to circumstances beyond its reasonable control, including but not limited to acts of God, war, terrorism, governmental actions, natural disasters, or other events beyond the company's control.
                                </li>

                                <h1 className='flex md:text-[35px] text-[30px] font-semibold text-txtclr3 md:-ml-10 -ml-7 mt-10'>Dispute Resolution</h1>

                                <li className='mt-3'> Any disputes arising with regard to the use of this Website shall be governed by the
                                    laws of India, with Courts of New Delhi, Delhi having exclusive jurisdiction.
                                </li>
                                <li className='mt-3'> Any dispute or difference that may arise in or out of connection with the subject matter
                                    of this terns of use, of the App or Website, shall be referred to Arbitration. The arbitral
                                    tribunal shall consist of a sole arbitrator to be appointed by us. The seat of arbitration
                                    shall be at New Delhi.
                                </li>
                                <li className='mt-3'> By accessing and using our platform, you acknowledge that you have read, understood,
                                    and agree to abide by these terms and conditions.</li>
                            </ol>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default TermCondition