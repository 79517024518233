import React, { useEffect, useState } from 'react'
import Buttons from '../../../CommonFunction/Buttons';
import { MdEmail, MdLocationPin } from 'react-icons/md';
import { validateForm } from '../../../CommonFunction/FormValidation';
import { ContactAPI } from '../../../API/ClevDocContact';

const ContactUs = () => {

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  })
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  }

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      let response;
      setIsLoading(true);
      try {
        response = await ContactAPI(formData, type);
        // 
        setIsLoading(false);

        if (response.status === 200) {
          setFormData({
            name: '',
            phone: '',
            email: '',
            message: ''
          });
          setSuccessMessage('Your message has been successfully submitted.');
          setErrorMessage('');
          
        } else {
          // Handling API error response
          if (response.errorMessage) {
            
            setFormData({
              name: '',
              phone: '',
              email: '',
              message: ''
            });
            setErrorMessage(response.errorMessage);
          } else {
            // If there is no error message in the response, handle it accordingly
            console.error('API call failed with status:', response.status);
            setErrorMessage('An error occurred while processing your request.');
          }
        }
      } catch (error) {
        console.error('Error during API call:', error);
        setErrorMessage('An error occurred while processing your request.');
      }
    }
  }

  return (
    <>
      <div className='md:pt-28 lg:pt-32 xl:pt-28 pt-28 font-avenir'>
        <div className='bg-bgclr8 '>
          <div className='mt-2 container mx-auto '>
            <div className='md:px-32 md:py-10 px-5 py-10'>
              <div className='flex text-[54px]'>
                <h1>Contact us</h1>
              </div>
              <div className='pl-1 -mt-2 mb-1 text-red-600'>
                <span>________</span>
              </div>
              <h1 className='text-[22px]'>We appreciate your interest in connecting with us. Whether you have queries regarding our services or require assistance, our proficient team stands ready to assist you. Please feel free to reach out to us using the contact form provided below, or alternatively, you can contact us directly via email. We prioritize prompt responses and are committed to addressing your inquiries in a timely manner.</h1>
            </div>
          </div>
        </div>
        <div className='bg-bgclr8 shadow-md'>
          <div className='container mx-auto md:py-10 py-10'>
            <div className='md:mx-24 mx-5 border bg-bgclr9 rounded shadow-2xl'>
              <div className='grid md:grid-cols-5 md:py-32 py-10'>
                <div className='md:flex md:col-span-2 md:border-r-2 mx-2 py-10 md:py-0 border-gray-300 justify-center'>
                  <div className='grid md:mt-8 md:px-0 '>
                    <div>
                      <div className='flex justify-center text-txtclr3'>
                        <MdLocationPin size={40} />
                      </div>
                      <div className='flex justify-center pt-1 text-txtclr3'>
                        <h1 className='font-semibold text-xl'>Corporate Address</h1>
                      </div>
                      <div className='text-center text-sm text-gray-500 font-semibold'>
                        <h1>Meta Mata House,</h1>
                        <h1>Plot No. 858,</h1>
                        <h1>Lower Ground Floor,</h1>
                        <h1>Udyog Vihar Phase 5,</h1>
                        <h1>Gurugram - 122016, India.</h1>
                      </div>
                    </div>

                    <div>
                      <div className='flex justify-center text-txtclr3'>
                        <MdEmail size={40} />
                      </div>
                      <div className='flex justify-center pt-1 text-txtclr3'>
                        <h1 className='font-semibold text-2xl'>Email</h1>
                      </div>
                      <div className='text-center text-sm text-gray-500 font-semibold'>
                        <p>
                          <a href="mailto:team@clevdoc.com">team@clevdoc.com</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='md:col-span-3 flex justify-center mx-3'>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.223987529184!2d77.08315837604228!3d28.502907989954316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d192012d372ed%3A0xc45c5e15606c62cc!2sClevdoc%20Platforms%20Inc.!5e0!3m2!1sen!2sin!4v1707465122166!5m2!1sen!2sin" className='md:w-[520px] md:h-[450px]' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mb-14'>
          <div className="container mx-auto md:px-36 px-2 py-10">
            <div className='md:mx-5 mx-6'>
              <div className='md:text-[35px] text-[32px] text-center md:text-left font-semibold text-txtclr3'>
                <h1 className='text-center md:text-left'>
                  Send us a message
                </h1>
              </div>
              <div>
                <p className='text-center md:text-left text-[18px]'>Reach out to us to get a proposal of your need, any assitance and we will get back to you at the earliest.</p>
              </div>
            </div>
            <div className="flex bg-white md:px-24 px-3 rounded-xl shadow-xl border mt-2 py-14">
              <form onSubmit={(e) => handleSubmit(e, 'contact')} className="w-full md:px-4 mx-auto md:ml-0">
                {errorMessage && (
                  <div className="text-red-600 font-semibold mb-1">
                    {errorMessage}
                  </div>
                )}
                {successMessage && (
                  <div className="text-green-600 font-semibold mb-1">
                    {successMessage}
                  </div>
                )}
                {/* Name */}
                <div className='pb-3'>
                  <label htmlFor="name" className="block text-sm font-medium text-txtclr2">
                    Name<span className='text-red-600'>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder='Enter Name'
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="input-style"
                  />
                  {errors.name && <span className='text-red-600 font-semibold'>{errors.name}</span>}
                </div>

                {/* Phone number */}
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Phone<span className='text-red-600'>*</span>
                  </label>
                  <input
                    type="tel"
                    maxLength="10"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="input-style"
                    placeholder="Mobile No."
                  />
                  {errors.phone && <span className='text-red-600 font-semibold'>{errors.phone}</span>}
                </div>

                {/* Email ID */}
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Email ID<span className='text-red-600'>*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="input-style"
                    placeholder="Email ID"
                  />
                  {errors.email && <span className='text-red-600 font-semibold'>{errors.email}</span>}
                </div>

                <div className="col-span-2 mb-1">
                  <label htmlFor="message" className="block text-sm font-medium text-txtclr2">
                    Message<span className='text-red-600'>*</span>
                  </label>
                  <textarea
                    type="text"
                    placeholder='Write your question here....'
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="input-style"
                  />
                  {errors.message && <span className='text-red-600 font-semibold'>{errors.message}</span>}
                </div>

                <div className="mt-3">
                <Buttons.Button2 isLoading={isLoading} label="Send now" />
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs