import { showFailed } from "../../CommonFunction/SweetAlert";

export const UploadPOR = async (jwtToken, formData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/upload/por`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
                body: formData
            }
        );

        if (response.ok && response.status === 200) {
            const data = await response.json();
            return data;
        } else {
            showFailed('Failed to Upload Document.', `${response.statusText}`);
        }
    } catch (error) {
        showFailed('Failed to Upload Document.', `${error}`);
    }
};


