import React, { useEffect, useState } from "react";
import cookies from "../../../../CommonFunction/cookies";
import AmountToWord from "../../Banker-Dashboard/Elements/AmountToWord";
import Loader from "../../../../CommonFunction/Loader";
import { useParams, useNavigate } from "react-router-dom";

const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

const LoanSearchProfile = () => {
  let { uci } = useParams();
  const navigate = useNavigate();
  const [loanData, setLoanData] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state
  const jwtToken = cookies.get("jwt");

  useEffect(() => {
    const fetchLinkedLoans = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/user?type=system_id&value=${uci}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setLoanData(data);
          setLoading(false);
        } else {
          throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
      } catch (error) {
        setLoading(false); 
        throw new Error("Error retrieving user data:", error);
      }
    };

    fetchLinkedLoans();
  }, [jwtToken, uci]);

  // Render loader while data is being fetched
  if (loading) {
    return(
      <div className="mr-96">
    <Loader />
    </div> 
    );
  }

  return (
    <>
      <div className="w-[80%]  pb-20 pt-2 md:pt-4 md:ml-2">
        <div className="w-[80%]  pb-20 pt-2 md:pt-8 md:ml-2">
        <div className='flex justify-end pt-6 text-xl font-bold'>
        <button onClick={() => navigate("/banking/bankDashboard/loanSearch")}>X</button>
                    </div>
          <div className="pb-2">
            <div className="flex">
              <h1 className="pl-8 pt-5 pb-2 text-2xl font-bold text-txtclr3">
                User Details
              </h1>
            </div>
            <div className="mt-5 bg-bgclr9 md:mx-10 mx-4 px-5 md:py-5 py-2 border border-bgclr rounded-md shadow-md">
              <div>
                <h2 className="lg:pl-20 pl-5 text-lg font-bold underline text-txt-clr2">
                  Basic Info
                </h2>
              </div>
              <div className="lg:pl-28 pl-10 leading-8">
                <div className="flex">
                  <h2>User Name: </h2>
                  <h2 className="pl-5 font-semibold">
                    {loanData?.first_name}  {loanData?.last_name}
                  </h2>
                </div>
                <div className="flex">
                  <h2>Email: </h2>
                  <h2 className="pl-5 font-semibold">{loanData?.email}</h2>
                </div>
                <div className="flex">
                  <h2>Phone No.: </h2>
                  <h2 className="pl-5 font-semibold">{loanData?.phone_number}</h2>
                </div>
                <div className="flex">
                  <h2>Aadhar No.: </h2>
                  <h2 className="pl-5 font-semibold">
                    {loanData?.personal?.adhaar_card?.number}
                  </h2>
                </div>
                <div className="flex">
                  <h2>Address: </h2>
                  <h2 className="pl-5 font-semibold">
                    {loanData?.address.city}  {loanData?.address?.country} 
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="py-2">
            <div className="mt-5 bg-bgclr9 md:px-5 md:mx-10 mx-4 px-5 md:py-5 py-2 border border-bgclr rounded-md shadow-md">
              <div>
                <h2 className="lg:pl-20 pl-5 text-lg font-bold underline text-txt-clr2">
                  Linked Loans
                </h2>
              </div>
              <div className="lg:pl-28 pl-10 leading-8">
                <div className="flex">
                  <h2>Loans: </h2>
                  <h2 className="pl-5 font-semibold">
                    {loanData?.linked_loans.map((data,index) => (
                        <li key={index}>{data}</li>
                    ))}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanSearchProfile;
