import { showFailed } from "../../CommonFunction/SweetAlert";

export const forgotPassword = async (payload) => {
    const ucid = {
        cuid: payload
    }
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/resetPasswordEmail`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(ucid)
            }
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            showFailed(`Failed to forgot Password`, `${response.statusText}`);
        }
    } catch (error) {
        showFailed('Failed to load data.', error);
    }
};

export const resetNewPassword = async (ucid, password) => {
    const payload = {
        systemId: ucid,
        new_password: password
    }
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/forgetPassword   `,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload)
            }
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            showFailed(`Failed to forgot Password`, `${response.statusText}`);
        }
    } catch (error) {
        showFailed('Failed to load data.', error);
    }
};

export const changePassword = async (formData, jwtToken) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/changePassword`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + jwtToken
                },
                body: JSON.stringify(formData)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            showFailed(`Failed to Change Password`, `${response.statusText}`);
            return data;
        }
    } catch (error) {
        showFailed('Failed to load data.', error);
    }
};