import React from 'react'
import Demo from '../Elements/Demo'
import { MdEmail } from 'react-icons/md'
import { FaBlenderPhone, FaPhoneAlt } from 'react-icons/fa'

const BankingHelp = () => {

  const phoneNumber = {
    firstNumber: '6394849878',
    secondNumber: '8415521578',
    tollFreeNumber: '180-5400-697'
  }
  const handlePhoneNumberClick1 = () => {
    window.location.href = `tel:${phoneNumber.firstNumber}`;
  }
  const handlePhoneNumberClick2 = () => {
    window.location.href = `tel:${phoneNumber.secondNumber}`;
  }

  const handleTollFreeNumber = () => {
    window.location.href = `tel:${phoneNumber.tollFreeNumber}`;
  }

  return (
    <>
      <div className='flex relative items-center justify-center bg-bgclr2 text-txtclr bg-cover h-56'>

        <div>
          <div className='justify-center'>
            <h1 className='text-6xl text-center font-bold'>Help Center</h1>
          </div>
          <p className='text-lg text-center font-semibold'>Guiding you through every step. Explore our help center for comprehensive support and solutions.
          </p>
        </div>
      </div>
      <Demo />

      <div className='flex justify-between p-28 bg-bgclr4  items-center'>
        <div className='border-r-2 pr-32'>
          <div className='flex justify-center text-txtclr'>
            <MdEmail size={50} />
          </div>
          <div className='flex justify-center pt-1 text-txtclr'>
            <h1 className='font-semibold text-2xl font-mono'>Email Us</h1>
          </div>
          <div className='text-center text-sm text-txtclr4 font-semibold'>
            <p>
              <a href="mailto:team@clevdoc.com">team@clevdoc.com</a>
            </p>
            <p>
              <a href="mailto:sales@clevdoc.com">sales@clevdoc.com</a>
            </p>
          </div>
        </div>
        <div className='border-r-2 pr-36'>
          <div className='flex justify-center text-txtclr'>
            <FaPhoneAlt size={40} />
          </div>
          <div className='flex justify-center pt-1 text-txtclr'>
            <h1 className='font-semibold text-2xl font-mono'>Call Us</h1>
          </div>
          <div onClick={handlePhoneNumberClick1} className='flex justify-center pt-1 text-sm text-txtclr4 font-semibold'>
            +91-{phoneNumber.firstNumber}
          </div>
          <div onClick={handlePhoneNumberClick2} className='flex justify-center text-sm text-txtclr4 font-semibold'>
            +91-{phoneNumber.secondNumber}
          </div>
        </div>
        <div>
          <div className='flex justify-center text-txtclr'>
            <FaBlenderPhone size={40} />
          </div>
          <div className='flex justify-center pt-1 text-txtclr'>
            <h1 className='font-semibold text-2xl font-mono'>Toll Free</h1>
          </div>
          <div onClick={handleTollFreeNumber} className='flex justify-center pt-1 text-sm text-txtclr4 font-semibold'>
            +91-{phoneNumber.tollFreeNumber}
          </div>
        </div>
      </div>

    </>
  )
}

export default BankingHelp