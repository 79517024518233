import React from 'react'

const UsesBenefits = () => {
  return (
    <>
      <div className='bg-bgclr8 py-16 border-t'>
      <div className='container mx-auto'>
      <div className='bg-bgclr3 text-txtclr md:mx-10 mx-5 mb-16 rounded-2xl p-5 shadow-2xl'>
        <div className=''>
          <h1 className='md:text-[35px] text-[30px] pl-10 font-semibold py-4'>Us<span className='underline underline-offset-[10px] decoration-2'>es / Ben</span>efits</h1>
        </div>
        <div className='grid md:grid-cols-2 gap-10 md:px-10 px-3 pb-10'>
          <div className='pt-10 flex justify-center items-center'>
            <img className='w-[1700px]' src="https://cdn.clevdoc.com/assets/dashboard/banking/bankingHome_1.png" alt="Not Available" />
          </div>
          <div className=''>
            <div className=''>
              <h1 className='text-2xl font-bold pb-3'>Find your Benefits:-</h1>
            </div>
            <div className=''>
              <ul className='list-disc leading-7 text-lg'>
                <li>One step solution for the efficient management of bad loans for financial institutions.
                </li>
                <li>Streamline record-keeping processes associated with the served Notices
                </li>
                <li>Facilitates the enforcement of securities and monitors recoveries
                </li>
                <li>Significantly reduces recovery initiation time from years to months.
                </li>
                <li>Automates features include issuing reminders to defaulting borrowers </li>
                <li>Elevates the oversight of stressed assets</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  )
}

export default UsesBenefits