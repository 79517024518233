export const fetchBlogs = async (jwtToken) => {
    try {
        const response = await fetch(
            `https://client-cms-testing-85bf0f79d917.herokuapp.com/v1/blogs/list?search=`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        );
  
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Error retrieving user data:', error);
    }
  };


// Get CMS User by ID
export const fetchBlogsbyId = async (id) => {
    try {
        const response = await fetch(
            `https://client-cms-testing-85bf0f79d917.herokuapp.com/v1/blogs/${id}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        );
  
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Error retrieving user data:', error);
    }
  };