import React, { useEffect, useState } from 'react';
import Loader from '../../../../../CommonFunction/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { showFailed, showWarning } from '../../../../../CommonFunction/SweetAlert';
import { decodeJwtToken } from '../../../../../CommonFunction/TokenDecode';
import cookies from '../../../../../CommonFunction/cookies';
import { UploadInvoice } from '../../../../../API/Borrower/UploadInvoice';
import jsPDF from 'jspdf';
import { fetchIndividualData, fetchOrganizationData } from '../../../../../API/ClevDocContact';
import { formatDate } from '../../../../../CommonFunction/FormatedDate';
import toast from 'react-hot-toast';

const PaymentProcess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [sameState, setSameState] = useState(false);
    const [userData, setUserData] = useState([]);
    const jwtToken = cookies.get('jwt');

    const decodedData = jwtToken ? decodeJwtToken(jwtToken) : null;

    const generateAndUploadPDF = (jwtToken, transactionId, data) => {
        const pdf = new jsPDF('p', 'pt', 'a4');

        pdf.setFontSize(24);
        pdf.setFont('helvetica', 'bold');
        pdf.text('TAX INVOICE CUM RECEIPT', 310, 40, { align: 'center' });

        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`Invoice No.: ${data?.invoice_no}`, 40, 80);
        pdf.text(`Invoice Date: ${formatDate(data?.updated_at)}`, 40, 100);
        pdf.text(`Transaction ID: ${transactionId}`, 40, 120);

        pdf.setLineWidth(0.5);
        pdf.line(40, 130, 555, 130);

        pdf.setFontSize(11);
        pdf.setFont('helvetica', 'bold');
        pdf.text('Billed To:', 40, 150);
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`${data?.first_name || ''} ${data?.middle_name || ''} ${data?.last_name || ''}`.trim() || `${data?.name || ''}`, 40, 170);
        pdf.text(`Address: ${data?.address?.permanent_address || ''}`.trim() || `${data?.address?.registered_address || ''}`, 40, 190);
        pdf.text(`PAN: ${data?.personal?.pancard}`, 40, 210);
        if (data?.personal?.gst_number) {
            pdf.text(`GSTIN: ${data?.personal?.gst_number || ''}`, 40, 230);
            pdf.text(`State: ${data?.address?.state || ''}`, 40, 250);
        } else {
            pdf.text(`State: ${data?.address?.state || ''}`, 40, 230);
        }

        pdf.setFontSize(11);
        pdf.setFont('helvetica', 'bold');
        pdf.text('From:', 300, 150);
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'normal');
        pdf.text('Clevdoc Platforms Inc.', 300, 170);
        pdf.text('Address: Plot No. 858, Lower Ground Floor, Gurgaon', 300, 190);
        pdf.text('State Code: 122016', 300, 210);
        pdf.text('PAN: FYQPP1194D', 300, 230);
        pdf.text('GSTIN: 23SED5F6TF6TG', 300, 250);

        pdf.line(40, 260, 555, 260);

        pdf.setFontSize(11);
        pdf.setFont('helvetica', 'bold');
        pdf.text('Description', 40, 280);
        pdf.text('HSN CODE', 260, 280);
        pdf.text('Unit', 340, 280);
        pdf.text('Price', 400, 280);
        pdf.text('Amount', 500, 280);

        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'normal');
        pdf.text('Banking Platform One Time Onboard Fees', 40, 300);
        pdf.text('998316', 270, 300);
        pdf.text('1', 350, 300);
        pdf.text('INR 1000', 390, 300);
        pdf.text('INR 1000', 500, 300);

        pdf.line(40, 320, 555, 320);

        pdf.setFontSize(10);
        pdf.text('Subtotal: ', 390, 340);
        if (sameState) {
            pdf.text('CGST 9%: ', 390, 360);
            pdf.text('SGST 9%: ', 390, 380);
            pdf.setFont('helvetica', 'bold');
            pdf.text('Grand Total: ', 390, 420);
        } else {
            pdf.text('IGST 18%: ', 390, 360);
            pdf.setFont('helvetica', 'bold');
            pdf.text('Grand Total: ', 390, 400);
        }

        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'normal');
        pdf.text('INR 1000', 500, 340);
        if (sameState) {
            pdf.text('INR 90', 500, 360);
            pdf.text('INR 90', 500, 380);
            pdf.setFont('helvetica', 'bold');
            pdf.text('INR 1180', 500, 420);
        } else {
            pdf.text('INR 180', 500, 360);
            pdf.setFont('helvetica', 'bold');
            pdf.text('INR 1180', 500, 400);
        }

        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'normal');
        pdf.text('This is an auto generated invoice and does not require a signature.', 40, 460);

        const pdfBlob = pdf.output('blob');
        const file = new File([pdfBlob], "invoice.pdf", { type: "application/pdf" });
        const formData = new FormData();
        formData.append("file", file);
        formData.append("invoice_no", data?.invoice_no);

        UploadInvoice(jwtToken, formData);
    };

    const PaymentProcessing = (data) => {
        const queryString = location.search;
        const urlParams = new URLSearchParams(queryString);
        const transactionId = urlParams.get('transactionId');

        setTimeout(() => {
            const paymentProcessing = async () => {
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/payment/checkstatus`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                userTransactionId: transactionId,
                                userId: decodedData._id
                            }),
                        }
                    );

                    const result = await response.json();

                    if (response.ok && response.status === 200) {
                        if (result.responseCode === "SUCCESS") {
                            generateAndUploadPDF(jwtToken, transactionId, data);
                            toast.success(`Payment Successfull. To download invoice, please go to the profile section`,
                                {
                                    duration: 4000,
                                    position: 'top-center',
                                }
                            );
                            navigate('/banking/borrowerDashboard/certificate');
                        }
                        else {
                            showFailed('Payment Failed.', 'Please check and try Again');
                            navigate('/banking/purchaseSummary');
                            console.error("Payment failed data:", result.error);
                        }
                    }
                } catch (error) {
                    showFailed('Failed.', `${error}`);
                    navigate('/banking/purchaseSummary');
                    console.error("Error during payment processing:", error);
                }
            };
            paymentProcessing();
        }, 3000)
    }

    const fetchDataAndUpdateUserData = async () => {
        try {
            const data = await fetchIndividualData(jwtToken);
            setUserData(data);
            if (data?.address?.state === "Haryana") {
                setSameState(true);
            }
            if (data && data?.step === 1) {

                showWarning('Profile Incomplete...', 'Your Profile is not completed, please complete your profile first');
                navigate('/banking/borrowerDashboard/1');
            } else if (data && data?.step === 2 && !data?.payment) {
                PaymentProcessing(data);
            } else if (data && data?.step === 3 && data?.payment) {
                navigate('/banking/borrowerDashboard/dashboard');
            }
        } catch (error) {
            console.error('Error retrieving user data:', error);
        }
    };

    const fetchOrgDataAndUpdate = async () => {
        try {
            const data = await fetchOrganizationData(jwtToken);
            setUserData(data);
            if (data?.address?.state === "Haryana") {
                setSameState(true);
            }
            if (data && data?.step === 1) {
                showWarning('Profile Incomplete...', 'Your Profile is not completed, please complete your profile first');
                navigate('/banking/borrowerDashboard/2');
            } else if (data && data?.step === 2 && !data?.payment) {
                PaymentProcessing(data);
            } else if (data && data?.step === 3 && data?.payment) {
                navigate('/banking/borrowerDashboard/dashboard');
            }

        } catch (error) {
            console.error('Error retrieving organization data:', error);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (jwtToken) {
                if (decodedData.role === 'user') {
                    fetchDataAndUpdateUserData();
                }
                if (decodedData.role === 'entity') {
                    fetchOrgDataAndUpdate();
                }
            } else {
                console.warn('JWT token is not available yet. Waiting...');
            }
        }, 3000);
    }, [jwtToken]);

    return (
        <>
            <div>
                <Loader />
            </div>
        </>
    );
}

export default PaymentProcess;
