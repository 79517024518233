import React, { useState } from "react";
import { Link } from "react-router-dom";
import EmptyDataAlert from "../Elements/EmptyDataAlert";

const BankInbox = () => {
  const [bookmarks, setBookmarks] = useState([]);
  const handleBookmarkClick = (id) => {
    // Check if the item is already bookmarked
    const isBookmarked = bookmarks.includes(id);

    if (isBookmarked) {
      // If already bookmarked, remove it from bookmarks
      const updatedBookmarks = bookmarks.filter(
        (bookmarkId) => bookmarkId !== id
      );
      setBookmarks(updatedBookmarks);
    } else {
      // If not bookmarked, add it to bookmarks
      setBookmarks([...bookmarks, id]);
    }
  };

  return (
    <>
      <div className="root-style">
        <div>
          <div className="searchBar-style">
            <input
              type="search"
              placeholder="Search"
              className="searchBar-input"
            />
            <button className="searchBar-btn">
              Search
            </button>
          </div>
          <div className="w-[975px] mt-6">
            <div className="px-5 pt-3">
              {/* <table className="w-full">
              <thead>
                <tr className="border-b border-bgclr2">
                  <th></th>
                  <th>Received On</th>
                  <th>Subject</th>
                  <th>Reference</th>
                  
                </tr>
              </thead>
              <tbody>
                {InboxDetail.map((data, index) => (
                  <tr key={data.id} className="border-b border-bgclr2">
                    <td className="border-l font-semibold border-r pr-3 border-bgclr2 pl-2 py-2 text-sm text-bgclr2 ">
                      <div>
                        <input type="checkbox" />
                      </div>
                    </td>
                   
                    <td>
                      <div className="flex items-center">
                        <h1 className="border-r pr-5 border-bgclr2 pl-2 py-2 text-sm text-bgclr2">
                          {data.date}
                        </h1>
                      </div>
                    </td>
                    <td>
                      <div className="my-4 pl-2 text-txtclr3 font-semibold leading-5">
                        {data.Detail}
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center ml-5">
                        <h1 className="border-l font-semibold border-r pr-3 border-bgclr2 pl-2 py-2 text-sm text-bgclr2">
                          {data.linkedLoanNumber}
                        </h1>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
              <EmptyDataAlert title="No Data Available" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankInbox;
