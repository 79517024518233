import React, { useEffect, useState } from 'react';
import { fetchBlogs, fetchBlogsbyId } from '../../../API/BlogsAPI';
import { useParams } from 'react-router-dom';
import Loader from '../../../CommonFunction/Loader';

const BlogDetails = () => {

    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const [data, setData] = useState([]);

    const fetchBlogsData = async () => {
        try {
            const data = await fetchBlogs();
            setData(data.docs);
            
        } catch (error) {
            console.error('Error retrieving user data:', error);
        }
    };

    useEffect(() => {
        const fetchBlogDetails = async () => {
            try {
                const data = await fetchBlogsbyId(id);
                setBlog(data);
                
            } catch (error) {
                console.error('Error retrieving blog details:', error);
            }
        };

        fetchBlogDetails();
        fetchBlogsData();
    }, [id]);

    if (!blog && !data) {
        return <div><Loader /></div>;
    }

    return (
        <>
            <div className='md:pt-28 lg:pt-32 xl:pt-28 pt-28 font-avenir'>
                <div className='bg-bgclr8'>
                    <div className='mt-2 container mx-auto md:px-32 md:py-10 px-5 py-10'>
                        <div className='flex text-[54px]'>
                            <h1>The Blog</h1>
                        </div>
                        <div className='pl-1 -mt-2 mb-1 text-red-600'>
                            <span>________</span>
                        </div>
                        <div className='grid grid-cols-6 gap-5'>
                            <div className='col-span-4 text-[#667085] text-justify'>
                                <h1 className='font-bold text-black text-left text-[32px] mr-10'>{blog?.title}</h1>
                                <div className='flex mt-3 mb-8 gap-10 text-[14px]'>
                                    <h2 className='text-[#6941C6] font-semibold'>{blog?.publish_date}</h2>
                                    <p className='text-[#667085]'><span className='font-semibold text-black'>Author: </span>{blog?.author}</p>
                                </div>
                                <img src={blog?.image} alt={blog?.title} width="100%" className='h-[450px]' />

                                <p className='mt-5'>{blog?.description}</p>
                                <div className='mt-5' dangerouslySetInnerHTML={{ __html: blog?.content }} />
                            </div>
                            <div className='col-span-2 text-[#667085]'>
                                <h1 className='font-semibold text-black text-[28px]'>Recent Posts</h1>
                                <div className='pl-7 mt-10'>
                                    {data.map((card, index) => (
                                        <button key={index} className='mb-4'>
                                            <div className='flex mt-3 mb-2 text-[13px] gap-5 '>
                                                <h2 className='text-[#6941C6] font-semibold'>{card.publish_date}</h2>
                                                <p className='text-[#667085]'><span className='font-semibold text-black'>Author: </span>{card.author}</p>
                                            </div>
                                            <h1 className='text-black text-[20px] font-semibold text-left'>{card.title}</h1>
                                            <p className='text-left text-[15px]'>{card.description}</p>
                                            <div className='flex mt-2'>
                                                <p className='bg-[#EEF4FF] text-[#6941C6] px-3 text-[14px] font-semibold rounded-xl'>{card.category}</p>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogDetails;
