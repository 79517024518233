import React from 'react'
import { Link } from 'react-router-dom'
import Buttons from '../../../../CommonFunction/Buttons'

const Demo = () => {
  return (
    <>

      <div className='container mx-auto my-20'>
        <div className='bg-bgclr8 border shadow-2xl py-5'>
          <div className='pt-5'>
            <h1 className='text-3xl text-center font-bold pb-3'>De<span className='underline underline-offset-[6px] decoration-2'>mo Vi</span>deo</h1>
            <div className='flex justify-center'>
              <p className='text-center text-lg w-4/5 md:w-2/3'>Elevate your Loan management journey: seamless, secure, and simply exceptional at ClevDoc.</p>
            </div>
          </div>
          <div className='lg:flex gap-24 md:p-14 p-6'>

            <div>

              <div className=''>
                <p className='text-xl text-justify md:px-10 px-5 drop-shadow-2xl'>
                  Embark on a journey of financial transformation with ClevDoc Platforms Inc. Our exclusive demo unveils a groundbreaking software solution designed to redefine debt recovery for banks. Witness the seamless management of bad loans, from non-performing status to successful resolution, ensuring operational efficiency. This demo showcases the platform's prowess in streamlined record-keeping and accelerated recovery initiation, significantly reducing the timeline. Join us in shaping the future of financial institutions. Access the demo now to witness the innovative capabilities of ClevDoc Platforms Inc.</p>
              </div>
              <div className='flex justify-center mt-3 md:pl-10 md:justify-start pb-7'>
                <Link>
                  <Buttons.Button2 label="Get Started" />
                </Link>
              </div>
            </div>


            <div className='flex justify-center items-center'>
              <iframe className='w-72 md:h-[340px] md:w-[500px]' src="https://www.youtube.com/embed/1Jc4ywxopmY?si=pnxDRJxTVWlgCcCt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Demo