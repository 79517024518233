import React, { useState } from 'react'
import { FaPhoneAlt } from 'react-icons/fa'
import { MdEmail, MdLocationPin } from 'react-icons/md'

const BankingContact = () => {

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
  }

  const phoneNumber = {
    firstNumber: '6394849878',
    secondNumber: '8415521578'
  }
  const handlePhoneNumberClick1 = () => {
    window.location.href = `tel:${phoneNumber.firstNumber}`;
  }
  // const handlePhoneNumberClick2 = () => {
  //   window.location.href = `tel:${phoneNumber.secondNumber}`;
  // }

  return (
    <>

      <div className='flex relative items-center justify-center bg-bgclr2 text-txtclr bg-cover h-56'>

        <div>
          <div className='justify-center'>
            <h1 className='text-6xl text-center font-bold'>Contact Us</h1>
          </div>
          <p className='text-lg text-center font-semibold'>Efficiency made effortless. Connect with us to elevate your company's operational potential</p>
        </div>
      </div>

      <div className='bg-gray-300 px-20 py-16'>
        <div className='bg-txtclr px-16 rounded-3xl'>
          <div className='grid relative grid-cols-5 gap-10'>
           
            <div className='grid border-r-2 mt-28 mb-24 col-span-2 border-gray-300 pt-8'>
              <div>
                <div className='flex justify-center text-txtclr3'>
                  <FaPhoneAlt size={40} />
                </div>
                <div className='flex justify-center pt-1 text-txtclr3'>
                  <h1 className='font-semibold text-2xl font-mono'>Phone</h1>
                </div>
                <div onClick={handlePhoneNumberClick1} className='flex justify-center pt-1 text-sm text-gray-500 font-semibold'>
                  +91-{phoneNumber.firstNumber}
                </div>
                <div onClick={handlePhoneNumberClick1} className='flex justify-center text-sm text-gray-500 font-semibold'>
                  +91-{phoneNumber.secondNumber}
                </div>
              </div>

              <div>
                <div className='flex justify-center text-txtclr3'>
                  <MdEmail size={40} />
                </div>
                <div className='flex justify-center pt-1 text-txtclr3'>
                  <h1 className='font-semibold text-2xl font-mono'>Email</h1>
                </div>
                <div className='text-center text-sm text-gray-500 font-semibold'>
                  <p>
                    <a href="mailto:team@clevdoc.com">team@clevdoc.com</a>
                  </p>
                  <p>
                    <a href="mailto:sales@clevdoc.com">sales@clevdoc.com</a>
                  </p>
                </div>
              </div>
            </div>


            <div className="col-span-3 py-5">
              <div className='mx-5'>
                <div className='text-3xl font-bold text-txtclr3'>
                  <h1>
                    Send us a message
                  </h1>
                </div>
                <div>
                  <p>If you have any work from us or any type of quries related to software, you can send us message from here. It's our pleosure to help you.</p>
                </div>
              </div>
              <div className="flex bg-white px-2 rounded-3xl py-3">
                <form onSubmit={handleSubmit} className="md:w-full px-4">

                  {/* Name */}
                  <div className='pb-1'>
                    <label htmlFor="name" className="block text-sm font-medium text-txtclr2">
                      Name
                    </label>
                    <input
                      type="text"
                      placeholder='Enter Name'
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="input-style"
                      required
                    />
                  </div>

                  {/* Phone number */}
                  <div className="mb-2">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-txtclr2"
                    >
                      Phone
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="input-style"
                      placeholder="Mobile No."
                      required
                    />
                  </div>

                  {/* Email ID */}
                  <div className="mb-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-txtclr2"
                    >
                      Email ID
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="input-style"
                      placeholder="Email ID"
                      required
                    />
                  </div>

                  <div className="col-span-2 mb-1">
                    <label htmlFor="message" className="block text-sm font-medium text-txtclr2">
                      Message
                    </label>
                    <textarea
                      type="text"
                      placeholder='Write your question here....'
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="input-style"
                      required
                    />
                  </div>

                  <div className="">
                    <button
                      type="submit"
                      className="text-center bg-btnclr hover:bg-bg-btnhover text-white font-bold py-2 md:px-4 px-4 rounded-md focus:outline-none focus:shadow-outline"
                    >
                      Send Now
                    </button>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>

      
        <div className='bg-bgclr2 py-14'>
          <div className='flex justify-center text-txtclr'>
            <h1 className='text-3xl font-bold'>Reach Out Using Map</h1>
          </div>
          <div className='px-36 pb-10'>
            <p className='text-center text-lg leading-6 text-txtclr4'>Navigate to our location on the map. Discover the hub of innovation where platforms come to life.</p>
          </div>
          <div className='grid grid-cols-2 gap-20'>
            <div className='mt-16 mb-12 border-r-2'>
              <div className='flex justify-center text-txtclr'>
                <MdLocationPin size={50} />
              </div>
              <div className='flex justify-center pt-1 text-txtclr'>
                <h1 className='font-semibold text-2xl font-mono'>Corporate Address</h1>
              </div>
              <div className='flex text-center text-sm px-32 text-txtclr4 font-semibold'>
                <address>
                  Meta Mata House,
                  Plot No. 858,
                  Lower Ground Floor,
                  Udyog Vihar Phase 5,
                  Gurugram - 122016, India.
                </address>
              </div>
            </div>
            <div className=''>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.223987529075!2d77.08315837421723!3d28.502907989957578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d192012d372ed%3A0xc45c5e15606c62cc!2sClevdoc%20Platforms%20Inc.!5e0!3m2!1sen!2sin!4v1704176763074!5m2!1sen!2sin" width="430" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>

    </>
  )
}

export default BankingContact