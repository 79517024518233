import React, { useEffect, useState } from 'react'
import { fetchIndividualData, fetchOrganizationData } from '../../../../../API/ClevDocContact';
import { decodeJwtToken } from '../../../../../CommonFunction/TokenDecode';
import { useNavigate } from 'react-router-dom';
import cookies from '../../../../../CommonFunction/cookies';
import { showFailed, showWarning } from '../../../../../CommonFunction/SweetAlert';
import Loader from '../../../../../CommonFunction/Loader';
import Widget from '../../Pages/Widget';
import Calendar from 'react-calendar';
import { BorrowerDashboardDetails } from '../../../../../API/Borrower/Dashboard/Dashboard';

const BorrowerDashboard = () => {

    const [value, onChange] = useState(new Date());
    const [userData, setUserData] = useState(null);
    const jwtToken = cookies.get('jwt');
    const navigate = useNavigate();
    const [inboxDetails, setInboxDetails] = useState([]);
    const [inboxError, setInboxError] = useState({})
    const [dashboardData, setDashboardData] = useState({});
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMsg, setSelectedMsg] = useState(null);
    const [showDetails, setShowDetails] = useState(false);

    const fetchDataAndUpdateUserData = async () => {
        try {
            const data = await fetchIndividualData(jwtToken);
            setUserData(data);
            CheckComponent(data);
        } catch (error) {
            console.error('Error retrieving user data:', error);
        }
    };

    const fetchOrgDataAndUpdate = async () => {
        try {
            const data = await fetchOrganizationData(jwtToken);
            setUserData(data);
            CheckComponent(data);
        } catch (error) {
            console.error('Error retrieving organization data:', error);
        }
    };

    const fetchDashboardDetails = async () => {
        setIsLoading(true);
        try {
            const data = await BorrowerDashboardDetails(jwtToken);
            setDashboardData(data?.response);
        } catch (error) {
            showFailed('Failed.', `${error}`);
        }
        setIsLoading(false);
    }

    function CheckComponent(userData) {
        if (userData && userData?.step === 1) {
            showWarning('Profile Incomplete...', 'Your Profile is not completed, please complete your profile first');
            if (data.role === 'entity') {
                navigate('/banking/borrowerDashboard/2');
            } else if (data.role === 'user') {
                navigate('/banking/borrowerDashboard/1');
            }
        } else if (userData && userData?.step === 2 && !userData.payment) {
            showWarning('Payment Incomplete...', 'Your Payment is not completed, please complete your Payment first');
            navigate('/banking/purchaseSummary');
        }
        else {
            fetchDashboardDetails();
        }
    }

    useEffect(() => {
        const data = decodeJwtToken(jwtToken);
        setData(data);
        const fetchData = async () => {
            try {
                if (data.role === 'entity') {
                    await fetchOrgDataAndUpdate();
                } else if (data.role === 'user') {
                    await fetchDataAndUpdateUserData();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [jwtToken]);

    const handleButtonClick = (data) => {
        setSelectedMsg(data);
        setShowDetails(true);
    }
    const handleCloseDetail = () => {
        setSelectedMsg(null);
        setShowDetails(false);
    }

    if (userData === null || isLoading) {
        return <Loader />
    }

    const items = [
        {
            name: 'Total Linked Loans',
            loans: `${dashboardData?.linkedLoanCount}`,
            percent: '+3.48%'
        },
        {
            name: 'Loans in Default',
            loans: `${dashboardData?.loanDefaultCount}`,
            percent: '+3.48%'
        },
        {
            name: 'KYC Status',
            loans: `${dashboardData?.kycUpdated === true ? 'Updated' : 'Not updated'}`,
            percent: '+3.48%'
        }
    ]

    const message = [
        {
            name: 'Surya Pratap',
            message: 'This process helps identify images on a webpage that are not loading images on a webpage that are not loading',
            time: '5 Min',
            status: 'unread'
        },
        {
            name: 'Rahul Sharma',
            message: 'This process helps identify images on a webpage that are not loading images on a webpage that are not loading',
            time: '5 Min',
            status: 'read'
        }
    ]


    return (
        <>
            <div className='bg-bgclr8'>
                <div className='container mx-auto'>
                    <div className='flex pt-6 pb-10 md:mx-10 mx-2 gap-5'>
                        <div class=" hidden md:block">
                            <Widget name={`${userData?.first_name || ''} ${userData?.middle_name || ''} ${userData?.last_name || ''}`.trim() || `${userData?.name || ''}`} UCI={userData?.system_id} />
                        </div>
                        <div className='w-full'>
                            <div>
                                <div className="text-[24px] font-[600] mb-5 mt-2 text-bgclr6">
                                    <h1>Overview</h1>
                                </div>
                                <div className='grid grid-cols-3 gap-5 mr-3'>
                                    {items.map((item, index) => (
                                        <div className='bg-bgclr9 pl-5 pr-1 h-[140px] rounded-3xl shadow-xl'>
                                            <p className='text-[14px] text-[#A0AEC0] mt-5'>{item.name}</p>
                                            <h3 className='text-[18px] text-[#2D3748] font-[700]'>{item.loans}</h3>
                                            <div className='flex items-end mt-7'>
                                                <h2 className='text-[14px] text-[#48BB78] font-[700]'>{item.percent}</h2>
                                                <p className='text-[14px] text-[#A0AEC0] ml-3'>Since last month</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                </div>
                            </div>

                            <div className='flex mt-10 gap-5'>
                                <div className='w-[60%] rounded-xl bg-bgclr9 shadow-lg'>
                                    <div className="text-[24px] px-3 font-[600] py-3 text-bgclr6 border-b">
                                        <h1>Notifications</h1>
                                    </div>
                                    <div>
                                        {message.map((item, index) => (
                                            <div className={`pl-5 pr-1 py-5 border-b ${item.status === 'unread' ? 'bg-bgclr8' : 'bg-bgclr9'}`}>
                                                <div className='flex justify-between'>
                                                    <h2 className='text-[18px] text-bgclr font-[700]'>{item.name}</h2>
                                                    <p className='text-[14px] text-[#A0AEC0] mx-3'>{item.time}</p>
                                                </div>
                                                <p className='text-[16px] text-[#A0AEC0] mt-3 mr-10'>{item.message}</p>
                                            </div>
                                        ))}
                                        <div className='flex justify-center rounded-b-xl bg-[#E0E6FF]'>
                                            <button className='py-3 text-[16px] text-bgclr font-semibold'>
                                                View All
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-[40%] rounded-xl bg-bgclr9 shadow-lg'>
                                    <div>
                                        <div className="text-[24px] px-3 font-[600] py-3 text-bgclr6">
                                            <h1>Calender</h1>
                                        </div>
                                        <div className='flex justify-center'>
                                            <Calendar
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BorrowerDashboard