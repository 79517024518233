import React from 'react'

const AboutDetails = () => {
  return (
    <>
        <div className="mb-11 container mx-auto font-avenir pt-12">
                <div className="grid md:grid-cols-2 gap-10 pb-11 px-10">
                    <div className="flex md:text-start text-lg flex-col gap-3 md:pl-7 md:pr-10 pt-10 mt-6">
                        <h2 className="md:text-[40px] text-4xl -mb-1  font-semibold">
                            About ClevDoc Platforms
                        </h2>
                        <h2 className="text-xl font-semibold md:text-justify drop-shadow-lg shadow-black">
                            Creating Solutions for a Digital India.
                        </h2>
                        <p className="text-justify tracking-wide">
                            We are a Gurugram, Haryana based SaaS Enterprise focusing on envisioning, creating, implementing and maintaining digital platforms and process relating to the Banking, Legal and Document Authentication/Verification Sectors, working with a vision to transform India into a digitally empowered society and knowledge economy, with our vision based on three key focus areas:
                        </p>
                        <ol className='list-decimal md:ml-14 ml-7 text-justify'>
                            <li>Providing digital infrastructure as a source of utility to every citizen in the sectors we are working on and shall upon in the future.</li>
                            <li>Governance and services on demand across the length and breadth of India.</li>
                            <li>⁠To look after the digital empowerment of every citizen irrespective of social or financial background.</li>
                        </ol>
                        <p className="text-justify">
                            We believe in India’s Digital Revolution journey for developing for better tomorrow across different societies, economies, and above all humanity.
                        </p>
                    </div>
                    <div className="grid place-items-center">
                        <div className=" px-5 py-2 md:pt-24 ">
                            <div className="-m-1 flex flex-wrap md:-m-2">
                               <img src="https://cdn.clevdoc.com/assets/home/home_1.jpg"  alt="Not available" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </> 
  )
}

export default AboutDetails