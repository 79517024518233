// import React from 'react';
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

// const Pagination = ({
//   previousPage,
//   nextPage,
//   canPreviousPage,
//   canNextPage,
//   paginationData
// }) => {
//   return (
//     <div className="pagination mt-4 items-center flex justify-center">
//       <button
//         onClick={previousPage}
//         className={`border border-black rounded-lg px-1 ${canPreviousPage ? "bg-bgclr4 text-white" : "bg-gray-300 text-gray-500"}`}
//         disabled={!canPreviousPage}
//       >
//         <MdKeyboardArrowLeft size={25} />
//       </button>
//       <span className="mx-3">
//         Page{" "}
//         <strong>
//           {paginationData.page} of {paginationData.totalPages}
//         </strong>
//       </span>
//       <button
//         onClick={nextPage}
//         className={`border border-black rounded-lg px-1 ${canNextPage ? "bg-bgclr4 text-white" : "bg-gray-300 text-gray-500"}`}
//         disabled={!canNextPage}
//       >
//         <MdKeyboardArrowRight size={25} />
//       </button>
//     </div>
//   );
// };

// export default Pagination;


import React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const Pagination = ({
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  paginationData = { page: 1, totalPages: 1 }, // Default values
}) => {
  return (
    <div className="pagination mt-4 items-center flex justify-center">
      <button
        onClick={previousPage}
        className={`border border-black rounded-lg px-1 ${canPreviousPage ? "bg-bgclr4 text-white" : "bg-gray-300 text-gray-500"}`}
        disabled={!canPreviousPage}
      >
        <MdKeyboardArrowLeft size={25} />
      </button>
      <span className="mx-3">
        Page{" "}
        <strong>
          {paginationData.page} of {paginationData.totalPages}
        </strong>
      </span>
      <button
        onClick={nextPage}
        className={`border border-black rounded-lg px-1 ${canNextPage ? "bg-bgclr4 text-white" : "bg-gray-300 text-gray-500"}`}
        disabled={!canNextPage}
      >
        <MdKeyboardArrowRight size={25} />
      </button>
    </div>
  );
};

export default Pagination;
