import { showFailed } from "../../CommonFunction/SweetAlert";

export const fetchOutboxList = async (jwtToken, paginationData, searchQuery) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/outbox?search=${searchQuery}&page=${paginationData}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                },
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            showFailed(`Failed.`, `${data?.error[0]}`);
        }
    } catch (error) {
        showFailed('Failed to load data.', error);
    }
};