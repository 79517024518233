import React, { useEffect, useState } from 'react'
import { ContactAPI } from '../../../../API/ClevDocContact';
import { validateForm } from '../../../../CommonFunction/FormValidation';
import Buttons from '../../../../CommonFunction/Buttons';

const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  })
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  }

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      let response;
      setIsLoading(true);
      try {
        response = await ContactAPI(formData, type);
        // 
        setIsLoading(false);
        if (response.status === 200) {
          setFormData({
            name: '',
            phone: '',
            email: '',
            message: ''
          });
          setSuccessMessage('Your message has been successfully submitted.');
          setErrorMessage('');
          
        } else {
          // Handling API error response
          if (response.errorMessage) {
            
            setFormData({
              name: '',
              phone: '',
              email: '',
              message: ''
            });
            setErrorMessage(response.errorMessage);
          } else {
            // If there is no error message in the response, handle it accordingly
            console.error('API call failed with status:', response.status);
            setErrorMessage('An error occurred while processing your request.');
          }
        }
      } catch (error) {
        console.error('Error during API call:', error);
        setErrorMessage('An error occurred while processing your request.');
      }
    }
  }

  return (
    <>
      <div className='pt-3 font-avenir'>

        <div className='mb-14 lg:mx-14'>
          <div className="container mx-auto md:px-16 lg:px-36 px-2 py-10">
            <div className="flex bg-white lg:px-24 md:px-10 px-3 rounded-xl shadow-xl border mt-2 py-14">
              <form onSubmit={(e) => handleSubmit(e, 'borrowerHelp')} className="w-full lg:px-4 mx-auto md:ml-0">
                {errorMessage && (
                  <div className="text-red-600 font-semibold mb-1">
                    {errorMessage}
                  </div>
                )}
                {successMessage && (
                  <div className="text-green-600 font-semibold mb-1">
                    {successMessage}
                  </div>
                )}
                {/* Name */}
                <div className='pb-3'>
                  <label htmlFor="name" className="block text-sm font-medium text-txtclr2">
                    Name<span className='text-red-600'>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder='Enter Name'
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="input-style"
                  />
                  {errors.name && <span className='text-red-600 font-semibold'>{errors.name}</span>}
                </div>

                {/* Phone number */}
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Phone<span className='text-red-600'>*</span>
                  </label>
                  <input
                    type="tel"
                    maxLength="10"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="input-style"
                    placeholder="Mobile No."
                  />
                  {errors.phone && <span className='text-red-600 font-semibold'>{errors.phone}</span>}
                </div>

                {/* Email ID */}
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Email ID<span className='text-red-600'>*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="input-style"
                    placeholder="Email ID"
                  />
                  {errors.email && <span className='text-red-600 font-semibold'>{errors.email}</span>}
                </div>

                <div className="col-span-2 mb-1">
                  <label htmlFor="message" className="block text-sm font-medium text-txtclr2">
                    Message<span className='text-red-600'>*</span>
                  </label>
                  <textarea
                    type="text"
                    placeholder='Write your question here....'
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="input-style"
                  />
                  {errors.message && <span className='text-red-600 font-semibold'>{errors.message}</span>}
                </div>

                <div className="mt-3">
                <Buttons.Button2 isLoading={isLoading} label="Send now" />
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact