import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowDown } from "react-icons/md";


const BankerDashboardHeader = () => {

  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCustomerSearchOpen, setIsCustomerSearchOpen] = useState(false)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSearchMenu = () => {
    setIsCustomerSearchOpen(!isCustomerSearchOpen);
  };

  return (
    <>
      <div className="mt-[68px]">
        <button
          onClick={toggleMenu}
          aria-controls="default-sidebar"
          type="button"
          className="inline-flex items-center pt-20 pr-16 ms-3 text-sm  text-gray-500 rounded-lg sm:hidden   "
        >
          {isMenuOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <GiHamburgerMenu size={30} />
          )}
        </button>

        <div
          id="default-sidebar"
          className={`fixed pt-5 z-44 h-full w-64 bg-bgclr2 dark:bg-gray-800 transition-transform duration-300 ease-in-out transform ${isMenuOpen
              ? "mt-4  pl-12  h-60 bg-black overflow-y-auto"
              : "hidden md:block"
            } sm:relative sm:translate-x-0 md:fixed`}
          aria-label="Sidebar"
        >
          <div className="px-3 py-4 overflow-y-auto">
            <ul className="space-y-2 font-medium overflow-y-auto">
              <li className={`flex items-center p-2 text-txtclr rounded-lg dark:text-white hover:bg-bgclr dark:hover:bg-gray-700 ${location.pathname === "/banking/bankDashboard/profile" ? "bankNavbarStyle" : ""}`} >
                <Link
                  to="/banking/bankDashboard/profile"
                >
                  <span className="ms-3 font-bold text-xl">My Profile</span>
                </Link>
              </li>
              <li
                className={`relative li-style hover:bg-bgclr rounded-lg ${location.pathname.includes("/search") ? "bankNavbarStyle" : ""}`}
                onMouseEnter={toggleSearchMenu}
                onMouseLeave={toggleSearchMenu}
              >
                <div className="flex">  <span className="flex text-white cursor-pointer">Customer Search</span><MdKeyboardArrowDown className='m-1.5 text-white' /></div>
                {isCustomerSearchOpen && (
                  <ul className="absolute top-10 left-5 bg-white text-bgclr py-3 rounded px-2 mt-1 z-50">
                    <li className={`hover:bg-bgclr px-3 rounded-lg mb-2 ${location.pathname.includes("/searchIndividual") ? "bankNavbarStyle" : ""}`}>
                      <Link to="/banking/bankDashboard/searchIndividual" className="block hover:text-white py-3">Individual</Link>
                    </li>
                    <li className={`hover:bg-bgclr px-3 rounded-lg ${location.pathname.includes("/searchOrganization") ? "bankNavbarStyle" : ""}`}>
                      <Link to="/banking/bankDashboard/searchOrganization" className="block hover:text-white py-3">Organization</Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link
                  to="/banking/bankDashboard/inbox"
                  className={`flex items-center p-2 text-txtclr rounded-lg dark:text-white hover:bg-bgclr dark:hover:bg-gray-700 group ${location.pathname === "/banking/bankDashboard/inbox" ? "bankNavbarStyle" : ""}`}
                >
                  <span className="flex-1 ms-3 whitespace-nowrap">Inbox</span>
                  {/* <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                    3
                  </span> */}
                </Link>
              </li>
              <li>
                <Link
                  to="/banking/bankDashboard/outbox"
                  className={`flex items-center p-2 text-txtclr rounded-lg dark:text-white hover:bg-bgclr dark:hover:bg-gray-700 group ${location.pathname === "/banking/bankDashboard/outbox" ? "bankNavbarStyle" : ""}`}
                >
                  <span className="flex-1 ms-3 whitespace-nowrap">Outbox</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/banking/bankDashboard/loanSearch"
                  className={`flex items-center p-2 text-txtclr rounded-lg dark:text-white hover:bg-bgclr dark:hover:bg-gray-700 group ${location.pathname === "/banking/bankDashboard/loanSearch" ? "bankNavbarStyle" : ""}`}
                >
                  <span className="flex-1 ms-3 whitespace-nowrap">
                    Loan Search
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/banking/bankDashboard/serviceNotice"
                  className={`flex items-center p-2 text-txtclr rounded-lg dark:text-white hover:bg-bgclr dark:hover:bg-gray-700 group ${location.pathname === "/banking/bankDashboard/serviceNotice" ? "bankNavbarStyle" : ""}`}
                >
                  <span className="flex-1 ms-3 whitespace-nowrap">
                    Serve Notice / Reminder
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/banking/bankDashboard/POD"
                  className={`flex items-center p-2 text-txtclr rounded-lg dark:text-white hover:bg-bgclr dark:hover:bg-gray-700 group ${location.pathname === "/banking/bankDashboard/POD" ? "bankNavbarStyle" : ""}`}
                >
                  <span className="flex-1 ms-3 whitespace-nowrap">
                    Proof of Delivery
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/banking/bankDashboard/accountSetting"
                  className={`flex items-center p-2 text-txtclr rounded-lg dark:text-white hover:bg-bgclr dark:hover:bg-gray-700 group ${location.pathname === "/banking/bankDashboard/accountSetting" ? "bankNavbarStyle" : ""}`}
                >
                  <span className="flex-1 ms-3 whitespace-nowrap">
                    Account Setting
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/banking/bankDashboard/help"
                  className={`flex items-center p-2 text-txtclr rounded-lg dark:text-white hover:bg-bgclr dark:hover:bg-gray-700 group ${location.pathname === "/banking/bankDashboard/help" ? "bankNavbarStyle" : ""}`}
                >
                  <span className="flex-1 ms-3 whitespace-nowrap">Help</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankerDashboardHeader;
