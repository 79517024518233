import React, { useEffect, useState } from 'react';
import { MdEmail, MdLocationPin } from "react-icons/md";
import Buttons from '../../../CommonFunction/Buttons';
import { ContactAPI } from '../../../API/ClevDocContact';
import { validateForm } from '../../../CommonFunction/FormValidation';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (successMessage || errorMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                setErrorMessage('');
            }, 5000); // 5 seconds
            return () => clearTimeout(timer);
        }
    }, [successMessage, errorMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (errors[name]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }
    }

    const handleSubmit = async (e, type) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            let response;
            setIsLoading(true);
            try {
                response = await ContactAPI(formData, type);
                
                setIsLoading(false);

                if (response.status === 200) {
                    setFormData({
                        name: '',
                        phone: '',
                        email: '',
                        message: ''
                    });
                    setSuccessMessage('Your message has been successfully submitted.');
                    setErrorMessage('');
                    
                } else {
                    // Handling API error response
                    if (response.errorMessage) {
                        
                        setFormData({
                            name: '',
                            phone: '',
                            email: '',
                            message: ''
                        });
                        setErrorMessage(response.errorMessage);
                    } else {
                        // If there is no error message in the response, handle it accordingly
                        console.error('API call failed with status:', response.status);
                        setErrorMessage('An error occurred while processing your request.');
                    }
                }
            } catch (error) {
                console.error('Error during API call:', error);
                setErrorMessage('An error occurred while processing your request.');
            }
        }
    }


    return (
        <div className='bg-gray-50'>
            <div className='grid container mx-auto md:grid-cols-4  md:p-14 md:gap-10 font-avenir'>
                <div className='md:flex  md:border-r-2 mx-2 my-10 py-10 md:py-0 border-gray-300 justify-center'>
                    <div className='grid md:mt-8 md:px-0 '>
                        <div>
                            <div className='flex justify-center text-txtclr3'>
                                <MdLocationPin size={40} />
                            </div>
                            <div className='flex justify-center pt-1 text-txtclr3'>
                                <h1 className='font-semibold text-xl'>Corporate Address</h1>
                            </div>
                            <div className='text-center text-sm text-gray-500 font-semibold'>
                                <h1>Meta Mata House,</h1>
                                <h1>Plot No. 858,</h1>
                                <h1>Lower Ground Floor,</h1>
                                <h1>Udyog Vihar Phase 5,</h1>
                                <h1>Gurugram - 122016, India.</h1>
                            </div>
                        </div>

                        <div>
                            <div className='flex justify-center text-txtclr3'>
                                <MdEmail size={40} />
                            </div>
                            <div className='flex justify-center pt-1 text-txtclr3'>
                                <h1 className='font-semibold text-2xl'>Email</h1>
                            </div>
                            <div className='text-center text-sm text-gray-500 font-semibold'>
                                <p>
                                    <a href="mailto:team@clevdoc.com">team@clevdoc.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:col-span-3 py-5">
                    <div className='md:mx-10 mx-8 mb-2'>
                        <div className='text-3xl text-left font-semibold text-txtclr3'>
                            <h1 className='text-left'>Get in touch with us</h1>
                        </div>
                        <div>
                            <p className='text-left'>Reach out to us to get a proposal of your need, any assistance, and we will get back to you at the earliest.</p>
                        </div>
                    </div>
                    <div className="flex bg-white md:px-7 mx-5 md:mx-0 rounded-xl border py-5">
                        <form onSubmit={(e) => handleSubmit(e, 'contact')} className="w-full md:px-4 mx-10 md:ml-0">
                            {errorMessage && (
                                <div className="text-red-600 font-semibold mb-1">{errorMessage}</div>
                            )}
                            {successMessage && (
                                <div className="text-green-600 font-semibold mb-1">{successMessage}</div>
                            )}
                            {/* Name */}
                            <div className='pb-3'>
                                <label htmlFor="name" className="block text-sm font-medium text-txtclr2">
                                    Name<span className='text-red-600'>*</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='Enter Name'
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="input-style"
                                />
                                {errors.name && <span className='text-red-600 font-semibold'>{errors.name}</span>}
                            </div>
                            {/* Phone number */}
                            <div className="mb-4">
                                <label htmlFor="phone" className="block text-sm font-medium text-txtclr2">
                                    Phone<span className='text-red-600'>*</span>
                                </label>
                                <input
                                    type="tel"
                                    maxLength="10"
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="input-style"
                                    placeholder="Mobile No."
                                />
                                {errors.phone && <span className='text-red-600 font-semibold'>{errors.phone}</span>}
                            </div>
                            {/* Email ID */}
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-txtclr2">
                                    Email ID<span className='text-red-600'>*</span>
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="input-style"
                                    placeholder="Email ID"
                                />
                                {errors.email && <span className='text-red-600 font-semibold'>{errors.email}</span>}
                            </div>
                            <div className="col-span-2 mb-1">
                                <label htmlFor="message" className="block text-sm font-medium text-txtclr2">
                                    Message<span className='text-red-600'>*</span>
                                </label>
                                <textarea
                                    type="text"
                                    placeholder='Write your question here....'
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="input-style"
                                />
                                {errors.message && <span className='text-red-600 font-semibold'>{errors.message}</span>}
                            </div>
                            <div className="mt-3">
                                <Buttons.Button2 isLoading={isLoading} label="Send now" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
