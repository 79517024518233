import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cookies from "../../../../CommonFunction/cookies";
import { fetchBankDetails } from "../../../../API/Bank/BankDetails";

const BankerProfile = () => {
  const [loanData, setLoanData] = useState([]);
  const [formData, setFormData] = useState({
    bankName: "",
    branchName: "",
    branchID: "",
    location: "",
    IFSCCode: "",
    branchContact: "",
    managerName: "",
    managerPhone: "",
    managerEmpId: "",
    managerEmpEmail: "",
  });

  const [isEditable, setIsEditable] = useState(false);

  const jwtToken = cookies.get("jwt");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBankDetails(jwtToken);
        setLoanData(data);
        setFormData({
          bankName: data?.bank?.name || "",
          branchName: data?.location || "",
          branchID: data?.b_id || "",
          location: data?.location || "",
          IFSCCode: data?.ifsc || "",
          branchContact: data?.phone_number || "",
          managerName: data?.manager?.name || "",
          managerPhone: data?.manager?.phone_number || "",
          managerEmpId: data?.manager?.employee_id || "",
          managerEmpEmail: data?.manager?.email || "",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/banking/bankDashboard/certificate/2", { state: { formData } });
  };

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  return (
    <>
      <div className="w-[95%] ml-48 bg-bgclr8 pt-20 pb-10">
        <form
          onSubmit={handleSubmit}
          className="md:mx-32 border bg-txtclr rounded-md border-gray-800 md:p-8 p-5"
        >
          <div className="grid md:gap-4">
            <div className="flex justify-between -mb-8">
              <div>
                <h1 className="font-bold text-xl text-gray-500">My Profile</h1>
              </div>

              <div>
                <span className="text-red-600 font-semibold text-md">
                  [* Indicates a required field]
                </span>
              </div>
            </div>

            <div>
              <div className="grid md:grid-cols-3 md:gap-8">
                {/* Entity Name */}
                <div className="mb-4 md:mt-10 mt-8">
                  <label
                    htmlFor="bankName"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Bank Name<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Bank Name"
                    id="bankName"
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleChange}
                    className="input-style text-gray-700 bg-gray-100"
                    readOnly
                    required
                  />
                </div>

                {/* Organization Type */}
                <div className="mb-4 md:mt-10">
                  <label
                    htmlFor="branchName"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Branch Name<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Branch Name"
                    id="branchName"
                    name="branchName"
                    value={formData.branchName}
                    onChange={handleChange}
                    className="input-style text-gray-700 bg-gray-100"
                    readOnly
                    required
                  />
                </div>
                <div className="mb-4 mt-10 w-full">
                  <label
                    htmlFor="branchID"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Branch ID<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Branch ID"
                    id="branchID"
                    name="branchID"
                    value={formData.branchID}
                    onChange={handleChange}
                    className="input-style text-gray-700 bg-gray-100"
                    readOnly
                    required
                  />
                </div>
              </div>

              {/* Aadhaar Number */}
              <div className="grid md:grid-cols-3 md:gap-10">
                <div className="mb-4">
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Branch Location<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Branch Location"
                    id="location"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    className="input-style text-gray-700 bg-gray-100"
                    required
                    readOnly
                  />
                </div>

                {/* Mobile Number */}
                <div className="mb-4">
                  <label
                    htmlFor="IFSCCode"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Branch IFSC Code<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Branch IFSC Code"
                    id="IFSCCode"
                    name="IFSCCode"
                    value={formData.IFSCCode}
                    onChange={handleChange}
                    className="input-style text-gray-700 bg-gray-100"
                    required
                    readOnly
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="branchContact"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Branch Contact No.<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Branch Contact No."
                    id="branchContact"
                    name="branchContact"
                    value={formData.branchContact}
                    onChange={handleChange}
                    className="input-style text-gray-700 bg-gray-100"
                    required
                    readOnly
                  />
                </div>
              </div>

              <div className="flex">
                <div className="text-gray-500 my-6 font-semibold text-xl">
                  Branch Manager Details
                </div>
                <div className="ml-[38vw]">
                  <button
                    type="button"
                    onClick={toggleEdit}
                    className="bg-bgclr mt-7 rounded-lg h-7 text-white px-5"
                  >
                    {isEditable ? "Cancel" : "Edit"}
                  </button>
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-10 md:mt-2">
                {/* Manager Name */}
                <div className="mb-4">
                  <label
                    htmlFor="managerName"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Branch Manager Name<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Branch Manager Name"
                    id="managerName"
                    name="managerName"
                    value={formData.managerName}
                    onChange={handleChange}
                    className={`input-style text-gray-500 ${isEditable ? "bg-white" : "bg-gray-100"}`}
                    required
                    readOnly={!isEditable}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="managerPhone"
                    className="block text-sm font-medium text-txtclr2"
                  >
                    Branch Manager Mobile No.
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Branch Manager Mobile No."
                    id="managerPhone"
                    name="managerPhone"
                    value={formData.managerPhone}
                    onChange={handleChange}
                    className={`input-style text-gray-500 ${isEditable ? "bg-white" : "bg-gray-100"}`}
                    required
                    readOnly={!isEditable}
                  />
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-2 md:gap-10 md:mt-2">
              {/* Manager Employee ID */}
              <div className="mb-4">
                <label
                  htmlFor="managerEmpId"
                  className="block text-sm font-medium text-txtclr2"
                >
                  Branch Manager Employee ID
                  <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Branch Manager Employee ID"
                  id="managerEmpId"
                  name="managerEmpId"
                  value={formData.managerEmpId}
                  onChange={handleChange}
                  className={`input-style text-gray-500 ${isEditable ? "bg-white" : "bg-gray-100"}`}
                  required
                  readOnly={!isEditable}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="managerEmpEmail"
                  className="block text-sm font-medium text-txtclr2"
                >
                  Branch Manager Employee Email
                  <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Branch Manager Employee Email"
                  id="managerEmpEmail"
                  name="managerEmpEmail"
                  value={formData.managerEmpEmail}
                  onChange={handleChange}
                  className={`input-style text-gray-500 ${isEditable ? "bg-white" : "bg-gray-100"}`}
                  required
                  readOnly={!isEditable}
                />
              </div>
            </div>
            {isEditable && (
              <div className="flex items-center justify-center ">
                <button
                  type="submit"
                  className="bg-bgclr mt-7 rounded-lg h-8 text-white px-5"
                >
                  Save Changes
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default BankerProfile;
