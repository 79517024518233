import React, { useContext } from 'react'
import Details from '../Elements/Details'
import Cards from '../Elements/Cards'
import Contact from '../Elements/Contact'
import WebsiteDetails from '../Elements/WebsiteDetails'
import cookies from '../../../CommonFunction/cookies'
import { UserContext } from '../../../App'

const Home = () => {

  // const { state, dispatch } = useContext(UserContext);

  // try {
  //   cookies.remove("jwt", { path: "/" });
  //   localStorage.removeItem("user"); // Remove user data from localStorage
  //   dispatch({ type: "USER", payload: false });
  // } catch (error) {
  //   console.error("Error removing JWT token:", error);
  // }
  return (
    <>
      <WebsiteDetails />
      <Details />
      <Cards />
      <Contact />
    </>
  )
}

export default Home