import React, { useState, useEffect } from 'react';
import cookies from '../../../../CommonFunction/cookies';
import OrganizationProfile from './Oraganization/OrganizationProfile';
import IndividualProfile from './Individual/IndividualProfile';
import { decodeJwtToken } from '../../../../CommonFunction/TokenDecode';

const Profile = () => {
    const jwtToken = cookies.get('jwt');
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const decodedData = decodeJwtToken(jwtToken);
        
        setUserRole(decodedData.role);
    }, [jwtToken, setUserRole]);

    return (
        <>
            {userRole === 'entity' && <OrganizationProfile />}
            {userRole === 'user' && <IndividualProfile />}
        </>
    );
}

export default Profile;
