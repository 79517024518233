import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";

const MainHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPlatformOpen, setIsPlatformOpen] = useState(false);
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const togglePlatform = () => {
    setIsPlatformOpen(!isPlatformOpen);
    setIsCompanyOpen(false); // Close Company dropdown when Platform is opened
  };

  const toggleCompany = () => {
    setIsCompanyOpen(!isCompanyOpen);
    setIsPlatformOpen(false); // Close Platform dropdown when Company is opened
  };

  const handleMouseEnterPlatform = () => {
    setIsPlatformOpen(true);
  };

  const handleMouseLeavePlatform = () => {
    setIsPlatformOpen(false);
  };

  const handleMouseEnterCompany = () => {
    setIsCompanyOpen(true);
  };

  const handleMouseLeaveCompany = () => {
    setIsCompanyOpen(false);
  };

  return (
    <div>
      <nav className="font-avenir flex md:items-center text-txtclr">
        <div className={`md:static absolute md:min-h-fit min-h-[40vh] left-0 
        ${isMenuOpen ? "fixed top-[3%] pl-12 w-full mt-[8.8rem] bg-bgclr border-t"
            : "hidden md:block"
          } md:w-auto w-full flex items-center transition-all duration-300 ease-in-out`}>
          <ul className="flex md:flex-row flex-col text-[20px] md:items-center md:gap-5">
            <li className='' onMouseEnter={togglePlatform} onMouseLeave={handleMouseLeavePlatform}>
              <button className='flex py-7' type='button'>Platforms <MdKeyboardArrowDown className='m-1.5' /></button>
              {isPlatformOpen && (
                <ul className="absolute  bg-bgclr9 border py-3" onMouseEnter={handleMouseEnterPlatform} onMouseLeave={handleMouseLeavePlatform}>
                  <Link className="no-underline" to="/banking">
                    <li className='text-txtclr3 p-3 text-lg hover:bg-bgclr8 px-10'><div>Banking</div></li>
                  </Link>
                  {/* <Link className="no-underline" to="/"> */}
                  <li className='text-txtclr3 p-3 text-lg hover:bg-bgclr8 px-10'><div>Document</div></li>
                  {/* </Link> */}
                  {/* <Link className="no-underline" to="/"> */}
                  <li className='text-txtclr3 p-3 text-lg hover:bg-bgclr8 px-10'><div>Legal</div></li>
                  {/* </Link> */}
                </ul>
              )}
            </li>
            <li className='' onMouseEnter={toggleCompany} onMouseLeave={handleMouseLeaveCompany}>
              <button className='flex py-7' type='button'>Company <MdKeyboardArrowDown className='m-1.5' /></button>
              {isCompanyOpen && (
                <ul className="absolute nested-dropdown bg-bgclr9 py-3 border" onMouseEnter={handleMouseEnterCompany} onMouseLeave={handleMouseLeaveCompany}>
                  <Link className="no-underline" to="/about">
                    <li className='text-txtclr3 p-3 text-lg hover:bg-bgclr8 px-10'><div>About</div></li>
                  </Link>
                  {/* <Link className="no-underline" to="/"> */}
                  <li className='text-txtclr3 p-3 text-lg hover:bg-bgclr8 px-10'><div>FAQ</div></li>
                  {/* </Link> */}
                  <Link className="no-underline" to="/contact">
                    <li className='text-txtclr3 p-3 text-lg hover:bg-bgclr8 px-10'><div>Contact</div></li>
                  </Link>
                  {/* <Link className="no-underline" to="/"> */}
                  {/* <li className='text-txtclr3 p-3 text-lg hover:bg-bgclr8 px-10'><div>Help</div></li> */}
                  {/* </Link> */}

                </ul>
              )}
            </li>
            <Link className="no-underline" to="/verification">
              <li className='py-7'>
                <div className="flex">
                  <div>Verification</div>
                </div>
              </li>
            </Link>
          </ul>
        </div>
        {isMenuOpen ? (
          <AiOutlineClose
            onClick={toggleMenu}
            className="text-3xl cursor-pointer md:hidden "
          />
        ) : (
          <AiOutlineMenu
            onClick={toggleMenu}
            className="text-3xl cursor-pointer md:hidden "
          />
        )}
      </nav>
    </div>
  );
};

export default MainHeader;
