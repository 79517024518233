import React, { useEffect, useState } from "react";
import cookies from "../../../../../CommonFunction/cookies";
import Loader from "../../../../../CommonFunction/Loader";
import IndividualProfile from "./Individual/IndividualProfile";
import OrganizationProfile from "./Organization/OrganizationProfile";

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

const UserProfile = ({ onClose, ucid }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const jwtToken = cookies.get("jwt");

  useEffect(() => {
    const fetchLinkedLoans = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/user?type=system_id&value=${ucid}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
          setLoading(false);
        } else {
          throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
      } catch (error) {
        setLoading(false);
        throw new Error("Error retrieving user data:", error);
      }
    };

    fetchLinkedLoans();
  }, []);

  if (loading) {
    return (
      <Loader />
    );
  }

  return (
    <>
      {userData?.role === "user" && <IndividualProfile userData={userData} onClose={onClose} />}
      {userData?.role === "entity" && <OrganizationProfile userData={userData} onClose={onClose} />}
    </>
  );
};

export default UserProfile;

