import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cookies from '../../../../../CommonFunction/cookies'
import Loader from '../../../../../CommonFunction/Loader'
import { validateFormIndividual } from '../../../../../CommonFunction/FormValidation'
import OTPPopup from '../../../../../CommonFunction/OTPPopup'
import { HiShieldCheck } from "react-icons/hi";
import { fetchIndividualData } from '../../../../../API/ClevDocContact'
import { FaSpinner } from 'react-icons/fa'
import { capitalizeFirstLetter } from '../../Pages/Widget'
import { showFailed } from '../../../../../CommonFunction/SweetAlert'
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import toast from 'react-hot-toast'

const IndividualForm = () => {

    const navigate = useNavigate();
    const [officeAddress, setOfficeAddress] = useState('');
    const [mobileNumber, setMobileNumber] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMobile, setIsLoadingMobile] = useState(false);
    const [isLoadingWhatsapp, setIsLoadingWhatsapp] = useState(false);
    const [whatsappError, setWhatsappError] = useState("");
    const [isLoadingPancard, setIsLoadingPancard] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [aadhaarError, setAadhaarError] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [pancardError, setPancardError] = useState("");
    // const [isOTPVerified, setIsOTPVerified] = useState(false);
    const [otpLength, setOtpLength] = useState('');
    const [OTPError, setOTPError] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [agreeContent, setAgreeContent] = useState(false);
    const [agreePrice, setAgreePrice] = useState(false);
    const [contentErr, setContentErr] = useState('')
    const [priceErr, setPriceErr] = useState('');
    const [formData, setFormData] = useState({
        AadhaarNumber: '',
        setMobileNumber: '',
        Whatsapp: '',
        panNumber: '',
        selectedGender: '',
        currentAddress: '',
        permanentAddress: '',
        alternateNumber: '',
        alternateEmail: '',
        state_name: selectedState,
        city_name: selectedCity
    });

    const jwtToken = cookies.get('jwt');
    const [userData, setUserData] = useState(null);
    const [errors, setErrors] = useState({});
    const [isOTPVerificationOpen, setIsOTPVerificationOpen] = useState(false);
    const [otp, setOTP] = useState('');
    const [timer, setTimer] = useState(10); // 5 minutes in seconds
    const [showResend, setShowResend] = useState(false);

    useEffect(() => {
        
    }, [selectedCountry]);

    useEffect(() => {
        
    }, [selectedState]);

    useEffect(() => {
        
    }, [selectedCity]);

    const indiaOption = Country.getAllCountries().find(country => country.name === "India");

    // Get Data
    useEffect(() => {
        const fetchDataAndUpdateUserData = async () => {
            try {
                const data = await fetchIndividualData(jwtToken);
                setUserData(data);
                
            } catch (error) {
                console.error('Error retrieving user data:', error);
            }
        };

        fetchDataAndUpdateUserData();

    }, [jwtToken]);

    useEffect(() => {
        if (userData && userData.step === 2) {
            
            // showToast('Your profile is completed.', 'warning');
            navigate('/banking/purchaseSummary');
        } else if(userData && userData.step === 3 && userData.payment){
            navigate('/banking/borrowerDashboard/dashboard');
        }
    }, [userData, navigate]);

    // Update data
    const updateIndividualData = async (e) => {
        e.preventDefault();
        const validationErrors = validateFormIndividual(formData);

        if (!selectedState || !selectedState.name) {
            validationErrors.state_name = 'State Name is required';
        }

        if (!selectedCity || !selectedCity.name) {
            validationErrors.city_name = 'City Name is required';
        }

        if (formData.alternateNumber === userData?.phone_number) {
            validationErrors.alternateNumber = 'Alternative mobile number and personal contact number cannot be the same';
        }
        if (formData.alternateEmail === userData?.email) {
            validationErrors.alternateEmail = 'Alternative email and personal email cannot be the same';
        }
        if (!userData?.verification?.adhaar_card) {
            setAadhaarError('Aadhaar Number is not verified');
        }
        if (!userData?.verification?.pancard) {
            setPancardError('PAN Number is not verified');
        }
        if (!userData?.verification?.phone_number) {
            setMobileError('Mobile Number is not verified');
        }
        if (!userData?.verification?.whatsapp_number) {
            setWhatsappError('WhataApp Number is not verified');
        }
        if (!agreeContent) {
            setContentErr("Please agree content declaration note.");
        }
        if (!agreePrice) {
            setPriceErr("Please agree Price declaration note.");
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            
            // Prepare the updated data
            const updatedData = {
                gender: formData.selectedGender,
                // type: formData.typeValue,
                personal: {
                    adhaar_card: formData.AadhaarNumber || userData.personal.adhaar_card.number,
                    whatsapp: formData.Whatsapp || userData.personal.whatsapp,
                    pancard: formData.panNumber || userData.personal.pancard,
                },
                address: {
                    country: "India",
                    state: selectedState.name,
                    city: selectedCity.name,
                    current_address: formData.currentAddress,
                    permanent_address: formData.permanentAddress,
                },
                alternate_contacts: {
                    email: formData.alternateEmail,
                    phone_number: formData.alternateNumber,
                },

            };

            try {
                setIsLoadingUpdate(true);
                const response = await fetch(`${process.env.REACT_APP_PLATFORM_API_BASE_URL}/user`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + jwtToken,
                    },
                    body: JSON.stringify(updatedData),
                });
                setIsLoadingUpdate(false);

                

                if (response.ok) {
                    setIsLoadingUpdate(false);
                    
                    navigate('/banking/purchaseSummary', { state: { formData } })
                } else {
                    showFailed('Failed.', `${response.statusText}`);
                    setIsLoadingUpdate(false);
                    console.error('Failed to update user data');
                }
            } catch (error) {
                setIsLoadingUpdate(false);
                console.error('Error occurred while updating user data:', error);
            }
        }
    };

    // Documents verification
    const VerifyAdhaar = async (e, aadhaarNumber) => {
        e.preventDefault();
        setAadhaarError('');
        if (!aadhaarNumber) {
            setAadhaarError('Aadhar number is required');
        } else if (!/^\d{12}$/.test(aadhaarNumber)) {
            setAadhaarError('Aadhar number should be 12 digits.');
        } else {
            setIsLoading(true);
            
            try {
                const baseURL = process.env.REACT_APP_PLATFORM_API_BASE_URL;

                const dataToSend = {
                    type: 'adhaar',
                    value: formData.AadhaarNumber,
                };

                const response = await fetch(`${baseURL}/user/documents/verify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + jwtToken,
                    },
                    body: JSON.stringify(dataToSend),
                });
                const verificationResult = await response.json();
                
                if (response.ok && response.status === 200) {
                    setIsLoading(false);
                    const updatedUserData = await fetchIndividualData(jwtToken);
                    setUserData(updatedUserData);
                    
                } else if (response.status === 400) {
                    setIsLoading(false);
                    setAadhaarError(verificationResult.error);
                    showFailed('Failed.', `${capitalizeFirstLetter(verificationResult.error)}`);
                    formData.AadhaarNumber = '';
                }
            } catch (error) {
                setIsLoading(false);
                showFailed('Failed.', `${error.message}`);
                console.error('Error verifying Adhaar:', error.message);
            }
        }
    };

    // Verify mobile
    const VerifyMobile = async (e) => {
        
        const CompleteMobile = mobileNumber + (userData?.personal?.adhaar_card?.digits || '');
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        

        setMobileError('');

        

        if (mobileNumber === null || mobileNumber === undefined || mobileNumber.length < 7) {
            
            setMobileError('Please enter first 7 digits');
        } else if (CompleteMobile.length === 10) {
            
            setIsLoadingMobile(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/user/documents/verify`,
                    {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + jwtToken,
                        },
                        body: JSON.stringify({
                            type: 'mobile',
                            value: CompleteMobile,
                        }),
                    }
                );
                const result = await response.json();
                
                if (response.ok && response.status === 200) {
                    setIsLoadingMobile(false);
                    toast.success(`${capitalizeFirstLetter(result.message)}`);
                    setOtpLength('6');
                    setIsOTPVerificationOpen(true);
                } else {
                    setIsLoadingMobile(false);
                    showFailed('Failed.', `${capitalizeFirstLetter(result.error) || 'Failed to send OTP'}`);
                    console.error("OTP verification failed:", result.error);
                }
            } catch (error) {
                setIsLoadingMobile(false);
                showFailed('Failed.', `${capitalizeFirstLetter(error) || 'Failed to send OTP'}`);
                console.error("Error during OTP send :", error);
            }
        } else {
            
        }
    }

    // OTP verification
    const handleOTPVerification = async (e) => {
        
        e.preventDefault();
        setIsLoading(true);
        const CompleteMobile = mobileNumber + userData?.personal?.adhaar_card?.digits;

        try {
            const response = await fetch(
                `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/user/documents/verify-otp`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: 'Bearer ' + jwtToken,
                    },
                    body: JSON.stringify({
                        device_id: `${CompleteMobile}`,
                        code: otp,
                        type: 'mobile',
                    }),
                }
            );

            const result = await response.json();
            
            

            if (response.status === 200) {
                setIsLoading(false);
                setIsOTPVerificationOpen(false);
                toast.success(`${capitalizeFirstLetter(result.message)}`);
                const updatedUserData = await fetchIndividualData(jwtToken);
                setUserData(updatedUserData);
                
            } else {
                setIsLoading(false);
                setOTPError(capitalizeFirstLetter(result.error[0]));
                console.error("OTP verification failed:", result.error);
            }
        } catch (error) {
            setIsLoading(false);
            setOTPError(capitalizeFirstLetter(error));
            console.error("Error during OTP verification:", error);
        }
    };

    // OTP Resend
    const handleResendClick = (e) => {
        e.preventDefault();
        // Reset timer
        setTimer(60); // Reset timer to 10 seconds
        setShowResend(false);

        // Start the timer countdown again
        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(interval);
                    setShowResend(true);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        // Handle form submission after resend
        VerifyMobile(e);
    };

    // Whatsapp Verify
    const VerifyWhatsapp = async (e, whatsapp) => {
        e.preventDefault();
        setWhatsappError('');
        
        if (whatsapp.length < 10) {
            
            setWhatsappError('Please enter 10 digit whatsapp number');
        } else if (whatsapp.length === 10) {
            
            setIsLoadingWhatsapp(true);
            try {
                const baseURL = process.env.REACT_APP_PLATFORM_API_BASE_URL;

                const dataToSend = {
                    type: 'whatsapp',
                    value: formData.Whatsapp,
                    role: "user"
                };

                const response = await fetch(`${baseURL}/user/documents/verify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + jwtToken,
                    },
                    body: JSON.stringify(dataToSend),
                });
                setIsLoadingWhatsapp(false);
                if (response.status === 200) {
                    const verificationResult = await response.json();
                    toast.success(`${verificationResult.message}`);
                    const pollInterval = 5000;
                    const poll = setInterval(async () => {
                        try {
                            const updatedUserData = await fetchIndividualData(jwtToken);
                            setUserData(updatedUserData);
                            if (updatedUserData?.verification?.whatsapp_number === true) {
                                clearInterval(poll); // Stop polling once condition is met
                            }
                        } catch (error) {
                            console.error('Error fetching individual data:', error.message);
                        }
                    }, pollInterval);
                } else {
                    setIsLoadingWhatsapp(false);
                    showFailed('Failed.', `${response.statusText}`);
                    
                }
            } catch (error) {
                setIsLoadingWhatsapp(false);
                console.error('Error verifying WhatsApp:', error);
            }
        }
    };

    // Verify Pancard
    const VerifyPancard = async (e, pancard) => {
        e.preventDefault();
        setPancardError('');
        
        if (!pancard) {
            setPancardError('PAN number is required');
        } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/gm.test(pancard)) {
            setPancardError('PAN number is invalid');
        } else {
            setIsLoadingPancard(true);
            try {
                const baseURL = process.env.REACT_APP_PLATFORM_API_BASE_URL;

                const dataToSend = {
                    type: 'pancard',
                    value: formData.panNumber,
                };

                const response = await fetch(`${baseURL}/user/documents/verify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + jwtToken,
                    },
                    body: JSON.stringify(dataToSend),
                });
                const verificationResult = await response.json();
                

                if (response.ok && response.status === 200) {
                    setIsLoadingPancard(false);
                    toast.success(`Pan Card Number Verified.`);
                    const updatedUserData = await fetchIndividualData(jwtToken);
                    setUserData(updatedUserData);
                    
                } else if (response.status === 400) {
                    setIsLoadingPancard(false);
                    showFailed('Failed.', `${verificationResult.error}`);
                }
            } catch (error) {
                setIsLoadingPancard(false);
                showFailed('Failed.', `${error}`);
            }
        }
    };

    const handleMobileNumberChange = (e) => {
        const { value } = e.target;
        setMobileNumber(value);
    };

    const handleEdit = () => {
        setIsOTPVerificationOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (errors[name]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }
    }


    if (userData === null) {
        // Loading state
        return <Loader />
    }

    const handleGender = (e) => {
        setFormData({
            ...formData,
            selectedGender: e.target.value
        });
    };

    const sameAddress = () => {
        const newPermanentAddress = formData.currentAddress;
        setOfficeAddress(newPermanentAddress);

        // Also update registeredAddress to be the same
        setFormData(prevFormData => ({
            ...prevFormData,
            permanentAddress: newPermanentAddress
        }));
    }


    return (
        <>
            <div className=" bg-gray-200 md:p-4">
                <div className='container mx-auto'>
                    <form onSubmit={(e) => updateIndividualData(e)} className="lg:mx-44 my-10 border-2 bg-white rounded-md border-gray-800 md:p-8 p-5">
                        <div className="grid md:gap-4">
                            <div className='flex justify-between'>
                                <div>
                                    <h1 className='font-bold text-xl text-gray-500'>Personal Details</h1>
                                </div>
                                <div>
                                    <span className='text-red-600 font-semibold text-md'>[* Indicates a required field]</span>
                                </div>
                            </div>
                            <div>
                                <div className="grid md:grid-cols-3 md:gap-8">
                                    {/* First Name */}
                                    <div className="mb-4 md:mt-10 mt-8">
                                        <label htmlFor="fname" className="block text-sm font-medium text-txtclr2">
                                            First Name<span className='text-red-600'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='First Name'
                                            id="fname"
                                            name="fname"
                                            value={capitalizeFirstLetter(userData.first_name)}
                                            className="input-style bg-gray-200 text-gray-500"
                                            disabled
                                        />
                                    </div>

                                    {/* Middle Name */}
                                    <div className="mb-4 md:mt-10">
                                        <label htmlFor="middle_name" className="block text-sm font-medium text-txtclr2">
                                            Middle Name
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='Middle Name'
                                            id="middle_name"
                                            name="middle_name"
                                            value={capitalizeFirstLetter(userData.middle_name)}
                                            className="input-style bg-gray-200 text-gray-500"
                                            disabled
                                        />
                                    </div>

                                    {/* Last Name */}
                                    <div className="mb-4 md:mt-10">
                                        <label htmlFor="lname" className="block text-sm font-medium text-txtclr2">
                                            Last Name<span className='text-red-600'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='Last Name'
                                            id="lname"
                                            name="lname"
                                            value={capitalizeFirstLetter(userData.last_name)}
                                            className="input-style bg-gray-200 text-gray-500"
                                            disabled
                                        />
                                    </div>
                                </div>

                                {/* Aadhaar Number */}
                                <div className="grid md:grid-cols-2 md:gap-10">
                                    <div className='mb-2'>
                                        <div className='md:grid md:grid-cols-5 flex md:gap-3'>
                                            <div className="col-span-4 w-full">
                                                <label htmlFor="AadhaarNumber" className="block text-sm font-medium text-txtclr2">
                                                    Aadhaar Number<span className='text-red-600'>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder='Aadhaar Number'
                                                    maxLength="12"
                                                    id="AadhaarNumber"
                                                    name="AadhaarNumber"
                                                    value={userData?.verification?.adhaar_card ? userData?.personal?.adhaar_card?.number : formData.AadhaarNumber}
                                                    onChange={handleChange}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                    }}
                                                    className={`input-style ${userData?.verification?.adhaar_card ? 'bg-gray-200 text-gray-500' : ''}`}
                                                    disabled={userData?.verification?.adhaar_card ? true : false}
                                                />
                                            </div>

                                            <div className='flex items-center justify-center mt-6 ml-2'>
                                                {(!userData?.verification?.adhaar_card) && (<button onClick={(e) => VerifyAdhaar(e, formData.AadhaarNumber)} disabled={userData?.verification?.adhaar_card || isLoading} className='underline px-3 h-10 rounded border font-semibold text-green-600 focus:border-green-600 ring-1 ring-green-700'>
                                                    {isLoading ? <FaSpinner className="animate-spin text-3xl" /> : 'Send'}
                                                </button>
                                                )}
                                                {userData?.verification?.adhaar_card && (
                                                    <div className='-ml-10 text-green-700'>
                                                        <HiShieldCheck size={30} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {aadhaarError && <span className='text-red-600 font-semibold text-[14px]'>{aadhaarError}</span>}
                                    </div>

                                    {/* Email */}
                                    <div className="mb-4">
                                        <label htmlFor="email" className="block text-sm font-medium text-txtclr2">
                                            Email Address<span className='text-red-600'>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            placeholder='Enter Email Address'
                                            id="email"
                                            name="email"
                                            value={userData.email}
                                            className="input-style bg-gray-200 text-gray-500"
                                            disabled
                                        />
                                        {errors.email && <span className='text-red-600 font-semibold text-[14px]'>{errors.email}</span>}
                                    </div>
                                </div>

                                <div className="grid md:grid-cols-2 md:gap-10 md:mt-2">
                                    {/* Mobile Number */}
                                    <div className='mb-2'>
                                        <div className='md:grid flex md:grid-cols-5 md:gap-3'>
                                            <div className="col-span-4 w-full">
                                                <label htmlFor="mobileNumber" className="block text-sm font-medium text-txtclr2">
                                                    Mobile Number<span className='text-red-600'>*</span>
                                                    {(userData.personal.adhaar_card.digits && !userData.verification.phone_number) && (
                                                        <span className='text-red-600'> [Enter first 7 digits of Aadhaar Linked Mobile Number]</span>
                                                    )}
                                                </label>
                                                <div className='flex'>
                                                    <input
                                                        type="text"
                                                        placeholder='Mobile Number'
                                                        id="mobileNumber"
                                                        name="mobileNumber"
                                                        value={userData.phone_number ? userData.phone_number : mobileNumber}
                                                        onChange={handleMobileNumberChange}
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                        }}
                                                        className={`input-style ${!userData?.personal?.adhaar_card?.digits || userData?.phone_number ? 'bg-gray-200 text-gray-500' : ''}`}
                                                        disabled={!userData?.personal?.adhaar_card?.digits || userData?.phone_number ? true : false}
                                                        maxLength={7}
                                                    />
                                                    {(userData.phone_number === null) && (
                                                        <div className='-ml-10 mt-3'>
                                                            {userData?.personal?.adhaar_card?.digits}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='flex items-center justify-center ml-2 mt-6 md:pl-0'>
                                                {!userData.verification.phone_number && (
                                                    <button onClick={(e) => VerifyMobile(e, mobileNumber)} disabled={!userData?.personal?.adhaar_card?.digits || isLoadingMobile} className='underline px-3 h-10 rounded border font-semibold text-green-700 focus:border-green-700 ring-1 ring-green-800'>
                                                        {isLoadingMobile ? <FaSpinner className="animate-spin text-3xl" /> : 'Verify'}
                                                    </button>
                                                )}
                                                {userData.verification.phone_number && (
                                                    <div className='md:-mt-1 -ml-10 text-green-700'>
                                                        <HiShieldCheck size={30} />
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                        {mobileError && <span className='text-red-600 font-semibold text-[14px]'>{mobileError}</span>}
                                    </div>

                                    <div className='mb-2'>
                                        <div className="md:grid flex md:grid-cols-5">
                                            {/* Whatsapp */}
                                            <div className="col-span-4 w-full">
                                                <label htmlFor="Whatsapp" className="block text-sm font-medium text-txtclr2">
                                                    WhatsApp<span className='text-red-600'>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    maxLength='10'
                                                    placeholder='WhatsApp number'
                                                    id="Whatsapp"
                                                    name="Whatsapp"
                                                    value={userData?.verification?.whatsapp_number ? userData?.personal?.whatsapp : formData.Whatsapp}
                                                    onChange={handleChange}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                    }}
                                                    disabled={userData?.verification?.whatsapp_number ? true : false}
                                                    className={`input-style ${userData?.personal?.whatsapp && userData?.verification?.whatsapp_number ? 'bg-gray-200 text-gray-500' : ''}`}
                                                />
                                            </div>

                                            <div className='flex items-center ml-2 mt-6'>
                                                {!userData?.verification?.whatsapp_number && (
                                                    <button onClick={(e) => VerifyWhatsapp(e, formData.Whatsapp)} disabled={isLoadingWhatsapp} className='underline px-3 h-10 rounded border font-semibold text-green-600 focus:border-green-600 ring-1 ring-green-700'>
                                                        {isLoadingWhatsapp ? <FaSpinner className="animate-spin text-3xl" /> : `${userData?.personal?.whatsapp ? 'Resend' : 'Verify'}`}
                                                    </button>
                                                )}
                                                {userData?.verification?.whatsapp_number && (
                                                    <div className='md:-mt-1 ml-1 text-green-700'>
                                                        <HiShieldCheck size={30} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {whatsappError && <span className='text-red-600 font-semibold text-[14px]'>{whatsappError}</span>}
                                    </div>
                                </div>

                                <div className="grid md:grid-cols-3 md:gap-10 md:mt-2">
                                    <div className='md:col-span-2'>
                                        <div className="md:grid flex md:grid-cols-6 md:gap-3 md:mt-2">
                                            {/* PAN number */}
                                            <div className="col-span-5 w-full">
                                                <label htmlFor="panNumber" className="block text-sm font-medium text-txtclr2">
                                                    PAN Number<span className='text-red-600'>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    maxLength="10"
                                                    placeholder='PAN number'
                                                    id="panNumber"
                                                    name="panNumber"
                                                    value={formData.panNumber || userData.personal.pancard}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
                                                        handleChange({
                                                            target: {
                                                                name: 'panNumber',
                                                                value: newValue,
                                                            },
                                                        });
                                                    }}
                                                    className={`input-style ${userData?.personal?.pancard ? 'bg-gray-200 text-gray-500' : ''}`}
                                                    disabled={userData?.personal?.pancard ? true : false}
                                                    pattern="[A-Z0-9]+"
                                                    title="Please enter only capital letters and numbers"
                                                />
                                            </div>
                                            <div className='flex items-center ml-2 mt-6'>
                                                {(!userData?.verification?.pancard) && (
                                                    <button onClick={(e) => VerifyPancard(e, formData.panNumber)} disabled={isLoadingPancard} className='underline px-3 h-10 rounded border font-semibold text-green-600 focus:border-green-600 ring-1 ring-green-700'>
                                                        {isLoadingPancard ? <FaSpinner className="animate-spin text-3xl" /> : 'Verify'}
                                                    </button>
                                                )}
                                                {userData?.verification?.pancard && (
                                                    <div className='md:-mt-1 -ml-3 text-green-700'>
                                                        <HiShieldCheck size={30} />
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                        {pancardError && <span className='text-red-600 font-semibold text-[14px]'>{pancardError}</span>}
                                    </div>

                                    <div className='mt-2 w-full'>
                                        <label htmlFor="gender" className="block text-sm font-medium text-txtclr2">
                                            Gender<span className='text-red-600'>*</span>
                                        </label>
                                        <select id="gender" name='gender' className="input-style" value={formData.selectedGender} onChange={handleGender}>
                                            <option>Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                        {errors.gender && <span className='text-red-600 font-semibold text-[14px]'>{errors.gender}</span>}
                                    </div>
                                </div>

                                <div className="md:grid md:grid-cols-3 md:gap-8 mt-5">
                                    <div className="mt-2" disabled>
                                        <label htmlFor="country_name" className="block text-sm font-medium text-txtclr2">
                                            Country<span className='text-red-600'>*</span>
                                        </label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                }),
                                            }}
                                            name='country_name'
                                            id='country_name'
                                            options={[{ ...indiaOption, isDisabled: true }]} // Disable India
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.name}
                                            value={selectedCountry || indiaOption}
                                            onChange={(item) => setSelectedCountry(item)}
                                        />
                                        {errors.country_name && <span className='text-red-600 font-semibold text-[14px]'>{errors.country_name}</span>}
                                    </div>

                                    <div className="mt-2">
                                        <label htmlFor="state" className="block text-sm font-medium text-txtclr2">
                                            State<span className='text-red-600'>*</span>
                                        </label>
                                        <Select
                                            name='state_name'
                                            id='state_name'
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                }),
                                            }}
                                            options={State?.getStatesOfCountry('IN')}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedState}
                                            onChange={(item) => {
                                                setSelectedState(item);
                                            }}
                                        />
                                        {errors.state_name && <span className='text-red-600 font-semibold text-[14px]'>{errors.state_name}</span>}
                                    </div>

                                    <div className="mt-2">
                                        <label htmlFor="state" className="block text-sm font-medium text-txtclr2">
                                            City<span className='text-red-600'>*</span>
                                        </label>
                                        <Select
                                            name='city_name'
                                            id='city_name'
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                }),
                                            }}
                                            options={City.getCitiesOfState(
                                                selectedState?.countryCode,
                                                selectedState?.isoCode
                                            )}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedCity}
                                            onChange={(item) => {
                                                setSelectedCity(item);
                                            }}
                                        />
                                        {errors.city_name && <span className='text-red-600 font-semibold text-[14px]'>{errors.city_name}</span>}
                                    </div>

                                </div>

                            </div>

                            <div className="grid md:grid-cols-9 mt-3 md:mt-0 md:gap-10">
                                {/* Current Address */}
                                <div className="col-span-4">
                                    <label htmlFor="currentAddress" className="block text-sm font-medium text-txtclr2">
                                        Current Address<span className='text-red-600'>*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        placeholder='Enter Current Address here....'
                                        id="currentAddress"
                                        name="currentAddress"
                                        value={formData.currentAddress}
                                        onChange={handleChange}
                                        className="input-style"

                                    />
                                    {errors.currentAddress && <span className='text-red-600 font-semibold text-[14px]'>{errors.currentAddress}</span>}
                                </div>
                                <div className='flex col-span-1 my-2 justify-center'>
                                    <input type="button" className='w-16 h-9 font-semibold rounded-md my-auto text-txtclr text-center bg-bgclr2' onClick={sameAddress} value='Same' />
                                </div>
                                <div className="col-span-4">
                                    <label htmlFor="permanentAddress" className="block text-sm font-medium text-txtclr2">
                                        Permanent Address<span className='text-red-600'>*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        placeholder='Enter Permanent Address here....'
                                        id="permanentAddress"
                                        name="permanentAddress"
                                        value={officeAddress || formData.permanentAddress}
                                        onChange={handleChange}
                                        className="input-style"
                                    />
                                    {errors.permanentAddress && <span className='text-red-600 font-semibold text-[14px]'>{errors.permanentAddress}</span>}
                                </div>
                            </div>

                            <div className="grid md:grid-cols-2 md:gap-10 md:mt-2">
                                {/* Alternate number */}
                                <div className="mt-3 md:mt-0">
                                    <label htmlFor="alternateNumber" className="block text-sm font-medium text-txtclr2">
                                        Alternate Number<span className='text-red-600'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        maxLength="10"
                                        placeholder='Mobile Number'
                                        id="alternateNumber"
                                        name="alternateNumber"
                                        value={formData.alternateNumber}
                                        onChange={handleChange}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                        }}
                                        className="input-style"
                                    />
                                    {errors.alternateNumber && <span className='text-red-600 font-semibold text-[14px]'>{errors.alternateNumber}</span>}
                                </div>

                                {/* Alternate Email */}
                                <div className="mt-3 md:mt-0">
                                    <label htmlFor="alternateEmail" className="block text-sm font-medium text-txtclr2">
                                        Alternate Email<span className='text-red-600'>*</span>
                                    </label>
                                    <input
                                        type="email"
                                        placeholder='Email'
                                        id="alternateEmail"
                                        name="alternateEmail"
                                        value={formData.alternateEmail}
                                        onChange={handleChange}
                                        className="input-style"
                                    />
                                    {errors.alternateEmail && <span className='text-red-600 font-semibold text-[14px]'>{errors.alternateEmail}</span>}
                                </div>
                            </div>
                        </div>

                        <div className='text-[14px]'>
                            <div className='mt-10 leading-6'>
                                <label className='flex text-txtclr2'>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={agreeContent}
                                            onChange={(e) => {
                                                setAgreeContent(e.target.checked);
                                                setContentErr('');
                                            }}
                                            className='mr-3'
                                        />
                                    </div>
                                    <div>
                                    <span className='text-red-600 font-semibold'>*</span>I/We hereby undertake to inform ClevDoc incase of change in my existing <span className='font-semibold text-black'>mobile number, whatsapp number</span> or <span className='font-semibold text-black'>email</span> address.
                                    </div>
                                </label>
                            {contentErr && <span className='text-red-600 font-semibold ml-6'>{contentErr}</span>}
                            </div>

                            <div className='mt-2 leading-6'>
                                <label className='flex text-txtclr2'>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={agreePrice}
                                            onChange={(e) => {
                                                setAgreePrice(e.target.checked);
                                                setPriceErr('');
                                            }}
                                            className='mr-3'
                                        />
                                    </div>
                                    ⁠<div>
                                    <span className='text-red-600 font-semibold'>*</span>I/We agree to pay a sum of <span className='font-semibold text-black'>Rs. 1000 (Incl. GST)</span> as one time onboarding licence fees for using the ClevDoc Banking Portal. I further agree to re-verify my Digital KYC on yearly basis (from date of registration) and also agree to KYC updation charges as will be applicable.
                                    </div>
                                </label>
                            </div>
                            {priceErr && <span className='text-red-600 font-semibold ml-6'>{priceErr}</span>}
                        </div>

                        {/* Submit Button */}
                        <div className="flex mt-10 justify-center ">
                            <button
                                type="submit"
                                disabled={isLoadingUpdate}
                                className="px-12 py-2 text-white bg-btnclr w-full rounded-md font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                            >
                                {isLoadingUpdate ? <div className='flex justify-center items-center'><FaSpinner className="animate-spin text-3xl" /></div> : 'Submit'}
                            </button>
                        </div>
                    </form>

                </div>
            </div>

            {isOTPVerificationOpen && (
                <OTPPopup
                    isOpen={isOTPVerificationOpen}
                    onClose={() => setIsOTPVerificationOpen(false)}
                    otp={otp}
                    setOTP={setOTP}
                    handleOTPVerification={handleOTPVerification}
                    timer={timer}
                    showResend={showResend}
                    handleResendClick={handleResendClick}
                    formData={formData}
                    handleEdit={handleEdit}
                    setTimer={setTimer}
                    setShowResend={setShowResend}
                    otpLength={otpLength}
                    isLoadingVerify={isLoading}
                    OTPError={OTPError}
                    setOTPError={setOTPError}
                />
            )}
        </>
    )
}

export default IndividualForm;