import React, { useEffect, useState } from "react";
import cookies from "../../../../../CommonFunction/cookies";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Loader from "../../../../../CommonFunction/Loader";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { FaDownload } from "react-icons/fa";
import logo from './clevdoc_logo.png';

const PodFormat = ({ loanId, onClose }) => {
  const jwtToken = cookies.get("jwt");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/pod/${loanId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        
        setFormData(data?.notices || {});
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [jwtToken, loanId]);

  const downloadPDF = (category) => {
    const input = document.getElementById(`pdf-content-${category}`);
    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`ProofOfDelivery_${category}.pdf`);
    });
  };

  const formatDate = (date) => {
    if (!date) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const capitalizeName = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  if (loading) {
    return (
      <div className="ml-72 ">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      {formData.map((categoryData, index) => {
        const category = Object.keys(categoryData)[0];
        const categoryDetails = categoryData[category];
        const sms = categoryDetails?.find(item => item.mode === 'sms');
        const email = categoryDetails?.find(item => item.mode === 'email');
        const whatsapp = categoryDetails?.find(item => item.mode === 'whatsapp');
        const ivr = categoryDetails?.find(item => item.mode === 'ivr');
        return (
          <div key={category} className="w-[950px] mt-10">
            <div className="pb-2 mx-8">
              <div className="flex justify-between pb-3">
                <div className="flex items-center">
                  <h1 className="text-2xl font-bold text-bgclr">POD Details - {category.toUpperCase()}</h1>
                </div>
                <div className="flex text-md gap-5">
                  <button
                    className="flex gap-2 items-center text-txtclr4 border border-gray-400 px-3 py-1.5 rounded-md bg-bgclr"
                    onClick={() => downloadPDF(category)}
                  >
                    <FaDownload size={18} /> Download
                  </button>
                  <button
                    className="flex items-center text-gray-500 border border-gray-400 px-3 py-1.5 rounded-md bg-bgclr9"
                    onClick={onClose}
                  >
                    <HiArrowNarrowLeft /> Back
                  </button>
                </div>
              </div>
              <div>
                <div id={`pdf-content-${category}`} className="border bg-bgclr9 mb-20 max-w-5xl">
                  <div className="flex bg-bgclr px-10 py-5 justify-between">
                    <div>
                      <img
                        className="w-64 ml-3"
                        src={logo}
                        alt="Not available"
                        crossOrigin="anonymous"
                      />
                    </div>
                  </div>

                  <div className="mt-5 mb-7">
                    <h1 className="text-center text-4xl font-bold text-txtclr3">PROOF OF DELIVERY</h1>
                  </div>

                  <div className="flex justify-between">
                    <h1 className="text-xl ml-16 pb-2 text-txtclr2 underline font-semibold">Details</h1>
                  </div>
                  <div className="mx-10 mb-4 py-7 border border-gray-600 rounded h-auto ">
                    <div className="flex justify-end ">
                      <h2 className="text-sm text-gray-400 mr-5 mt-1">
                        Updated on :- {formatDate(categoryDetails[0]?.created_at)}
                      </h2>
                    </div>
                    <table className="text-md ml-8">
                      <tr>
                        <td>Name:</td>
                        <td className="pl-8 font-semibold">
                          {categoryDetails[0]?.user?.personal
                            ? capitalizeName(categoryDetails[0]?.user.first_name) +
                            ' ' +
                            capitalizeName(categoryDetails[0]?.user.last_name)
                            : ''} {categoryDetails[0]?.organization
                              ? capitalizeName(categoryDetails[0]?.organization?.name) : ''}
                        </td>
                      </tr>
                      <tr>
                        <td>ClevDoc Envelope Id : </td>
                        <td className="pl-8 font-semibold">{categoryDetails[0]?.envelope_id || 'N/A'}</td>
                      </tr>
                    </table>

                    <div className="p-3 border mx-5 mt-5">
                      <div className="grid grid-cols-2 mb-5 mr-4 gap-5">
                        <div className="grid border-r pr-3">
                          <div className="text-[20px] font-semibold mb-2">
                            <h2>Email :</h2>
                          </div>
                          <table className="text-md">
                            <tr>
                              <td className="w-[50%]">Delivery Address : </td>
                              <td className="font-semibold">
                                {email?.user?.email || ''} {email?.organization?.email || ''}
                              </td>
                            </tr>
                            <tr>
                              <td className="w-[50%]">Delivery (time/date) : </td>
                              <td className="font-semibold">
                                {formatDate(email?.created_at) || 'N/A'}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="grid">
                          <div className="text-[20px] font-semibold mb-2">
                            <h2>SMS :</h2>
                          </div>
                          <table className="text-md">
                            <tr>
                              <td className="w-[50%]">Delivery Number : </td>
                              <td className="font-semibold">
                                {sms?.user?.phone_number || ''} {sms?.organization?.phone_number || ''}
                              </td>
                            </tr>
                            <tr>
                              <td className="w-[50%]">Delivery (time/date) : </td>
                              <td className="font-semibold">
                                {formatDate(sms?.created_at) || 'N/A'}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <hr />
                      <div className="grid grid-cols-2 mt-5 gap-10">
                        <div className="grid border-r">
                          <div className="text-[20px] font-semibold mb-2">
                            <h2>WhatsApp :</h2>
                          </div>
                          <table className="text-md">
                            <tr>
                              <td className="w-[50%]">Delivery Number : </td>
                              <td className="font-semibold">
                                {whatsapp?.user?.phone_number || ''} {whatsapp?.organization?.phone_number || ''}
                              </td>
                            </tr>
                            <tr>
                              <td className="w-[50%]">Delivery (time/date) : </td>
                              <td className="font-semibold">
                                {formatDate(whatsapp?.created_at) || 'N/A'}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="grid">
                          <div className="text-[20px] font-semibold mb-2">
                            <h2>IVR :</h2>
                          </div>
                          <table className="text-md">
                            <tr>
                              <td className="w-[50%]">Initiated Date : </td>
                              <td className="font-semibold">
                                {formatDate(ivr?.created_at) || 'N/A'}
                              </td>
                            </tr>
                            <tr>
                              <td className="w-[50%]">Last Called : </td>
                              <td className="font-semibold">
                                {formatDate(ivr?.last_response?.start_time) || 'N/A'}
                              </td>
                            </tr>
                            {/* <tr>
                              <td>Receipt : </td>
                              <td className="pl-8 font-semibold">
                                {categoryDetails.find(item => item.mode === 'ivr')?.last_response?.customer_keypress || 'N/A'}
                              </td>
                            </tr> */}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mx-12">
                    <div className="text-[25px] font-semibold mb-5">
                      <h1>Remarks :-</h1>
                    </div>
                    <div>
                      <ol className="list-inside list-decimal leading-7 text-lg pl-5 pb-5">
                        {sms?.status === 'sent' ? (
                          <li>SMS was <b>Sent and Delivered</b> (number switched on).</li>
                        ) : (
                          <li>SMS was <b>not sent</b>.</li>
                        )}

                        {email?.status === 'sent' ? (
                          <div>
                            {email?.last_response?.email_status === 'open' ? (
                              <li>Email was <b>Sent and Read </b>by borrower.</li>
                            ) : (
                              <li>Email was <b>sent</b> & did not bounce back.</li>
                            )} </div>
                        ) : (
                          <li>Email was <b>Sent</b>.</li>
                        )}

                        {whatsapp?.last_response?.status === 'read' ? (
                          <li>Whatsapp message was <b>Sent, Delivered and Read</b> by Borrower.</li>
                        ) : whatsapp?.last_response?.status === 'delivered' ? (
                          <li>Whatsapp message was <b>Sent and Delivered</b> to Borrower.</li>
                        ) : whatsapp?.last_response?.status === 'delivered' ? (
                          <li>Whatsapp was <b>Sent</b> to the borrower.</li>
                        ) : (
                          <li>Whatsapp message <b>wasn't sent</b>.</li>
                        )}

                        {ivr?.last_response?.customer_keypress === '2' ? (
                          <li>IVR call was made, acknowledgement for receipt & <b>communication acknowledged</b> by Borrower.</li>
                        ) : (
                          <li>IVR call made, acknowledgement for receipt & <b>communication wasn't acknowledged</b> by Borrower.</li>
                        )}
                      </ol>
                    </div>
                  </div>

                  <div className="grid grid-cols-5 bg-gray-300 p-5 px-10 gap-10">
                    <div className="col-span-3">
                      <div>
                        <h1 className="text-2xl font-bold text-txtclr3">
                          ClevDoc Platforms Inc.
                        </h1>
                      </div>
                      <div>
                        <p className="flex text-justify text-lg pt-5 border-r-2 pr-10 border-bgclr2">
                          To verify the authenticity of this Registration
                          Certificate, please scan the QR or visit the
                          https/clevdoc.com/verify. In case of any queries, you can
                          email us at support@clevdoc.com
                        </p>
                      </div>
                    </div>
                    <div className="grid pl-8">
                      <div className="flex items-center w-56">
                        <img
                          src={`https://api.qrserver.com/v1/create-qr-code/?data=https://www.clevdoc.com/verifyUser?number=${formData?.envelope?.document_id}&size=200x200`}
                          alt="not available"
                          crossOrigin="anonymous"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PodFormat;
