import React from 'react'
import { Link } from 'react-router-dom'

export function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Widget = ({ name, UCI }) => {

    return (
        <>
            <div className='col-span-1 bg-bgclr9 mt-5 shadow-xl border border-gray-400 pt-12 w-[350px] rounded-t'>
                <div className='text-center pb-10 border-b border-gray-400'>
                    <div className="flex justify-center">
                        <img className="w-20 h-20 rounded-full" src='https://cdn.clevdoc.com/assets/dashboard/user/user_avatar.png' alt="Neil image" />
                    </div>
                    <h1 className='text-2xl font-bold text-txt-clr2'>Welcome! </h1>
                    <h1 className='text-2xl font-bold text-txt-clr2'>{capitalizeFirstLetter(name)}</h1>
                    <h2 className='font-bold'>UCI: <span className='font-thin'>{UCI}</span></h2>
                    <Link to='/banking/borrowerDashboard/profile' className='flex justify-center text-txtclr2 hover:underline'>View Profile</Link>
                </div>
                <div className='text-center pt-5 pb-10 border-txtclr3'>
                    <h2 className="mb-2 text-xl font-bold text-txtclr3 dark:text-white"><span className='underline underline-offset-[7px] decoration-1'>Quick Links </span> </h2>
                    <ul className="text-txtclr2 font-semibold font-md">
                        <li className="mb-2">
                            <Link to='/contact' className="hover:underline">Contact Us</Link>
                        </li>
                        <li className="mb-2">
                            <Link to='/banking/borrowerDashboard/help' className="hover:underline">Help</Link>
                        </li>
                        <li className="mb-2">
                            <Link to='/t&c' className="hover:underline">
                                FAQs
                            </Link>
                        </li>
                        <li className="mb-2">
                            <Link to='/privacy&policy' className="hover:underline">
                                Privacy
                            </Link>
                        </li>
                        <li className="mb-2">
                            {/* <Link to='/FAQ' className="hover:underline"> */}
                            Terms of use
                            {/* </Link> */}
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Widget