import React from 'react'
import { capitalizeFirstLetter } from '../../Pages/Widget'
import { formatDate } from '../../../../../CommonFunction/FormatedDate'
import { HiArrowNarrowLeft } from "react-icons/hi";

const InboxDetails = ({ onClose, msg }) => {

  const createContentWithLinks = (content) => {
    const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    const contentWithLinks = content.replace(emailPattern, '<a href="mailto:$1" style="color: blue; text-decoration: underline;">$1</a>');
    return contentWithLinks.replace(/\n/g, '<br />');
  };

  return (
    <>
      <div className='bg-bgclr9 mt-5'>
        <div className='pt-5'>
          <div className='flex mt-5 justify-between pb-2'>
            <div className='pr-14 text-md ml-10'>
              <button className='flex items-center text-gray-500 border border-gray-400 px-2 rounded-full h-8 bg-bgclr9' onClick={onClose}><HiArrowNarrowLeft /></button>
            </div>
          </div>
          <div className='mt-5 mr-20'>
            <div className='flex ml-36 gap-5'>
              <div className='font-[500] text-[24px] text-txtclr2 mt-2'>
                <h2>{msg?.type === "welcome" ? "Message Type:" : "Notice Type:"}  <span>{capitalizeFirstLetter(msg?.type)}</span></h2>
              </div>
              <div className='flex justify-center items-center text-[12px] rounded-sm'>
                <p className={`px-2 py-1 ${msg?.type === "welcome" ? 'text-[#18AD5E] bg-green-100' : 'text-[#FF9900] bg-orange-100'}`}>{msg?.type === "welcome" ? "welcome" : "notice"}</p>
              </div>
            </div>
            <div className='grid grid-cols-5 mt-3'>
              <div className='col-span-1 flex justify-center mt-2.5'>
                <img
                  src="https://cdn.clevdoc.com/assets/dashboard/user/user_avatar.png"
                  alt="MSG"
                  className='w-[74px] h-[74px] cursor-pointer'
                />
              </div>
              <div className='col-span-4'>
                <div className='flex justify-between w-full mt-7'>
                  <div className='flex font-semibold text-[#000000]'>
                    <h1 className='text-[20px] font-[400]'>{capitalizeFirstLetter(msg?.from)}</h1>
                  </div>
                  <h1 className='text-sm text-bgclr2'>{formatDate(msg.created_at)}</h1>
                </div>
                <div className='my-10'>
                  <p className=''>
                    <div dangerouslySetInnerHTML={{ __html: createContentWithLinks(msg?.content || '') }} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InboxDetails