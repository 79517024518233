import React, { useEffect, useState } from 'react'
import { IoMailOutline } from 'react-icons/io5';
import { MdPhoneInTalk } from 'react-icons/md';
import FAQs from '../../Banking-Landing-page/Elements/FAQs';
import { validateForm } from '../../../../CommonFunction/FormValidation';
import Contact from '../Pages/Contact';

const Help = () => {

  const cardData = [
    {
      link: '/banking',
      icon: <IoMailOutline size={35} />,
      heading: 'Email',
      para: 'support@clevdoc.com',
      detail: "Reach out via our Mail tab for detailed correspondence.",
    }
  ];

  const [formData, setFormData] = useState({
    type: 'borrowerHelp',
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      
      const response = await fetch('https://client-clevdoc-testing-db68938bf14c.herokuapp.com/v1/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage('Form submitted successfully!');
        setFormData({
          type: 'borrowerHelp',
          name: '',
          email: '',
          message: '',
        });
      } else {
        setErrorMessage('Failed to submit form. Please try again later.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <>
      <div className='bg-bgclr8'>
        <div className='container mx-auto'>
          <div className='pt-10'>
            <div className='flex justify-center'>
              <h1 className='pl-4 pt-3 pb-2 text-2xl font-bold text-txtclr3'>Hello, How can we help you?</h1>
            </div>
            <div className='md:w-3/5 w-4/6 mx-auto text-center'>
              <p>Greetings! For prompt, personalized assistance, share your needs, questions, or feedback with us. We're here to help and committed to providing you with the support you require.
              </p>
            </div>
          </div>
          
          <div className='w-full '>
              <Contact />
            </div>
          <div className='bg-blue-100'><hr className='flex justify-center border-1 border-gray-900 mx-auto w-2/3' /></div>

          <div className='bg-bgclr2'>
            <div className='pb-5 pt-3 text-txtclr'>
              <div className='flex justify-center'>
                <h1 className='pl-4 pt-3 pb-2 text-2xl font-bold text-txtclr'>You still have a question?</h1>
              </div>
              <div className='md:w-1/2 w-4/5 mx-auto text-center'>
                <p>If you have a question and can't find the answer, simply fill out the query form and submit it. Alternatively, you can contact us directly, and we'll respond to your inquiry promptly.
                </p>
              </div>
            </div>
            <div className='flex justify-center'>
              <div className='md:flex md:px-20 py-5 gap-10'>
                {cardData.map((data, index) => (
                  <div>
                    <div className="w-64 p-4  mb-5 justify-center bg-gray-100 border border-gray-200 rounded-lg shadow-2xl  dark:bg-gray-800 dark:border-gray-700">
                      <div className='flex justify-center text-txtclr3'>
                        {data.icon}
                      </div>
                      <div className='flex justify-center'>
                        <h5 className="text-2xl font-semibold tracking-tight text-txtclr3 dark:text-white">{data.heading}</h5>
                      </div>
                      <div className='flex justify-center'>
                        <p className="font-normal text-gray-500 dark:text-gray-400">{data.para}</p>
                      </div>
                      <div className="flex justify-center ">
                        <p className='text-center'>{data.detail}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='pb-5'>
            <FAQs />
          </div>
        </div>
      </div>
    </>
  )
}

export default Help