export const convertToWords = (num) => {
  const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
  const teens = ['', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
  const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

  let words = '';

  if (num === 0) {
    words = 'Zero';
  } else {
    if (num >= 10000000) {
      words += convertToWords(Math.floor(num / 10000000)) + ' Crore ';
      num %= 10000000;
    }

    if (num >= 1000000) {
      // Modify the logic for the million's place
      const millions = Math.floor(num / 100000);
      if (millions === 10) {
        words += 'Ten Lakh ';
      } else {
        words += convertToWords(millions) + ' Million ';
      }
      num %= 1000000;
    }

    if (num >= 100000) {
      words += convertToWords(Math.floor(num / 100000)) + ' Lakh ';
      num %= 100000;
    }

    if (num >= 10000) {
      const thousands = Math.floor(num / 1000);
      if (thousands === 10) {
        words += 'Ten Thousand ';
      } else {
        words += convertToWords(thousands) + ' Thousand ';
      }
      num %= 1000;
    }

    if (num >= 1000) {
      words += convertToWords(Math.floor(num / 1000)) + ' Thousand ';
      num %= 1000;
    }

    if (num >= 100) {
      words += ones[Math.floor(num / 100)] + ' Hundred ';
      num %= 100;
    }

    if (num >= 20) {
      words += tens[Math.floor(num / 10)] + ' ';
      num %= 10;
    }

    if (num > 0) {
      if (num >= 11 && num <= 19) {
        words += teens[num - 11] + ' ';
      } else {
        words += ones[num] + ' ';
      }
    }
  }

  return words.trim();
};


const AmountToWord = ({ amount }) => {
  
  const text = `${convertToWords(amount)} Rupees Only `;
  return text;
  
};

export default AmountToWord;
