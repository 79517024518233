import React from 'react'
import Buttons from '../../../CommonFunction/Buttons'
import { Link } from 'react-router-dom'

export const SoftwareDeveloper = () => {
    return (
        <>
            <div className='bg-bgclr8 mt-28'>
                <div className='bg-[#F6F9FD] border shadow-md flex justify-center py-24'>
                    <div className='text-center'>
                        <p className='text-2xl'>ClevDoc <span className='border-l-2 ml-1 pl-3 border-gray-500'>Full Time</span></p>
                        <h1 className='text-[54px]'>Software developer</h1>
                    </div>
                </div>
                <div className='container mx-auto py-20'>
                    <div className='text-xl mx-20 pr-16'>
                        <div>
                            <h1 className='pl-2 text-gray-500'>JOB DETAILS</h1>
                            <h1 className='text-[40px]'>Job Description</h1>
                        </div>
                        <div className='mt-5'>
                            <h1><span className='font-bold'>Job Summary: </span>We are seeking a talented and motivated software developer to join our dynamic team. The ideal candidate will be passionate about technology and have a strong background in software development. As a software developer, you will work closely with our team to design, develop, test, and maintain software applications that meet our clients' needs.</h1>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>Responsibilities:</h1>
                            <ul className='list-disc ml-14 mt-3 leading-9'>
                                <li>Collaborate with product managers and other stakeholders to gather and analyze requirements.</li>
                                <li>Design and develop high-quality software solutions that meet project requirements.</li>
                                <li>Write clean, maintainable, and efficient code.</li>
                                <li>Perform thorough testing to ensure software quality and reliability.</li>
                                <li>Troubleshoot and debug software issues in a timely manner.</li>
                                <li>Stay up-to-date with emerging technologies and best practices.</li>
                                <li>Participate in code reviews and provide constructive feedback to team members.</li>
                                <li>Document software designs, processes, and procedures.</li>
                                <li>Work effectively in a team environment and communicate clearly with team members.</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>Requirements:</h1>
                            <ul className='list-disc ml-14 mt-3 leading-9'>
                                <li>Bachelor's degree in Computer Science, Software Engineering, or related field.</li>
                                <li>Proven experience as a software developer, with a strong portfolio of projects.</li>
                                <li>Proficiency in one or more is programming languages such as Node js, React js or JavaScript.</li>
                                <li>Experience with software development tools and frameworks (e.g., Git, React).</li>
                                <li>Solid understanding of software development principles, algorithms, and data structures.</li>
                                <li>Strong problem-solving and analytical skills.</li>
                                <li>Excellent communication and teamwork skills.</li>
                                <li>Ability to work independently and manage multiple tasks simultaneously.</li>
                                <li>Familiarity with agile development methodologies is a plus.</li>
                                <li>Experience with cloud platforms (e.g., AWS, Azure) is a plus.</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>How to apply?</h1>
                            <p className='ml-14 mt-3'>If the above job roles interest you OR if you think you’ll be a good fit to work in our team, send us your CV to <span className='text-blue-600 underline'><a href="mailto:hr@clevdoc.com">hr@clevdoc.com</a></span> and we’ll get back to you!</p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center mb-20'>
                    <Link to='/careers'>
                        <Buttons.Button3 label="View All Jobs" />
                    </Link>
                </div>
            </div>
        </>
    )
}

export const LegalExecutive = () => {
    return (
        <>
            <div className='bg-bgclr8 mt-28'>
                <div className='bg-[#F6F9FD] border shadow-md flex justify-center py-24'>
                    <div className='text-center'>
                        <p className='text-2xl'>ClevDoc <span className='border-l-2 ml-1 pl-3 border-gray-500'>Full Time</span></p>
                        <h1 className='text-[54px]'>Legal Executive</h1>
                    </div>
                </div>
                <div className='container mx-auto py-20'>
                    <div className='text-xl mx-20 pr-16'>
                        <div>
                            <h1 className='pl-2 text-gray-500'>JOB DETAILS</h1>
                            <h1 className='text-[40px]'>Job Description</h1>
                        </div>
                        <div className='mt-5'>
                            <h1><span className='font-bold'>Job Summary: </span>We are seeking a skilled and experienced Legal Executive to join our legal team. The Legal Executive will provide comprehensive support to our legal department, assisting with various legal tasks and activities. The ideal candidate will have a strong understanding of legal principles, excellent communication skills, and the ability to work efficiently in a fast-paced environment.</h1>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>Responsibilities:</h1>
                            <ul className='list-disc ml-14 mt-3 leading-9'>
                                <li>Assist in drafting and reviewing legal documents, including contracts, agreements, and legal correspondence.</li>
                                <li>Conduct legal research on various topics and provide advisory to the legal team.</li>
                                <li>Assist in the preparation of legal briefs, memos, and presentations for internal and external stakeholders.</li>
                                <li>Manage and maintain legal files, databases, and records, ensuring accuracy and confidentiality.</li>
                                <li>Coordinate and schedule meetings, appointments, and court hearings for legal department members.</li>
                                <li>Assist with the organization and preparation of materials for meetings, hearings, and trials.</li>
                                <li>Liaise with external legal counsel, clients, and other parties as necessary.</li>
                                <li>Monitor and track legal deadlines, filings, and obligations to ensure compliance.</li>
                                <li>Assist in the development and implementation of legal policies, procedures, and best practices.</li>
                                <li>Provide general administrative support to the legal department, including managing correspondence, filing documents, and answering phones.</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>Requirements:</h1>
                            <ul className='list-disc ml-14 mt-3 leading-9'>
                                <li>Bachelor's degree in Law, Legal Studies, or a related field.</li>
                                <li>Strong knowledge of legal principles, procedures, and terminology.</li>
                                <li>Excellent written and verbal communication skills, with the ability to effectively communicate complex legal concepts.</li>
                                <li>Proficiency in legal research methods and resources.</li>
                                <li>Strong organizational and time management skills, with the ability to prioritize tasks and meet deadlines.</li>
                                <li>Proficiency in MS Office suite and legal software applications.</li>
                                <li>Excellent communication and teamwork skills.</li>
                                <li>Ability to work independently and collaboratively as part of a team.</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>How to apply?</h1>
                            <p className='ml-14 mt-3'>If the above job roles interest you OR if you think you’ll be a good fit to work in our team, send us your CV to <span className='text-blue-600 underline'><a href="mailto:hr@clevdoc.com">hr@clevdoc.com</a></span> and we’ll get back to you!</p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center mb-20'>
                    <Link to='/careers'>
                        <Buttons.Button3 label="View All Jobs" />
                    </Link>
                </div>
            </div>
        </>
    )
}

export const SalesExecutive = () => {
    return (
        <>
            <div className='bg-bgclr8 mt-28'>
                <div className='bg-[#F6F9FD] border shadow-md flex justify-center py-24'>
                    <div className='text-center'>
                        <p className='text-2xl'>ClevDoc <span className='border-l-2 ml-1 pl-3 border-gray-500'>Full Time</span></p>
                        <h1 className='text-[54px]'>Sales Executive</h1>
                    </div>
                </div>
                <div className='container mx-auto py-20'>
                    <div className='text-xl mx-20 pr-16'>
                        <div>
                            <h1 className='pl-2 text-gray-500'>JOB DETAILS</h1>
                            <h1 className='text-[40px]'>Job Description</h1>
                        </div>
                        <div className='mt-5'>
                            <h1><span className='font-bold'>Job Summary: </span>We are seeking a dynamic and results-driven Sales Executive to join our sales team. The Sales Executive will be responsible for generating leads, nurturing client relationships, and closing sales deals. The ideal candidate will have a strong sales background, excellent communication skills, and a passion for exceeding targets and driving business growth.</h1>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>Responsibilities:</h1>
                            <ul className='list-disc ml-14 mt-3 leading-9'>
                                <li>Identify and prospect potential clients through cold calling, networking, and other lead generation methods.</li>
                                <li>Build and maintain strong relationships with new and existing clients, understanding their needs and providing tailored solutions.</li>
                                <li>Conduct sales presentations and product demonstrations to showcase the features and benefits of our products or services.</li>
                                <li>Negotiate and close sales deals, ensuring profitability.</li>
                                <li>Achieve and exceed sales targets and KPIs set by the sales manager or company management.</li>
                                <li>Collaborate with the marketing team to develop and implement effective sales strategies and campaigns.</li>
                                <li>Keep abreast of market trends, competitor activities, and industry developments to identify new business opportunities.</li>
                                <li>Prepare and submit sales reports, forecasts, and other documentation as required.</li>
                                <li>Provide timely and accurate feedback to management on sales performance and market trends.</li>
                                <li>Represent the company at industry events, conferences, and trade shows to promote our products and services.</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>Requirements:</h1>
                            <ul className='list-disc ml-14 mt-3 leading-9'>
                                <li>Bachelor's degree in Business Administration, Marketing, or related field.</li>
                                <li>Proven track record of success in sales, with at least 2 years of experience in a sales role.</li>
                                <li>Strong understanding of sales principles, techniques, and methodologies.</li>
                                <li>Excellent communication and interpersonal skills, with the ability to build rapport and influence decision-makers.</li>
                                <li>Ability to work independently and as part of a team in a fast-paced environment.</li>
                                <li>Demonstrated ability to meet and exceed sales targets and quotas.</li>
                                <li>Proficiency in CRM software and MS Office suite.</li>
                                <li>Strong negotiation and closing skills.</li>
                                <li>Self-motivated and results-oriented with a positive attitude.</li>
                                <li>Willingness to travel as required.</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>How to apply?</h1>
                            <p className='ml-14 mt-3'>If the above job roles interest you OR if you think you’ll be a good fit to work in our team, send us your CV to <span className='text-blue-600 underline'><a href="mailto:hr@clevdoc.com">hr@clevdoc.com</a></span> and we’ll get back to you!</p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center mb-20'>
                    <Link to='/careers'>
                        <Buttons.Button3 label="View All Jobs" />
                    </Link>
                </div>
            </div>
        </>
    )
}

export const Admin = () => {
    return (
        <>
            <div className='bg-bgclr8 mt-28'>
                <div className='bg-[#F6F9FD] border shadow-md flex justify-center py-24'>
                    <div className='text-center'>
                        <p className='text-2xl'>ClevDoc <span className='border-l-2 ml-1 pl-3 border-gray-500'>Full Time</span></p>
                        <h1 className='text-[54px]'>Administrative Assistant</h1>
                    </div>
                </div>
                <div className='container mx-auto py-20'>
                    <div className='text-xl mx-20 pr-16'>
                        <div>
                            <h1 className='pl-2 text-gray-500'>JOB DETAILS</h1>
                            <h1 className='text-[40px]'>Job Description</h1>
                        </div>
                        <div className='mt-5'>
                            <h1><span className='font-bold'>Job Summary: </span>We are seeking a detail-oriented and organized Administrative Assistant to join our team. The Administrative Assistant will provide essential support to our office operations and ensure smooth day-to-day functioning. The ideal candidate will be proactive, able to multitask, and possess excellent communication skills.</h1>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>Responsibilities:</h1>
                            <ul className='list-disc ml-14 mt-3 leading-9'>
                                <li>Greet and assist visitors in person or on the phone, answering inquiries and directing them to the appropriate person or department.</li>
                                <li>Manage and maintain office supplies, inventory, and equipment, ensuring adequate stock levels and functionality.</li>
                                <li>Handle incoming and outgoing mail, packages, and courier deliveries.</li>
                                <li>Assist with scheduling appointments, meetings, and conference calls for management and staff.</li>
                                <li>Coordinate travel arrangements, including booking flights, hotels, and transportation as needed.</li>
                                <li>Prepare and edit correspondence, reports, presentations, and other documents as requested.</li>
                                <li>Maintain electronic and paper filing systems, organizing and archiving documents as necessary.</li>
                                <li>Assist with data entry, record-keeping, and database management.</li>
                                <li>Support HR functions such as onboarding new employees, maintaining personnel records, and coordinating employee events or activities.</li>
                                <li>Assist with event planning and coordination, including meetings, conferences, and special events.</li>
                                <li>Provide general administrative support to management and staff, including photocopying, scanning, and faxing documents.</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>Requirements:</h1>
                            <ul className='list-disc ml-14 mt-3 leading-9'>
                                <li>High school diploma or equivalent; associate's or bachelor's degree preferred.</li>
                                <li>Proven experience in an administrative support role or similar position.</li>
                                <li>Proficiency in MS Office suite (Word, Excel, Outlook, PowerPoint) and other office software applications.</li>
                                <li>Excellent verbal and written communication skills.</li>
                                <li>Strong organizational and time management skills, with the ability to prioritize tasks and meet deadlines.</li>
                                <li>Attention to detail and accuracy in all work.</li>
                                <li>Ability to handle sensitive information with discretion and maintain confidentiality.</li>
                                <li>Ability to work independently and as part of a team.</li>
                                <li>Positive attitude, proactive approach, and willingness to learn and take on new challenges.</li>
                                <li>Previous experience in customer service or receptionist role is a plus.</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold'>How to apply?</h1>
                            <p className='ml-14 mt-3'>If the above job roles interest you OR if you think you’ll be a good fit to work in our team, send us your CV to <span className='text-blue-600 underline'><a href="mailto:hr@clevdoc.com">hr@clevdoc.com</a></span> and we’ll get back to you!</p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center mb-20'>
                    <Link to='/careers'>
                        <Buttons.Button3 label="View All Jobs" />
                    </Link>
                </div>
            </div>
        </>
    )
}
