import React from 'react'
import { CiBank } from 'react-icons/ci'
import { GiThorHammer } from 'react-icons/gi'
import { IoDocumentOutline } from 'react-icons/io5'
import AboutDetails from './AboutDetails'

const Details = () => {

    const data = [
        {
            img: <CiBank size={70} />,
            heading: "Integrity",
            text: "Making ethical, transparent, and well-intentioned decisions for all our Stakeholders in the supplychain economy and insuring the highest level of customer satifaction at all times."
        },
        {
            img: <IoDocumentOutline size={70} />,
            heading: "Continuous research & development",
            text: "Software is our craft and we back it up with our relentless investments in R&D. So much so that we prefer to own the entire technology stack, including running our data centers globally."
        },
        {
            img: <GiThorHammer size={70} />,
            heading: "Customer-first philosophy",
            text: "In all these years, it's our customers' trust and goodwill that has helped us establish a strong position in the market. No matter the size of your business, we're here to help you grow."
        }
    ]

    return (
        <>

            <AboutDetails />
            <hr />

            <div className='bg-gray-100 p-10 py-28'>
                <div className='flex w-24 mx-auto'>
                    <img src="https://cdn.clevdoc.com/assets/home/secure.png" className='flex' alt="" />
                </div>
                <div className='text-center'>
                    <h1 className='text-[44px] tracking-[-1px]'>
                        Your privacy is our responsibility
                    </h1>
                </div>
                <div className='max-w-[770px] mx-auto text-center mb-5'>
                    <h2 className='text-[18px] tracking-[-0.2] leading-7'>
                        We believe that trust is paramount in a relationship. We do not own or sell your data, and we most certainly do not bank on advertising-based business models.
                    </h2>
                </div>
                {/* <div className='flex justify-center'>
                    <Link to='/register'>
                        <Buttons.Button4 label="Sign Up now" />
                    </Link>
                </div> */}
            </div>

            <hr />

            <div className='bg-bgclr8 py-20 px-5 font-avenir'>
                <div className='md:p-10 py-5 md:max-w-[1230px] md:mx-auto mx-3 border bg-bgclr9'>
                    <div className='max-w-[450px] mx-auto text-center'>
                        <h1 className='md:text-[44px] text-[34px] leading-tight'>Our core values</h1>
                        <div className='pl-1 pt-3 text-red-600'>
                            <span>________</span>
                        </div>
                    </div>
                    <div className='md:flex mt-16 md:gap-14 pb-5'>
                        {data.map((items, index) => (
                            <div key={index} className="w-full px-3 pb-10">
                                <div>
                                    <div className='text-txtclr3 flex justify-center'>
                                        {items.img}
                                    </div>
                                    <div className='md:text-[35px] leading-[45px] py-5 text-gray-950 flex text-center justify-center'>{items.heading}</div>
                                    <div className='text-xl leading-8 text-center'>{items.text}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <div className='flex justify-center'>
                    <Link to='/team'>
                        <Buttons.Button3 label="Read Our Story" />
                    </Link>
                </div> */}
                </div>
            </div>
        </>
    )
}

export default Details