import React from "react";
import { FaSpinner } from "react-icons/fa";

function Button1(props) {
  return (
    <button class="relative inline-flex items-center justify-start  px-4 py-2 overflow-hidden font-medium transition-all bg-blue-600 rounded hover:bg-white group ">
      <span class="absolute inset-0 border-0 group-hover:border-[25px] h ease-linear duration-100 transition-all border-white rounded"></span>
      <span class="relative w-full text-left text-txtclr3 transition-colors duration-200 ease-in-out group-hover:text-blue-600">
        {props.label}
      </span>
    </button>
  );
}

function Button2({isLoading, label}) {
  return (
    <button type="submit" class="rounded-md px-3.5 py-1 overflow-hidden relative group cursor-pointer border-2 font-medium border-txtclr3 text-txtclr3 ">
      <span class="absolute w-64 h-0 transition-all duration-300 origin-center rotate-45 -translate-x-20 bg-txtclr3 top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
      <span class="relative text-txtclr3 transition duration-300 group-hover:text-white ease">
        {isLoading ? <FaSpinner size={20} className="animate-spin text-3xl" /> : label}
      </span>
    </button>
  );
}

function Button3(props) {
  return (
    <button class="relative inline-flex items-center px-12 py-3 bg-red-600 overflow-hidden text-lg font-medium text-white border-2 border-red-600 hover:border-indigo-600 rounded hover:text-white group hover:bg-gray-50">
      <span class="absolute left-0 block w-full h-0 transition-all bg-indigo-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
      <span class="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
        <svg
          class="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          ></path>
        </svg>
      </span>
      <span class="relative">{props.label}</span>
    </button>
  );
}

function Button4(props) {
  return (
    <button class="relative inline-flex items-center px-12 py-3 overflow-hidden text-lg font-medium text-indigo-600 border-2 border-indigo-600 rounded hover:text-white group hover:bg-gray-50">
      <span class="absolute left-0 block w-full h-0 transition-all bg-indigo-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
      <span class="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
        <svg
          class="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          ></path>
        </svg>
      </span>
      <span class="relative">{props.label}</span>
    </button>
  );
}

export default {
  Button1,
  Button2,
  Button3,
  Button4,
};
