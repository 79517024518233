// Register validation
export const registerFormUser = (formData) => {
    const validationErrors = {};

    if (!formData.first_name) {
        validationErrors.first_name = 'Field required';
    } else if (formData.first_name.length < 3) {
        validationErrors.first_name = 'At least 3 char';
    }

    // if (!formData.otp) {
    //     validationErrors.otp = 'Field required';
    // } else if (formData.otp.length < 5) {
    //     validationErrors.first_name = 'Invalid code';
    // }
    
    if (!formData.last_name) {
        validationErrors.last_name = 'Field required';
    } else if (formData.last_name.length < 3) {
        validationErrors.last_name = 'At least 3 char';
    }
    
    if (!formData.email) {
        validationErrors.email = 'Email is required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
        validationErrors.email = 'Email is invalid';
    }
    
    return validationErrors;
}

export const registerFormEntity = (formData) => {
    const validationErrors = {};
    if (!formData.name) {
        validationErrors.name = 'Entity Name is required';
    } else if (formData.name.length < 3) {
        validationErrors.name = 'Entity Name should be at least 3 characters.';
    }
    
    if (!formData.email) {
        validationErrors.email = 'Email is required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
        validationErrors.email = 'Email is invalid';
    }
    
    return validationErrors;
}

// Contact form validation
export const validateForm = (formData) => {
    const validationErrors = {};

    if (!formData.name) {
        validationErrors.name = 'Name is required';
    } else if (formData.name.length < 3) {
        validationErrors.name = 'Name should be at least 3 characters.';
    }
    
    if (!formData.phone) {
        validationErrors.phone = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
        validationErrors.phone = 'Mobile number should be 10 digits';
    }
    
    if (!formData.email) {
        validationErrors.email = 'Email is required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
        validationErrors.email = 'Email is invalid';
    }
    
    if (!formData.message) {
        validationErrors.message = 'Message is required';
    }        

    return validationErrors;
}


// Individual form Validation

export const validateFormIndividual = (formData) => {
    const validationErrors = {};
    
    if (!formData.selectedGender) {
        validationErrors.gender = 'Please select gender';
    }

    if (!formData.currentAddress) {
        validationErrors.currentAddress = 'Current Address is required';
    } else if (formData.currentAddress.length < 5) {
        validationErrors.currentAddress = 'Current Address should be at least 5 characters.';
    }

    if (!formData.permanentAddress) {
        validationErrors.permanentAddress = 'Permanent Address is required';
    } else if (formData.permanentAddress.length < 5) {
        validationErrors.permanentAddress = 'Permanent Address should be at least 5 characters.';
    }

    if (!formData.alternateNumber) {
        validationErrors.alternateNumber = 'Alternate Number is required';
    } else if (!/^\d{10}$/.test(formData.alternateNumber)) {
        validationErrors.alternateNumber = 'Mobile number should be 10 digits';
    }

    if (!formData.alternateEmail) {
        validationErrors.alternateEmail = 'Email is required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.alternateEmail)) {
        validationErrors.alternateEmail = 'Email is invalid';
    }

    

    return validationErrors;
}


// Organization Form Validation
export const validateFormOrganization = (formData, userData) => {
    const validationErrors = {};

    if (!formData.type) {
        validationErrors.type = 'Organization Type is required';
    } else if (formData.type.length < 3) {
        validationErrors.type = 'Organization Type should be at least 3 characters.';
    }

    if (!formData.officeAddress) {
        validationErrors.officeAddress = 'Office Address is required';
    } else if (formData.officeAddress.length < 5) {
        validationErrors.officeAddress = 'Office Address should be at least 5 characters.';
    }

    if (!formData.registeredAddress) {
        validationErrors.registeredAddress = 'Registered Address is required';
    } else if (formData.registeredAddress.length < 5) {
        validationErrors.registeredAddress = 'Registered Address should be at least 5 characters.';
    }

    if (!formData.authorized_person) {
        validationErrors.authorized_person = 'Authorized Person name is required';
    } else if (formData.authorized_person.length < 3) {
        validationErrors.authorized_person = 'Authorized Person name should be at least 5 characters.';
    }

    if (!formData.designation) {
        validationErrors.designation = 'Designation is required';
    } else if (formData.designation.length < 3) {
        validationErrors.designation = 'Designation should be at least 5 characters.';
    }

    if (!formData.alternate_phone_number) {
        validationErrors.alternate_phone_number = 'Alternate Number is required';
    } else if (!/^\d{10}$/.test(formData.alternate_phone_number)) {
        validationErrors.alternate_phone_number = 'Mobile number should be 10 digits';
    }

    if (!formData.alternate_email) {
        validationErrors.alternate_email = 'Email is required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.alternate_email)) {
        validationErrors.alternate_email = 'Email is invalid';
    }

    return validationErrors;
}

