import { showFailed } from "../../CommonFunction/SweetAlert";

export const LoanByID = async (jwtToken, loanId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/loans/${loanId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + jwtToken,
                },
            }
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            showFailed(`Failed to post organization data`, `${response.statusText}`);
        }
    } catch (error) {
        showFailed('Failed to load data.', error);
    }
};

export const fetchLoanList = async (jwtToken, paginationData, searchQuery) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/loans?search=${searchQuery}&page=${paginationData}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                },
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            showFailed(`Failed.`, `${data?.error[0]}`);
        }
    } catch (error) {
        showFailed('Failed to load data.', error);
    }
};