import React from 'react'
import { Link } from 'react-router-dom'

const PlatformsDetails = () => {
  return (
    <>
      <div className='md:flex px-12 container mx-auto justify-between pt-28'>
        <h1 className='md:text-[40px] text-xl font-semibold md:pb-2 pb-2'>Banking Solution by ClevDoc</h1>
        <Link to='/contact'>
          <button className='bg-[#20868F] px-5 py-2 font-bold hover:bg-[#27737B] text-txtclr'>Book a demo</button>
        </Link>
      </div>

      <div className='grid md:grid-cols-2 container mx-auto md:gap-20 pb-14 md:px-12 px-7'>
        <div>
          <div className='flex mt-2 items-center'>
            <p className='text-xl pt-5 leading-7 text-justify'>
              An aggressive Debt Recovery Platform, engineered to redefine the recovery landscape, our innovative platform is tailored to assist financial institutions in efficiently managing bad loans.  The Platform is intended as an end-to-end recovery management solution.  The Platform is designed to offer banks seamless access for serving Notices in accordance with the SARFAESI Act, 2002, streamlining record-keeping processes associated with the served Notices.

              From the moment an account transitions to a non-performing status, until the successful resolution, our Platform efficiently manages every step of the process.  Our Platform promises to redefine debt recovery in the banking sector, offering a robust, efficient, and reliable system for managing bad loans.</p>
          </div>
        </div>


        <div className='flex justify-center items-center'>
          <img src="https://cdn.clevdoc.com/assets/dashboard/banking/bankingHome_2.png" alt="Not Available" />
        </div>

      </div>
    </>
  )
}

export default PlatformsDetails