
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


import { HiShieldCheck } from 'react-icons/hi';

const ServeNoticeSuccess = () => {
    const navigate = useNavigate();

    const NavigateComponent = () => {
        navigate('/banking/bankDashboard/serviceNotice')
    }

    return (
        <>
            <div className='container mx-auto ml-60 '>
                <div className='flex justify-center items-center h-[85vh]'>
                    <div className='text-center'>
                        <h1 className='flex text-[40px] text-green-600 font-bold'><span className='drop-shadow-lg shadow-black'>Your Notice has been sent</span> <HiShieldCheck size={50} className='ml-2 mt-2' /></h1>
                        <p className='text-2xl text-gray-600 font-semibold'>Please download the POD after 24 hours.</p>
                        <div className='flex justify-center mt-6'>
                            <button onClick={() => NavigateComponent()} className={`w-[245px] text-xl h-[54px] flex justify-center items-center rounded-md bg-bgclr text-txtclr`}>
                                Go To Dashboard
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
};

export default ServeNoticeSuccess;
