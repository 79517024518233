import React from 'react'

const PrivacyPolicy = () => {
    return (
        <>

            <div className='bg-bgclr8 md:pt-28 lg:pt-32 xl:pt-28 pt-28'>
                <div className='container mx-auto'>

                    <div className='md:px-32 py-10 px-5'>
                        <div className='flex md:text-[54px] text-[40px]'>
                            <h1>Privacy Policy</h1>
                        </div>
                        <div className='pl-1 -mt-2 mb-1 text-red-600'>
                            <span>________</span>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                We, at ClevDoc Platforms Inc.  (hereinafter referred to as the “ClevDoc Platforms”, “We” or “Us”) is a Partnership Firm, having its corporate office at Plot Number 858, Meta Mata House, Lower Ground Floor, Udyog Vihar Phase V, Sector 19, Gurugram, Haryana - 122016 recognize the importance of maintaining your (hereinafter referred to as “You/Your”) privacy.
                            </p>
                            <p className='mt-6'>
                                This Privacy Policy details how we gather, utilize, and share personal information from users accessing our services, including our Application and Website. We urge you to thoroughly review this policy to understand how we manage your information.
                            </p>
                            <p className='mt-6'>
                                By accessing our Application and Site, you acknowledge and accept the conditions outlined in this Policy. If these terms are unacceptable to you, kindly refrain from using our Application or Website. Please note that this Policy is subject to periodic changes in order to maintain conformity with the prevailing laws.
                            </p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Scope of Privacy Policy</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                This Privacy Policy (herein after also referred to as the “Policy”) describes how we collect, receives, possess, stores, deals, handles and secure your personally identifiable information that is collected on our Application and Website “ClevDoc Platforms Inc” (hereinafter referred to as our “App”). “Personal Information” or “Sensitive Personal Information” bears the meaning as is ascribed to it under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal data or Information) Rules, 2011.
                            </p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Personal Information</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                “Sensitive Personal Information” shall include (i) Passwords and financial data  (ii) official identifier (such as biometric data, Aadhaar number, social security number, passport, etc.,), (iii) PAN Details, (iv) Bank Account details, or (v) other data/information categorized as ‘sensitive personal data’ or ‘special categories of data’ under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, General Data Protection Regulation (GDPR) and / or the California Consumer Privacy Act (CCPA) (“Data Protection Laws”) and in context of this Policy or other equivalent / similar legislations. Usage of the term ‘Personal Information’ shall include ‘Sensitive Personal Information’ as may be applicable to the context of its usage.
                            </p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Contact Information</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                When you register on our website or App, you generally provide your name, age, email, mobile number, phone number, address, and other biometric details. The said information collected from the users could be categorized as “Personal Information”, “Sensitive Personal Information” and “Associated Information”. Personal Information, Sensitive Personal Information and Associated Information (each as individually defined under this Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 (the “Data Protection Rules”) shall collectively be referred to as 'Information' in this Policy. We may use the Information to provide you with the Services, important information, required notices and marketing promotions. We will ask you when we need more information that personally identifies you (personal information). We will not differentiate between who is using the device to access the App or website, as long as the log in/access credentials match with yours. In order to make the best use of the website and enable your Information to be captured accurately on the App or website, it is essential that you have logged in using your own credentials. We will, at all times, provide the option to you to not provide the Personal Information or Sensitive Personal Information, which we seek from you. Further, you shall, at any time while using the App or website, also have an option to withdraw your consent given earlier to us to use such Personal Information or Sensitive Personal Information. Such withdrawal of the consent is required to be sent in writing to us at the contact details provided in this Policy below. In such event, however, the we fully reserves the right not to allow further usage of the App or Website.
                            </p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Payment and Billing Information</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                While you are making payment for our services, we NEVER collect your credit card number or credit card expiry date or other details pertaining to your credit card on our Platform. Credit card information will be obtained and processed by our online payment partner, which is an independent entity. You are requested to review their privacy policy.
                            </p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Terms Applicable If You Are An Indian Resident</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                If you are located in India, you may have the following rights under the Personal Data Protection Act, 2023. All requests can be made by using the option provided to you within the Platform upon your login. You may also write to us as stated in the “grievance” section above, and we will address you concerns to the extent required by law.
                            </p>
                            <p className='mt-4'><span className='font-bold'>Right to Confirmation and Access:</span> You have the right to get confirmation and access to your Personal Information that is with us along with other supporting information.</p>
                            <p className='mt-4'><span className='font-bold'>Right to Correction:</span> You have the right to ask us to rectify your Personal Information that is with us that you think is inaccurate. You also have the right to ask us to update your Personal Information that you think is incomplete or out-of-date.</p>
                            <p className='mt-4'><span className='font-bold'>Right to Data Portability:</span> You have the right to ask that we transfer the Personal Information you gave us to another organisation, or to you, under certain circumstances.</p>
                            <p className='mt-4'><span className='font-bold'>Right to be Forgotten:</span> You have the right to restrict or prevent the continuing disclosure of your Personal Information under certain circumstances.</p>
                            <p className='mt-4'><span className='font-bold'>Right to Erasure:</span> If you wish to withdraw/remove your Personal Information from our Platform, you have the right to request erasure of your Personal Information from our Platform. However, please note that such erasure will remove all your Personal Information from our Platform (except as specifically stated in this Policy) and may result in deletion of your Account on the Platform permanently, and the same will not be retrievable.</p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Consumer Rights and Requests Under The CCPA</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                The CCPA gives consumers the right to request that we (1) disclose what Personal Information we collect, use, disclose, and (2) delete certain Personal Information that we have collected or maintained. You may submit these requests to us as described below, and we honor these rights where they apply.
                            </p>
                            <p className='mt-4'><span className='font-bold'>a. Request to Know:</span> As a California resident, you have the right to request: (1) the specific pieces of Personal Information we have collected about you; (2) the categories of Personal Information we have collected about you; (3) the categories of sources from which the Personal Information is collected; (4) the categories of Personal Information about you that we have sold and the categories of third parties to whom the Personal Information was sold; (5) the categories of Personal Information about you that we disclosed for a business purpose and the categories of third parties to whom the Personal Information was disclosed for a business purpose; (6) the business or commercial purpose for collecting, disclosing, or selling Personal Information; and (7) the categories of third parties with whom we share Personal Information. Our response will cover the 12-month period preceding our receipt of a verifiable request.</p>
                            <p className='mt-4'><span className='font-bold'>b. Request to Delete:</span> As a California resident, you have a right to request the erasure/deletion of certain Personal Information collected or maintained by us. As described herein, we will delete your Personal Information from our records and direct any service providers (as defined under applicable law) to delete your Personal Information from their records. However, we are not required to honor a deletion request if an exemption applies under the law.</p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Submitting a Request</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <ul className='list-decimal ml-5 md:ml-0'>
                                <li className='mt-4'><span className='font-bold'>Submission of Instructions:</span> You may submit a request to know or to delete by email to ClevDoc team, Email: <a href="mailto:team@clevdoc.com" className='text-blue-600 underline'>team@clevdoc.com</a>. Regarding requests to delete, we may present you with the choice to delete select portions of your Personal Information, but a global option to delete all Personal Information will be offered and more prominently presented.</li>
                                <li className='mt-4'><span className='font-bold'>Verification Process:</span> We are required by law to verify the identities of those who submit requests to know or to delete. To determine whether the individual making the request is the consumer about whom we have collected information, we will verify your identity by matching the identifying information provided by you in the request to the Personal Information that we already maintain about you.
                                    <ul className='list-disc ml-10 mt-4'>
                                        <li className='mt-3'>If we cannot verify the identity of the person making a request for categories of Personal Information, we may deny the request. If the request is denied in whole or in part for this reason, we will provide a copy of, or direct you to, our Privacy Policy.</li>
                                        <li className='mt-3'>If we cannot verify the identity of the person making a request to delete, we may deny the request. If there is no reasonable method by which we can verify the identity of the requestor to the degree of certainty required, we will state this in our response and explain why we have no reasonable method by which we can verify the identity of the requestor.</li>
                                        <li className='mt-3'>If we cannot verify the identity of the person making a request for categories of Personal Information, we may deny the request. If the request is denied in whole or in part for this reason, we will provide a copy of, or direct you to, our Privacy Policy.</li>
                                    </ul>
                                </li>
                                <li className='mt-4'><span className='font-bold'>Authorized Agents:</span> Authorized agents may submit requests via the methods identified in this Policy. If you use an authorized agent to submit a request to know or a request to delete, we may require you to: (1) provide the authorized agent with signed permission to do so; (2) verify your identity directly with us; and (3) directly confirm with us that you provided the authorized agent permission to submit the request. However, we will not require these actions if you have provided the authorized agent with power of attorney pursuant to the California Probate Code.</li>
                                <li className='mt-4'><span className='font-bold'>Excessive Requests:</span> If requests from a User are manifestly unfounded or excessive, in particular because of their repetitive character, we may either (1) charge a reasonable fee, or (2) refuse to act on the request and notify the User of the reason for refusing the request. If we charge a fee, the amount will be based upon the administrative costs of providing the information or communication or taking the action requested.</li>
                                <li className='mt-4'><span className='font-bold'>CCPA Non-Discrimination:</span> You have the right not to receive discriminatory treatment by us due to your exercise of the rights provided by the CCPA. We do not offer financial incentives and price or service differences, and we do not discriminate against Users/consumers for exercising their rights under the CCPA.</li>
                            </ul>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Updates of This Policy</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                This Privacy Policy was last updated on 14th March 2024. From time to time we may change our privacy practices. We therefore reserve our right to amend, revise and update this Privacy Policy from time to time. We stand behind the promises we make, however, and will not materially change our policies and practices making them less protective of customer information collected in the past without your consent. Please check our site periodically for updates. Please review the same frequently.
                            </p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Data Retention and Deletion</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                When your Account is deleted, we make sure it is no longer viewable on the Website. For up to 28 days it is still possible to restore your Account if it was accidentally deleted. After 28 days, we begin the process of deleting your personal information from our systems, unless:
                            </p>
                            <ul className='list-decimal ml-10 mt-4'>
                                <li className='mt-3'>We must keep it to comply with applicable law (for instance, if you make purchases within the App or Website, some personal data may need to be kept for tax and accounting purposes);</li>
                                <li className='mt-3'>We must keep it to evidence our compliance with applicable law (for example, if an account is blocked, we keep some account information and a record of the behaviour that led to the block - this information is retained for evidential purposes in case of queries or legal claims concerning the block);</li>
                                <li className='mt-3'>There is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved; or</li>
                                <li className='mt-3'>The information must be kept for our legitimate business interests, such as fraud prevention and enhancing Users’ safety and security (for example, information may need to be kept to prevent a user who was banned for unsafe behaviour or security incidents from opening a new account. </li>
                            </ul>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Data Security</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                Ensuring the security of your Personal Data is a priority for us. However, it's important to note that no method of transmission or storage over the Internet is entirely fool proof. While we endeavour to employ commercially viable means to safeguard your Personal Data, absolute security cannot be guaranteed. Your Personal data is stored in a secure operating environment, accessible only on a need-to-use basis by authorized employees, representatives, and service providers. We strictly adhere to industry standards and legal requirements to protect your Personal Data. For payment information, we maintain strict confidentiality and security measures in accordance with industry standards.
                            </p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Mergers or Acquisititions</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                In the event of a merger or acquisition involving our business, it's plausible that we may merge with or be acquired by another company. Should such a transition occur, you agree that the succeeding company will gain access to the information we hold, and they will remain obligated by this Privacy Policy unless amended. We commit not to sell, trade, or rent your Personal Information to external parties, excluding our partners or service providers, except in cases involving the sale of all or substantially all of our assets, where your consent will be sought prior to any such transaction.
                            </p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Indemnity</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                We stands indemnified from any loss, harm, cost or claim that may arise due to any act, omission or commission committed by any user or visitor of this Platform and therefore disclaims itself from any liability thereof.
                            </p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Governing Law, Jurisdiction and Dispute Resolution</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                If you choose to use the App or Website, any dispute over privacy is subject to this Policy and the Website’s terms of use. In addition to the foregoing, any disputes arising under this Policy or your use of this Website shall be governed by the laws of India, with Courts of New Delhi, Delhi having exclusive jurisdiction. Any dispute or difference that may arise in or out of connection with the subject matter of this Policy, your use of the App or Website, shall be referred to Arbitration in accordance with the Arbitration and Conciliation Act, 1996, as amended and the Rules thereunder for the time being in force. The arbitral tribunal shall consist of a sole arbitrator to be appointed by us. The seat of arbitration shall be at New Delhi, Delhi and the language of the proceedings shall be English only.
                            </p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Grievance Office</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                            </p>
                            <p>Email: <a href="mailto:team@clevdoc.com">team@clevdoc.com</a></p>
                        </div>
                    </div>

                    <div className='md:px-32 px-5 mb-10'>
                        <div className='flex text-[35px] font-semibold text-txtclr3'>
                            <h1>Consent of The Policy</h1>
                        </div>
                        <div className='text-[20px] text-justify'>
                            <p>
                                The Terms of Use Agreement is incorporated herein by reference in its entirety.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default PrivacyPolicy
