import React from 'react'

const About = () => {


    return (
        <>
            <div className='md:pt-28 lg:pt-32 xl:pt-28 pt-28 font-avenir'>
                <div>
                    <div className="mb-10 container mx-auto font-avenir">
                        <div className="grid md:grid-cols-2 md:px-10 px-5">
                            <div className="flex justify-center md:text-start flex-col gap-3 md:pl-7 md:pr-10 mt-10">
                                <h2 className="md:text-[40px] text-3xl font-semibold">
                                    About ClevDoc
                                </h2>
                                <p className="md:text-justify text-xl tracking-wide">
                                    ClevDoc Platforms Inc. was established in 2020, in response to the challenges faced by India's Banking, Legal, and Document Verification sectors during the Covid-19 pandemic. As a Innovative Startup - Software as a Solution (SaaS) entity, we specialize in envisioning, executing, and maintaining innovative Platforms tailored for Entities both in the Public and Private Sector within India's Banking, Legal, and Document Verification spheres.
                                </p>
                            </div>
                            <div className="grid place-items-center">
                                <div className="container mx-auto px-5 py-2 md:pt-24">
                                    <div className='flex items-center'>
                                        <img src="https://cdn.clevdoc.com/assets/about/aboutImg_1.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='mx-16 text-justify  tracking-wide'>
                            <p className="text-xl">
                                With a keen focus on technological advancement and industry-specific expertise, we aim to revolutionize the way these sectors operate by providing cutting-edge solutions that streamline processes, enhance security, and improve efficiency. Our commitment to innovation and excellence drives us to constantly push boundaries, ensuring that our clients receive tailor-made platforms that meet their unique needs and challenges. With a deep understanding of the intricacies within these domains, we stand as a trusted partner in navigating the complexities of the modern business landscape, empowering our clients to thrive in a rapidly evolving digital environment.
                            </p>
                        </div>
                    </div>
                    <div className='bg-bgclr8 shadow-lg'>
                        <div className='grid md:grid-cols-2 mx-auto gap-10 container py-8 mt-2 mb-10'>
                            <div className='flex items-center'>
                                <img src="https://cdn.clevdoc.com/assets/about/aboutImg_2.png" alt="" />
                            </div>
                            <div className='md:px-7 px-5'>
                                <p className="md:text-justify text-center text-xl">
                                    Our vision resonates deeply with the Digital India mission and the Make in India concept, both pivotal initiatives driving India's technological and economic advancement. By leveraging innovative SAAS solutions tailored for the Banking, Legal, and Document Verification sectors, we actively contribute to the Digital India vision of transforming the nation into a digitally empowered society and knowledge economy. Our commitment to developing cutting-edge platforms aligns seamlessly with the government's objective of fostering digital inclusivity, enhancing access to services, and promoting technology-driven growth across diverse sectors. Furthermore, our emphasis on creating indigenous solutions underscores the essence of the Make in India initiative, championing local innovation, and fostering economic self-reliance. Through our endeavors, we not only embrace the principles of Digital India and Make in India but also actively participate in shaping a future where technology serves as a catalyst for socio-economic progress and global competitiveness.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mx-auto py-10 mb-10 md:px-20 px-5 font-avenir'>
                    <div className='mt-5 md:py-20 py-12 md:px-10 bg-bgclr8 border'>
                        <div className='justify-center'>
                            <h1 className='md:text-4xl text-2xl text-center font-semibold'>Get to Know Us</h1>
                        </div>
                        <p className='text-md text-center font-semibold'>Empowering your dreams</p>
                        <div className='flex items-center justify-center'>
                            <p className='md:px-20 px-3 md:text-justify text-center text-xl text-gray-700 md:p-8 '>
                                Our team comprising of experts from various domains including Business Leaders, Legal Experts and Technocrats is committed to driving innovation, leveraging the latest technologies to drive efficiency, transparency, and security. Whether it's revolutionizing document authentication processes, optimizing legal workflows, or enhancing banking services, we are steadfast in our mission to empower our clients with the tools they need to thrive in a dynamic and competitive landscape. By fostering collaboration, embracing creativity, and staying ahead of the curve, we are forging a path towards a future where digital transformation is synonymous with success.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About