import { showFailed } from "../../CommonFunction/SweetAlert";

export const DashboardDetails = async (jwtToken) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/loandetails`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                },
            }
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            showFailed(`Failed to post organization data`, `${response.statusText}`);
        }
    } catch (error) {
        showFailed('Failed to load data.', error);
    }
};

export const NotificationDetails = async (jwtToken) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/bank/notification`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                },
            }
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            showFailed(`Failed to post organization data`, `${response.statusText}`);
        }
    } catch (error) {
        showFailed('Failed to load data.', error);
    }
};