import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../API/Bank/ChnageForgotPassword';
import { FaSpinner } from 'react-icons/fa';

const ForgotPassword = () => {

    const [systemId, setSystemId] = useState('');
    const [msg, setMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const data = await forgotPassword(systemId);
        console.log("Forgot pass Data: ", data);
        if (data) {
            setMsg(true);
        }
        setIsLoading(false);
    }

    return (
        <>
            <div className="container mx-auto pt-36 mb-20 font-avenir ">
                <div className="md:py-5 rounded-3xl">
                    {!msg ? (
                        <div className="md:grid md:grid-cols-2 py-5 rounded-3xl">
                            <div className="text-center mt-7">
                                <h1 className="text-3xl font-semibold text-bgclr">
                                    Streamlined Digital Communications,
                                </h1>
                                <h1 className="text-3xl font-semibold text-bgclr">
                                    Seamless Compliance
                                </h1>
                                <p className="mt-5">
                                    Efficient Financial Communications, Simplified Processes
                                </p>
                                <div className="flex justify-center mt-5">
                                    <img
                                        src="https://cdn.clevdoc.com/assets/Login/Forgot+password-amico+1.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="md:mx-10 mx-3 mt-10 md:mt-0">
                                <div className='mb-5 mt-8'>
                                    <h1 className="text-3xl font-semibold text-center md:text-left text-bgclr">
                                        Forgot
                                    </h1>
                                    <h1 className="text-3xl font-semibold text-center md:text-left text-bgclr">
                                        Your Password?
                                    </h1>
                                    <p className='text-[16px] leading-[24px] mt-5 text-txtclr5'>Don’t worry it happens sometimes. Enter your user id and we’ll send you a password reset link.</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-5 mt-10">
                                        <div className="mb-4">
                                            <label
                                                htmlFor="userID"
                                                className="block text-sm font-medium text-bgclr mb-1"
                                            >
                                                User ID<span className="text-red-600">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="userID"
                                                name="userID"
                                                onChange={(e) => setSystemId(e.target.value)}
                                                className="input-style"
                                                placeholder="Enter your User ID"
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '')
                                                }}
                                                required
                                                disabled={isLoading}
                                            />
                                        </div>
                                        <div className='flex mt-10 justify-center'>
                                            <button disabled={isLoading} type='submit' className="px-12 py-2 text-white bg-btnclr w-full rounded-md hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200">
                                                {!isLoading ? 'Continue' : <div className='flex justify-center items-center'><FaSpinner className="animate-spin text-3xl" /></div>}
                                            </button>
                                        </div>
                                        <div disabled={isLoading} className='text-center text-txtclr5 pt-5 hover:underline'>
                                            <Link to='/login'>
                                                Back to <span className='text-bgclr font-semibold'>Log in</span>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[50vh]'>
                            <h1 className='text-3xl text-txtclr2 font-semibold drop-shadow-2xl'>Password reset link has been sent to your registered email ID.</h1>
                        </div>
                    )}
                </div >
            </div >
        </>
    )
}

export default ForgotPassword