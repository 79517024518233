import React, { useState } from 'react'
import { useNavigate } from 'react-router';

const Verification = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [envelopeID, setEnvelopeID] = useState();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setEnvelopeID(e.target.value);
    }

    const handleSubmit = () => {
        navigate('/verifyUser', { state: { envelopeID } });
        
        setIsLoading(false);
    }

    return (
        <>
            <div className='bg-bgclr8 md:pt-28 lg:pt-32 xl:pt-28 pt-28'>
                <div className='container mx-auto'>
                    <div className='md:mx-32 mx-4 mt-8 mb-24'>
                        <div className="flex md:py-7 pt-10   md:pl-10 items-center lg:justify-center justify-center">
                            <h1 className="font-semibold md:text-[44px] text-center md:text-left text-3xl font-avenir">Verify Document Authenticity</h1>
                        </div>
                        <div className="bg-bgclr md:py-5 md:rounded-3xl rounded-xl">
                            <div className="flex justify-center items-center md:bg-white lg:my-10 my-5 lg:mx-20 mx-8 md:rounded-3xl lg:py-10 md:py-5 py-8">
                                <div className="bg-white md:p-8 p-4 md:rounded rounded-xl lg:shadow-xl">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <label
                                                htmlFor="userID"
                                                className="block text-sm font-medium text-txtclr2 mb-1"
                                            >
                                                ClevDoc Envelope ID<span className="text-red-600">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="userID"
                                                name="userID"
                                                value={envelopeID}
                                                onChange={handleChange}
                                                className="w-72 h-12 pl-2 focus:border-blue-500 ring-1 ring-bgclr border rounded focus:outline-none"
                                                placeholder="Enter ClevDoc Envelope ID"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <button
                                                type="submit"
                                                className="bg-txtclr2 hover:bg-bg-btnhover text-txtclr px-4 py-2 rounded"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? 'Verifying...' : 'Verify'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Verification