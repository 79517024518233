import React, { useRef, useState } from 'react'
import Popup from 'reactjs-popup';
import FirstNotice from './Notices/FirstNotice';
import { useNavigate } from 'react-router-dom';
import SecondNotice from './Notices/SecondNotice';
import FinalNotice from './Notices/FinalNotice';
import DemandNotice from './Notices/DemandNotice';
import PossessionNotice from './Notices/PossessionNotice';
import SaleNotice from './Notices/SaleNotice';
import ConsentNotice from './Notices/ConsentNotice';
import Arbitration from './Notices/Arbitration';
import { MdDeleteOutline } from 'react-icons/md';
import { FaFilePdf, FaSpinner } from 'react-icons/fa';
import cookies from '../../../../../CommonFunction/cookies';
import { NoticeStatusUpdate, ServeNotice, UploadNotice } from '../../../../../API/Bank/NoticeServe';
import { showFailed } from '../../../../../CommonFunction/SweetAlert';
import { PDFDocument, rgb } from 'pdf-lib';
import { convertToWords } from '../../Elements/AmountToWord';
import toast from 'react-hot-toast';

const ChooseNotice = ({ isOpen, onClose, noticeStatus, selectedLoanId, noticeName, envId }) => {

    const [showContinue, setShowContinue] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [showNotice, setShowNotice] = useState(false);
    const [fileName, setFileName] = useState();
    const [fileChosen, setFileChosen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loanData, setLoanData] = useState([]);
    const [file, setFile] = useState();
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const jwtToken = cookies.get('jwt')

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileSize = selectedFile.size;
            const minSize = 5 * 1024;
            const maxSize = 9 * 1024 * 1024;
            if (fileSize < minSize || fileSize > maxSize) {
                alert("Please select a file between 5 KB and 9 MB in size.");
                event.target.value = null;
                setFileChosen(false);
                setFileName("");
                setFile(null);
                return;
            }
            setFile(selectedFile);
            setFileChosen(true);
            setFileName(selectedFile.name);
        }
    };

    const fetchLoanData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/dashboard/loans/${selectedLoanId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ` + jwtToken,
                    },
                }
            );
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setLoanData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const SubjectSelector = (loanData, noticeName) => {
        switch (noticeName) {
            case "B1":
                return `Formal Reminder: Immediate Resolution for First Month Payment Default – ${loanData?.identity_number} for an Amount of Rs. ${loanData?.amount} `;
            case "B2":
                return `Formal Reminder: Immediate Resolution for Second Month Payment Default – ${loanData?.identity_number} for an Amount of Rs. ${loanData?.amount} `;
            case "B3":
                return `Final Reminder for Degradation of Account in the NPA Category – ${loanData?.identity_number} for an Amount of Rs. ${loanData?.amount} `;
            case "sarfaesi_npa":
                return `Notice under Section 13(2) of the Securitization and Reconstruction of Financial Assets and Enforcement of Security Interest Act, 2002 (“SARFAESI Act, 2002”) read with Security Interest (Enforcement) Rules, 2002 (“Rules”) as amended from time to time.`;
            case "possession":
                return `Possession Notice under SARFAESI Act, 2002 For immovable 				property as per Rule 8(1) read with Rule 8(2)  - ${loanData?.identity_number} for an Amount of Rs. ${loanData?.amount} `;
            case "sale":
                return `Sale Notice under SARFAESI Act, 2002 - ${loanData?.identity_number} –      
            XXXXXXX) for an Amount of Rs. ${loanData?.amount} `;
            case "consent":
                return `Request for Consent under Rule 9(2) of the Security Interest (Enforcement) Rules, 2002 – ${loanData?.identity_number} for an Amount of Rs. ${loanData?.amount} `;
            case "arbitration":
                return `Request for Consent under Rule 9(2) of the Security Interest (Enforcement) Rules, 2002 – Loan Account Number – ${loanData?.identity_number} for an Amount of Rs. ${loanData?.amount}  (${convertToWords(loanData?.amount)}).`
            default:
                return "Loan Subject not defined";
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const Subject = SubjectSelector(loanData, noticeName);

        try {
            const uploadPromises = envId?.envelope?.map(async (item) => {
                const fileArrayBuffer = await file.arrayBuffer();
                const pdfDoc = await PDFDocument.load(fileArrayBuffer);
                const pages = pdfDoc.getPages();
                const firstPage = pages[0];
                const { width, height } = firstPage.getSize();
                firstPage.drawText(`Envelope ID: ${item.envelope_id}`, {
                    x: width / 2 - 60,
                    y: height - 20,
                    size: 12,
                    color: rgb(0, 0, 0),
                    align: 'center',
                });

                const modifiedPdfBytes = await pdfDoc.save();
                const modifiedFile = new Blob([modifiedPdfBytes], { type: file.type });

                const formData = new FormData();
                formData.append("file", modifiedFile);

                // Upload the file
                const response = await UploadNotice(jwtToken, formData, selectedLoanId);
                return {
                    link: response[0],
                    userid: item.user,
                    organization_id: item.organization,
                    category: item.category,
                };
            });

            const responses = await Promise.all(uploadPromises);
            const sourceArray = responses.map((response) => ({
                link: response.link,
                userid: response.userid || '',
                organization_id: response.organization_id || '',
                role: response.category,
            }));
            const noticeData = {
                source: sourceArray,
                subject: Subject,
                loanId: selectedLoanId,
                type: noticeName,
                envelope_id: envId?._id
            };

            const noticeServe = await ServeNotice(jwtToken, noticeData);

            if (noticeServe) {
                const statusPayload = {
                    loanId: selectedLoanId,
                    type: noticeName,
                    status: 'sent',
                    bank_id: loanData?.bank?._id,
                };
                const updateStatus = await NoticeStatusUpdate(jwtToken, statusPayload);
            }

            navigate("/banking/bankDashboard/serviceNotice/serveNoticeSuccess");
            toast.success(`Notice has been sent successfully.`);
        } catch (error) {
            console.error("Error in generatePdfAndSend:", error);
            showFailed('Operation Failed', `${error.message || error}`);
        } finally {
            setLoading(false);
        }
    };


    const handleRemoveFile = () => {
        setFileChosen(false);
        setFileName("");
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleExistingFormat = (e) => {
        e.preventDefault();
        setShowContinue(true);
        setShowUpload(false);
        setFile('');
        setFileChosen(false);
        setFileName('');
    }

    const handleUploadFile = async (e) => {
        e.preventDefault();
        setShowUpload(true);
        setShowContinue(false);
        await fetchLoanData();
    }

    const handleNotices = () => {
        setShowNotice(true);
    }

    const onCloseNotice = () => {
        setShowNotice(false);
    }

    return (
        <>
            <Popup open={isOpen} modal closeOnDocumentClick={false} className="custom-popup">
                {!showNotice ? (
                    <div className="popup-content font-avenir fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm">
                        <div className="bg-txtclr w-[600px] mx-auto h-auto overflow-y-auto rounded-[14px]">
                            <div className="flex justify-between font-avenir text-bgclr px-10 pt-10">
                                <h1 className='text-[20px] font-[600] '>Choose Format</h1>
                                <button
                                    type="submit"
                                    onClick={() => {
                                        onClose();
                                    }}
                                    disabled={loading}
                                    className="text-xl font-bold"
                                >
                                    X
                                </button>
                            </div>
                            <form>
                                <div className='flex gap-6 w-[526px] mx-auto mt-7 mb-8'>
                                    <button disabled={loading} onClick={(e) => handleExistingFormat(e)} className={`w-[50%] h-[180px] text-left shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-[8px] pl-5 ${showContinue ? 'border-2 border-bgclr' : ''}`}>
                                        <h2 className='text-[18px] font-[500] pr-5'>Use prefield existing format</h2>
                                        <p className='text-[18px] font-[500] text-[#D83939] pr-6 mt-3'>[You can also attach addi- tional paragraph in our formated document.]</p>
                                    </button>
                                    <button disabled={loading} onClick={(e) => handleUploadFile(e)} className={`w-[50%] h-[180px] text-left shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-[8px] pl-5 ${showUpload ? 'border-2 border-bgclr' : ''}`}>
                                        <h2 className='text-[18px] font-[500] -mt-12 pr-5'>Use Your Own format</h2>
                                        <p className='text-[18px] font-[500] text-[#D83939] pr-6 mt-3'>[Upload your document.]</p>
                                    </button>
                                </div>
                            </form>
                            {showContinue && (
                                <div className='flex justify-center mb-10'>
                                    <button onClick={() => handleNotices()} className='bg-bgclr px-8 text-txtclr py-3 rounded-md'>
                                        Continue
                                    </button>
                                </div>
                            )}
                            {showUpload && (
                                <div className='mb-10'>
                                    {!fileChosen ? (
                                        <div className='flex justify-center'>
                                            <input
                                                type="file"
                                                accept=".pdf"
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                                id="fileInput"
                                                style={{ display: 'none' }}
                                            />
                                            <label
                                                htmlFor="fileInput"
                                                className="bg-bgclr px-8 text-txtclr py-3 rounded-md"
                                            >
                                                Choose File
                                            </label>
                                        </div>
                                    ) : (
                                        <div className='flex justify-between px-10'>
                                            {fileChosen && (
                                                <div className="flex items-center w-[60%]">
                                                    <div className="flex items-center text-bgclr gap-3"><FaFilePdf size={30} /> <span className='flex w-[240px] overflow-hidden'>{fileName}</span></div>
                                                    <button
                                                        onClick={handleRemoveFile}
                                                        className="ml-2 text-red-600 px-2 py-1 rounded mr-20"
                                                        disabled={loading}
                                                    >
                                                        <MdDeleteOutline size={30} />
                                                    </button>
                                                </div>
                                            )}
                                            {fileChosen && (
                                                <button
                                                    onClick={() => handleSubmit(true)}
                                                    className="bg-bgclr px-8 text-txtclr py-3 rounded-md"
                                                    disabled={loading}
                                                >
                                                    {loading ? <FaSpinner className='animate-spin text-2xl' /> : 'Send'}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center font-avenir fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm">
                        {noticeName === 'B1' &&
                            <div className="w-full max-h-screen overflow-y-auto rounded-[14px]">
                                <FirstNotice loanId={selectedLoanId} envId={envId} onCloseNotice={onCloseNotice} />
                            </div>
                        }
                        {noticeName === 'B2' &&
                            <div className="w-full  max-h-screen overflow-y-auto rounded-[14px]">
                                <SecondNotice loanId={selectedLoanId} envId={envId} noticeStatus={noticeStatus} onCloseNotice={onCloseNotice} />
                            </div>
                        }
                        {noticeName === 'B3' &&
                            <div className="w-full  max-h-screen overflow-y-auto rounded-[14px]">
                                <FinalNotice loanId={selectedLoanId} envId={envId} noticeStatus={noticeStatus} onCloseNotice={onCloseNotice} />
                            </div>
                        }
                        {noticeName === 'sarfaesi_npa' &&
                            <div className="w-full  max-h-screen overflow-y-auto rounded-[14px]">
                                <DemandNotice loanId={selectedLoanId} envId={envId} noticeStatus={noticeStatus} onCloseNotice={onCloseNotice} />
                            </div>
                        }
                        {noticeName === 'possession' &&
                            <div className="w-full  max-h-screen overflow-y-auto rounded-[14px]">
                                <PossessionNotice loanId={selectedLoanId} envId={envId} noticeStatus={noticeStatus} onCloseNotice={onCloseNotice} />
                            </div>
                        }
                        {noticeName === 'sale' &&
                            <div className="w-full  max-h-screen overflow-y-auto rounded-[14px]">
                                <SaleNotice loanId={selectedLoanId} envId={envId} noticeStatus={noticeStatus} onCloseNotice={onCloseNotice} />
                            </div>
                        }
                        {noticeName === 'consent' &&
                            <div className="w-full  max-h-screen overflow-y-auto rounded-[14px]">
                                <ConsentNotice loanId={selectedLoanId} envId={envId} noticeStatus={noticeStatus} onCloseNotice={onCloseNotice} />
                            </div>
                        }
                        {noticeName === 'arbitration_npa' &&
                            <div className="w-full  max-h-screen overflow-y-auto rounded-[14px]">
                                <DemandNotice loanId={selectedLoanId} envId={envId} noticeStatus={noticeStatus} onCloseNotice={onCloseNotice} />
                            </div>
                        }
                        {noticeName === 'arbitration' &&
                            <div className="w-full max-h-screen overflow-y-auto rounded-[14px]">
                                <Arbitration loanId={selectedLoanId} envId={envId} noticeStatus={noticeStatus} onCloseNotice={onCloseNotice} />
                            </div>
                        }
                        {noticeName === 'arbitration_reminder' &&
                            <div className="w-full max-h-screen overflow-y-auto rounded-[14px]">
                                <Arbitration loanId={selectedLoanId} envId={envId} noticeStatus={noticeStatus} onCloseNotice={onCloseNotice} />
                            </div>
                        }
                    </div>
                )}
            </Popup>

        </>
    )
}

export default ChooseNotice