// src/ImageSlider.js
import React from 'react';
import Buttons from '../../../CommonFunction/Buttons';
import { Link } from 'react-router-dom';
import { CiBank } from 'react-icons/ci';
import { GiThorHammer } from "react-icons/gi";
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { IoDocumentOutline } from 'react-icons/io5';


const product = [
  {
    img: <CiBank size={50} />,
    heading: "BANKING",
    text: "Streamlined Loan Recovery Management Solution.",
    arrow: <MdOutlineArrowForwardIos />,
    link: '/banking'
  },
  {
    img: <GiThorHammer size={50} />,
    heading: "LEGAL",
    text: "Digi-Legal Management Solution For Courts/Forums, Judges/Arbitrators and Laywers.",
    arrow: <MdOutlineArrowForwardIos />,
    link: '/'
  },
  {
    img: <IoDocumentOutline size={50} />,
    heading: "V-DOC",
    text: "Digi-Local Verification, Authentication and Storage of Documents.",
    arrow: <MdOutlineArrowForwardIos />,
    link: '/'
  },

]

const WebsiteDetails = () => {
  

  return (

    <>
      <div className='bg-bgclr8 pt-24 font-avenir shadow-xl'>
        <div className='grid md:grid-cols-2 container justify-center md:px-10 py-20 pt-10 mx-auto md:gap-14'>
          <div className='p-10'>
            <div className='md:text-[54px] text-[30px] md:leading-[65px]'>
              <h2>Clever document solutions</h2>
              <h2>for a Digital India</h2>
            </div>
            <div className='pl-1 pt-3 text-red-600'>
              <span>________</span>
            </div>
            <div className='md:text-[24px] text-[20px] leading-10 pt-4'>
              <h1>
                Emulating Digital India Programme's vision to transform India into a digitally empowered society and knowledge economy.
              </h1>
            </div>
            <div className='mt-7'>
              <Link to='/register'>
                <Buttons.Button3 label="GET STARTED" />
              </Link>
            </div>
            {/* <div>
                <img src={HomeImg} alt="" />
              </div> */}
          </div>
          <div className='md:p-10'>
            <div className='border bg-bgclr9 rounded'>
              <div className='border-b px-9 py-6'>
                <h2>FEATURED APPS</h2>
              </div>
              <div className='p-9'>
                {product.map((items, index) => (
                  <div key={index} className="pb-10 ">
                    <Link to={items.link}>
                      <div className='flex gap-14'>
                        <div className='text-txtclr3'>
                          {items.img}
                        </div>
                        <div>
                          <div className='text-[25px] text-gray-950' >{items.heading}</div>
                          <div className='text-md'>{items.text}</div>
                        </div>
                        <div className='flex  items-center text-txtclr3'>
                          {items.arrow}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <hr /> */}
    </>


    // <div className="relative w-full ">
    //   <div className="overflow-hidden w-full">
    //     <div className="flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
    //       {images.map((imageUrl, index) => (
    //         <div key={index} className="w-full flex-shrink-0">
    //           <img src={imageUrl} alt={`Slide ${index + 1}`} className="w-full" />
    //         </div>
    //       ))}
    //     </div>
    //   </div>

    //   <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
    //     <div className="flex space-x-2">
    //       {images.map((_, index) => (
    //         <button
    //           key={index}
    //           onClick={() => handleIndicatorClick(index)}
    //           className={`w-4 h-4 rounded-full ${
    //             index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'
    //           }`}
    //         ></button>
    //       ))}
    //     </div>
    //   </div>
    // </div>

  );
};

export default WebsiteDetails;
