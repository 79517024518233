import React from 'react';

// Utility function to convert snake_case to Title Case
const convertToTitleCase = (str) => {
  if (typeof str !== 'string') {
    return '';
  }
  return str
    .replace(/_/g, ' ')               // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};

// Reusable component for converting and displaying strings
const TitleCaseConverter = ({ inputString }) => {
  const outputString = convertToTitleCase(inputString);

  return (
    <span>{outputString}</span>
  );
};

export default TitleCaseConverter;
    