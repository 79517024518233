import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AmountToWord, { convertToWords } from "../../../Elements/AmountToWord";
import cookies from "../../../../../../CommonFunction/cookies";
import NoticeUpdateHeader from "../../../Elements/NoticeUpdateHeader";
import { showFailed, showWarning } from "../../../../../../CommonFunction/SweetAlert";
import { NoticeStatusUpdate, ServeNotice, UploadNotice } from "../../../../../../API/Bank/NoticeServe";
import { NoticeLoading, ServeLoading } from "../../../Elements/ServeLoading";
import { LoanByID } from "../../../../../../API/Bank/LoanSearch";
import toast from "react-hot-toast";
import { generatePdfAndUpload } from "../generatePdfAndUpload";

const FirstNotice = ({ loanId, onCloseNotice, envId }) => {

  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const pdfRef = useRef();
  const currentDate = new Date();
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const formattedDate = format(currentDate, "MMMM dd, yyyy");
  const navigate = useNavigate();

  function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  }

  const [noticeDetail, setNoticeDetail] = useState({
    borrowerName: "",
    reservePrice: "",
    authOfficerName: "",
    authOfficerDesignation: "",
    authOfficerContact: "",
    additionalText: "",
    firstDefaultDate: "",
    firstDefaultAmmount: "",
    totalAmmount: "",
  });
  const jwtToken = cookies.get("jwt");
  const [formData, setFormData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      setFetchLoading(true);
      try {
        const data = await LoanByID(jwtToken, loanId);
        setFormData(data);
        setFetchLoading(false)
      } catch (error) {
        setFetchLoading(false)
        showFailed('Failed.', `${error.message}`);
      }
    };
    fetchData();
  }, [jwtToken]);

  const handleNoticeChange = (e) => {
    const { name, value } = e.target;
    setNoticeDetail({ ...noticeDetail, [name]: value });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNoticeDetail({ ...noticeDetail, [name]: value });
  };

  const subjectText = `Immediate Resolution for First Month Payment Default – ${formData?.identity_number
    } for an Amount of Rs. ${formData?.amount} (${convertToWords(
      formData?.amount
    )}) `;

  const generatePdfAndSend = async () => {
    setLoading(true);
    const noticeType = 'B1';
    try {
      await generatePdfAndUpload({
        pdfRef,
        envId,
        loanId,
        jwtToken,
        subjectText,
        UploadNotice,
        ServeNotice,
        NoticeStatusUpdate,
        navigate,
        toast,
        showFailed,
        formData,
        noticeType
      });
    } catch (error) {
      console.error("Error in handleGenerateAndSend:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    const requiredFields = [
      "authOfficerName",
      "authOfficerDesignation",
      "authOfficerContact",
      "firstDefaultDate",
      "firstDefaultAmmount",
      "totalAmmount"
    ];
    requiredFields.forEach((field) => {
      if (!noticeDetail[field]) {
        errors[field] = true;
      }
    });
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      setPreviewOpen(true);

    } else {
      showWarning('Warning...', 'Please fill the required field first.');
    }
  };

  const handleEdit = () => {
    setPreviewOpen(false);
  };

  const borrowerPosition = formData.positions?.find(
    (position) => position.role === "borrower"
  );

  const position = formData?.positions?.find(
    (position) => position.role === "borrower"
  );

  const phoneNumber =
    position?.user?.phone_number ?? position?.organization?.phone_number;

  if (phoneNumber) {

  } else {

  }

  return (
    <>
      {!isPreviewOpen ? (
        <div className="mb-14">
          {fetchLoading ? (
            <NoticeLoading />
          ) : (
            <div>
              <NoticeUpdateHeader onPreview={(e) => handleSubmit(e)} onClose={onCloseNotice} />
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="bg-txtclr w-[838px] mx-auto pt-40 pb-20 px-20">
                  <div className="mb-10">
                    <p>Date: {formattedDate}</p>
                    <p className="text-gray-700">
                      Reference Number:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        CDNC1263546798
                      </span>
                    </p>
                    <p className="text-gray-700">
                      Envelope ID:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        CDENV1263546798
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mb-10">
                    <h2 className="text-lg border-b border-gray-800 font-bold">
                      "WITHOUT PREJUDICE"
                    </h2>
                  </div>
                  <div className="pt-2 pb-7">
                    <h2 className="font-semibold">
                      {borrowerPosition
                        ? borrowerPosition.user
                          ? `Mr. ${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                          : borrowerPosition.organization
                            ? `${borrowerPosition.organization.name}`
                            : "Borrower Name/Organization Not Available"
                        : "Borrower Name/Organization Not Available"}
                    </h2>
                    <h2 className="text-blue-500">{phoneNumber}</h2>
                    {borrowerPosition && borrowerPosition.role === "borrower" && (
                      <h2 className="text-blue-500">
                        {borrowerPosition.user
                          ? ` ${borrowerPosition?.user?.address?.current_address}`
                          : borrowerPosition.organization
                            ? ` ${borrowerPosition?.organization?.address?.city}`
                            : "Address Not Available"}
                      </h2>
                    )}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">Reference:</h2>
                    <p className="ml-20">
                      Loan against Account Number{" "}
                      <span className="font-semibold font-sans">
                        {formData.identity_number}
                      </span>{" "}
                      for an Amount of Rs.{" "}
                      <span className="font-semibold font-sans">
                        {formData.amount} (<AmountToWord amount={formData?.amount} />)
                      </span>
                      .
                    </p>
                  </div>
                  <div className="flex py-10">
                    <h2 className="font-bold">Subject:</h2>
                    <h2 className="font-bold ml-24">
                      Formal Reminder: Immediate Resolution for First Month Payment
                      Default – Loan account number{" "}
                      <span className="font-semibold font-sans">
                        {formData.identity_number}
                      </span>{" "}
                      <span className="font-normal">for an Amount of Rs. </span>
                      <span className="font-semibold font-sans">
                        {formData.amount} (<AmountToWord amount={formData?.amount} />)
                      </span>
                      .
                    </h2>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">
                      Dear{" "}
                      {borrowerPosition &&
                        (borrowerPosition.user
                          ? `${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                          : borrowerPosition.organization?.name)}
                      ,
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-8 pl-5 mt-8">
                      <li className="pb-5">
                        We wish to bring to your attention that, your first instalment
                        payment against the{" "}
                        <span className="font-semibold font-sans">
                          (Loan Account Number - {formData.identity_number})
                        </span>{" "}
                        which was due on{" "}
                        <input
                          type="date"
                          id="firstDefaultDate"
                          name="firstDefaultDate"
                          value={noticeDetail.firstDefaultDate || ""}
                          onChange={handleDateChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.firstDefaultDate ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                          required
                        />{" "}
                        for an Amount of Rs.{" "}
                        <input
                          type="text"
                          placeholder="XXXXXXXXX"
                          name="firstDefaultAmmount"
                          id="firstDefaultAmmount"
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, "");
                          }}
                          value={noticeDetail.firstDefaultAmmount}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.firstDefaultAmmount ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                          required
                        />{" "}
                        , has not been paid and is currently outstanding, as a result
                        of which, your Loan Account is in the state of default.
                      </li>
                      <li className="pb-5">
                        As a consequence of the first default, interest and penalties
                        are now accruing on your Loan Account. We kindly request you
                        to promptly settle the total outstanding ammount Rs.
                        <input
                          type="text"
                          placeholder="XXXXXXXXX"
                          name="totalAmmount"
                          id="totalAmmount"
                          value={noticeDetail.totalAmmount}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, "");
                          }}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.totalAmmount ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                          required
                        />{" "}
                        as on <span className="font-semibold">{formattedDate}</span>{" "}
                        against your Loan Account Number -{" "}
                        <span className="font-sans font-semibold">
                          {formData.identity_number}.
                        </span>
                      </li>
                      <li className="pb-5">
                        Failure to rectify this situation immediately may result in
                        severe consequences, including but not limited to additional
                        penalty on the outstanding amount and damage to your credit
                        profile.
                      </li>
                      <li className="pb-5">
                        Please also note that this reminder is sent to you without
                        prejudice to the other rights and remedies available to{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>{" "}
                        including initiation of the appropriate legal proceedings
                        before the appropriate courts and/or tribunal for recovery of
                        the above said outstanding amount.{" "}
                      </li>
                      <div className="mt-10">
                        <textarea
                          className="border border-gray-500 p-3"
                          name="additionalText"
                          value={noticeDetail.additionalText}
                          onChange={handleNoticeChange}
                          placeholder="You can write your additional content here............"
                          id="additionalText"
                          cols="80"
                          rows="6"
                        ></textarea>
                      </div>
                    </ul>
                  </div>
                  <div className="mt-24">
                    <h2>
                      For,{" "}
                      <span className="text-blue-500 font-semibold">
                        {formData?.bank?.name}
                      </span>
                    </h2>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <input
                        type="text"
                        name="authOfficerName"
                        id="authOfficerName"
                        value={noticeDetail.authOfficerName}
                        onChange={handleNoticeChange}
                        placeholder="[Authorized Officer's Name]"
                        className={`px-1 w-[45%] text-left border-b ${validationErrors.authOfficerName ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        name="authOfficerDesignation"
                        id="authOfficerDesignation"
                        value={noticeDetail.authOfficerDesignation}
                        onChange={handleNoticeChange}
                        placeholder="[Authorized Officer's Designation]"
                        className={`px-1 w-[45%] text-left border-b ${validationErrors.authOfficerDesignation ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        name="authOfficerContact"
                        id="authOfficerContact"
                        value={noticeDetail.authOfficerContact}
                        onChange={handleNoticeChange}
                        placeholder="[Contact Details of the Authorized Officer]"
                        className={`px-1 w-[55%] text-left border-b ${validationErrors.authOfficerContact ? 'border-b-2 placeholder:text-red-600 border-red-500' : 'border-gray-700'}`}
                        required
                      />
                    </div>
                  </div>
                  <div className="text-center mt-20">
                    <button
                      type="submit"
                      className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>

      ) : (
        <div className="font-serif py-10 ">
          {loading ? (
            <ServeLoading />
          ) : (
            <div className="w-[860px] mx-auto">
              <div className="bg-txtclr py-10 pb-28 px-20">
                <div className="flex justify-end text-gray-500 mt-5 mb-3">
                  <button
                    type="submit"
                    onClick={() => {
                      handleEdit();
                    }}
                    className="text-[15px] px-2 text-txtclr bg-black font-sans rounded-sm"
                  >
                    X
                  </button>
                </div>
                <div ref={pdfRef} className="p-4">
                  <div className="mb-10">
                    <p>Date: {formattedDate}</p>
                    <p className="text-gray-700">
                      Reference Number:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        CDNC1263546798
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mb-10">
                    <h2 className="text-lg border-b border-gray-800 font-bold">
                      "WITHOUT PREJUDICE"
                    </h2>
                  </div>
                  <div className="pt-2 pb-7">
                    <h2 className="font-semibold">
                      {borrowerPosition
                        ? borrowerPosition.user
                          ? `Mr. ${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                          : borrowerPosition.organization
                            ? `${borrowerPosition.organization.name}`
                            : "Borrower Name/Organization Not Available"
                        : "Borrower Name/Organization Not Available"}
                    </h2>
                    <h2 className="text-blue-500">{phoneNumber}</h2>
                    {borrowerPosition &&
                      borrowerPosition.role === "borrower" && (
                        <h2 className="text-blue-500">
                          {borrowerPosition.user
                            ? ` ${borrowerPosition?.user?.address?.current_address}`
                            : borrowerPosition.organization
                              ? ` ${borrowerPosition?.organization?.address?.city}`
                              : "Address Not Available"}
                        </h2>
                      )}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">Reference:</h2>
                    <p className="ml-20">
                      Loan against Account Number{" "}
                      <span className="font-semibold font-sans">
                        {formData.identity_number}
                      </span>{" "}
                      for an Amount of Rs.{" "}
                      <span className="font-semibold font-sans">
                        {formData.amount} (
                        <AmountToWord amount={formData?.amount} />)
                      </span>
                      .
                    </p>
                  </div>
                  <div className="flex py-10">
                    <h2 className="font-bold">Subject:</h2>
                    <h2 className="font-bold ml-24">
                      Formal Reminder: Immediate Resolution for First Month
                      Payment Default – Loan account number{" "}
                      <span className="font-semibold font-sans">
                        {formData.identity_number}
                      </span>{" "}
                      <span className="font-normal">
                        for an Amount of Rs.{" "}
                      </span>
                      <span className="font-semibold font-sans">
                        {formData.amount} (
                        <AmountToWord amount={formData?.amount} />)
                      </span>
                      .
                    </h2>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">
                      Dear{" "}
                      {borrowerPosition &&
                        (borrowerPosition.user
                          ? `${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                          : borrowerPosition.organization?.name)}
                      ,
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-8 pl-5 mt-8">
                      <li className="pb-5">
                        We wish to bring to your attention that, your first
                        instalment payment against the{" "}
                        <span className="font-semibold font-sans">
                          (Loan Account Number - {formData.identity_number})
                        </span>{" "}
                        which was due on{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.firstDefaultDate}
                        </span>{" "}
                        for an Amount of Rs.{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.firstDefaultAmmount}
                        </span>{" "}
                        , has not been paid and is currently outstanding, as a
                        result of which, your Loan Account is in the state of
                        default.
                      </li>
                      <li className="pb-5">
                        As a consequence of the first default, interest and
                        penalties are now accruing on your Loan Account. We
                        kindly request you to promptly settle the total
                        outstanding ammount Rs.{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.totalAmmount}
                        </span>{" "}
                        as on{" "}
                        <span className="text-blue-500">{formattedDate}</span>{" "}
                        against your Loan Account Number -{" "}
                        <span className="font-sans font-semibold">
                          {formData.identity_number}.
                        </span>
                      </li>
                      <li className="pb-5">
                        Failure to rectify this situation immediately may
                        result in severe consequences, including but not
                        limited to additional penalty on the outstanding
                        amount and damage to your credit profile.
                      </li>
                      <li className="pb-5">
                        Please also note that this reminder is sent to you
                        without prejudice to the other rights and remedies
                        available to{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}
                        </span>{" "}
                        including initiation of the appropriate legal
                        proceedings before the appropriate courts and/or
                        tribunal for recovery of the above said outstanding
                        amount.{" "}
                      </li>
                      <li className="pb-5">{noticeDetail.additionalText}</li>
                    </ul>
                  </div>
                  <div className="mt-24">
                    <h2>
                      For,{" "}
                      <span className="text-blue-500 font-semibold">
                        {formData?.bank?.name}
                      </span>
                    </h2>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerName}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerDesignation}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerContact}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-16">
                  <button
                    type="submit"
                    onClick={generatePdfAndSend}
                    className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FirstNotice;
