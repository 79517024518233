import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import cookies from '../../../../../CommonFunction/cookies';
import { showToast } from '../../../../../CommonFunction/Toast';
import Loader from '../../../../../CommonFunction/Loader';
import { HiShieldCheck } from 'react-icons/hi';
import { showFailed } from '../../../../../CommonFunction/SweetAlert';
import toast from 'react-hot-toast';

const WhatsAppVerification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const jwtToken = cookies.get('jwt');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const verifyWhatsApp = async () => {
            try {
                const queryString = location.search.substring(1); // Remove leading '?'
                const params = queryString.split('_');
                const number = params[0].split('=')[1];
                const code = params[1].split('=')[1];
                const role = params[2].split('=')[1];

                const response = await fetch(
                    `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/${role}/whatsapp/verify-otp`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: 'Bearer ' + jwtToken,
                        },
                        body: JSON.stringify({
                            device_id: number,
                            code: code
                        }),
                    }
                );

                const result = await response.json();
                

                if (response.ok && response.status === 200) {
                    toast.success(`WhatsApp Number Verified.`);
                } else {
                    setError(true);
                    showFailed('Failed.',`${result.error}`)
                    console.error("WhatsApp verification failed:", result.error);
                }
            } catch (error) {
                showFailed('Failed.',`${error}`)
                console.error("Error during WhatsApp verification:", error);
            } finally {
                setLoading(false);
            }
        };

        verifyWhatsApp();
    }, [navigate, jwtToken, location.search]);

    if (loading) {
        // Render a loading indicator here
        return <div><Loader /></div>;
    }

    return (
        <>
            <div className='container mx-auto '>
                <div className='flex justify-center items-center h-[65vh] bg-bgclr8'>
                    <div className='text-center'>
                        {error === true ? (
                            <h1 className='flex md:text-[40px] text-[22px] text-red-500 font-bold'><span className='drop-shadow-lg shadow-black'>WhatsApp Verification Failed.</span></h1>                 
                        ) : (
                            <h1 className='flex md:text-[40px] text-[22px] text-green-600 font-bold'><span className='drop-shadow-lg shadow-black'>WhatsApp Number Verified</span> <HiShieldCheck className='ml-2 mt-2' /></h1>                 
                        )}
                       </div>
                </div>
            </div>
        </>
    )
};

export default WhatsAppVerification;
