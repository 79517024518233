import React from 'react';

const NoticeUpdateHeader = ({ onPreview, onClose }) => {
  return (
    <div className="flex justify-between fixed w-full items-center bg-[#1C1C1C] text-txtclr px-10 py-6">
      <div className="text-[22px]">
        <h2>[You can fill, upload and attach additional document in our formatted document.]</h2>
      </div>
      <div className="flex gap-3">
        <button
          type="button"
          onClick={onPreview}
          className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
        >
          Preview
        </button>
        <button
          type="submit"
          onClick={onClose}
          className="text-[25px] px-5 text-black bg-txtclr rounded-md"
        >
          X
        </button>
      </div>
    </div>
  );
};

export default NoticeUpdateHeader;
