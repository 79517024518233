import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cookies from '../../../CommonFunction/cookies';
import { fetchBlogs } from '../../../API/BlogsAPI';
import Loader from '../../../CommonFunction/Loader';

const Blog = () => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);

    // Get Data
    useEffect(() => {
        const fetchBlogsData = async () => {
            try {
                const data = await fetchBlogs();
                setData(data.docs);
                
            } catch (error) {
                console.error('Error retrieving user data:', error);
            }
        };

        fetchBlogsData();

    }, []);

    const handleCardDetails = (id) => {
        navigate(`/blogsDetails/${id}`);
    }

    if (!data) {
        return <div><Loader /></div>;
    }

    return (
        <>
            <div className='md:pt-28 lg:pt-32 xl:pt-28 pt-28 font-avenir'>
                <div className='bg-bgclr8'>
                    <div className='mt-2 container mx-auto md:px-32 md:py-10 px-5 py-10'>
                        <div className='flex text-[54px]'>
                            <h1>The Blogs</h1>
                        </div>
                        <div className='pl-1 -mt-2 mb-1 text-red-600'>
                            <span>________</span>
                        </div>
                        <div className='mt-10'>
                            <div className='flex text-[23px] font-semibold'>
                                <h1>Recent blog post</h1>
                            </div>
                            <div className='grid md:grid-cols-5 sm:grid-cols-2 gap-10 mt-5'>
                                {data.length > 0 && (
                                    <div className='md:col-span-2'>
                                        <button onClick={() => handleCardDetails(data[0]._id)}>
                                            <img src={data[0].image} alt={data[0].title} />
                                        </button>
                                    </div>
                                )}
                                {data.length > 0 && (
                                    <div className='col-span-3'>
                                        <button onClick={() => handleCardDetails(data[0]._id)}>
                                            <h2 className='text-[#6941C6] font-semibold'>{data[0].publish_date}</h2>
                                            <h1 className='font-semibold my-2'>{data[0].title}</h1>
                                            <p className='text-[#667085]'>{data[0].description}</p>
                                            <div className='flex mt-5 gap-10'>
                                                <p className='text-[#667085]'><span className='font-semibold text-black'>Author: </span>{data[0].author}</p>
                                                <p className='bg-[#EEF4FF] text-[#6941C6] px-3 text-[14px] font-semibold flex items-center rounded-xl'>{data[0].category}</p>
                                            </div>
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className='mt-10'>
                                <div className='flex text-[23px] font-semibold mb-6 ml-3'>
                                    <h1>All blog posts</h1>
                                </div>
                                <div className="flex flex-wrap">
                                    {data.slice(1).map((card, index) => (
                                        <button key={index} onClick={() => handleCardDetails(card._id)} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 ">
                                            <div className="m-3 mb-10 text-left">
                                                <img src={card.image} alt={card.title} className="w-72 h-72 object-cover mb-4" />
                                                <p className="text-[#6941C6] font-semibold">{card.publish_date}</p>
                                                <h2 className="text-lg font-semibold my-2.5">{card.title}</h2>
                                                <p className="text-gray-600 mb-4">{card.description}</p>
                                                <div className='flex mt-5 gap-5'>
                                                    <p className='text-[#667085]'><span className='font-semibold text-black'>Author: </span>{card.author}</p>
                                                    <p className='bg-[#EEF4FF] text-[#6941C6] px-3 text-[14px] font-semibold flex items-center rounded-xl'>{card.category}</p>
                                                </div>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Blog;
