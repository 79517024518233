import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import ColumnChart from '../../Elements/ColumnChart';
import { DashboardDetails, NotificationDetails } from '../../../../../API/Bank/Dashboard';
import cookies from '../../../../../CommonFunction/cookies';
import Loader from '../../../../../CommonFunction/Loader';
import { setISODay } from 'date-fns';

const BankDashboard = () => {
    const [value, onChange] = useState(new Date());
    const [dashboardData, setDashboardData] = useState([]);
    const [notificationData, setNotificationData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const jwtToken = cookies.get('jwt');

    useEffect(() => {
        const fetchDashboardDetails = async () => {
            setIsLoading(true);
            try {
                const data = await DashboardDetails(jwtToken);
                setDashboardData(data?.response);
            } catch (error) {
                setIsLoading(false);
                console.error('Error retrieving organization data:', error);
            }
        };

        const fetchNotificationDetails = async () => {
            try {
                const data = await NotificationDetails(jwtToken);
                setNotificationData(data?.response?.response);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error('Error retrieving organization data:', error);
            }
        };
        fetchDashboardDetails();
        fetchNotificationDetails();
    }, [setDashboardData]);

    const data = [
        {
            name: 'Total Active Loan Accounts',
            loans: `${dashboardData?.loanCount || ''}`,
            percent: '+3.48%'
        },
        {
            name: 'Loan Acc. with Default Reminders',
            loans: `${dashboardData?.loanDefaultRemainderCount || ''}`,
            percent: '+3.48%'
        },
        {
            name: 'Loan Account in NPA',
            loans: `${dashboardData?.NpaDefaultRemainderCount || ''}`,
            percent: '+3.48%'
        },
        {
            name: 'KYC Defaulters',
            loans: `${dashboardData?.kycDefaulters || ''}`,
            percent: '+3.48%'
        },
        {
            name: 'Delivery Default',
            loans: `${dashboardData?.deliveryDefault}`,
            percent: '+3.48%'
        },
    ]

    const message = [
        {
            name: 'Surya Pratap',
            message: 'This process helps identify images on a webpage that are not loading images on a webpage that are not loading',
            time: '5 Min',
            status: 'unread'
        },
        {
            name: 'Rahul Sharma',
            message: 'This process helps identify images on a webpage that are not loading images on a webpage that are not loading',
            time: '5 Min',
            status: 'read'
        }
    ]

    return (
        <>
            <div className="root-style">
                <div className="w-[975px] mt-6 mb-20">
                    {isLoading ? (
                        <div>
                            <Loader />
                        </div>
                    ) : (
                        <div>
                            <div className="text-[24px] font-[600] my-5 text-bgclr6">
                                <h1>Overview</h1>
                            </div>
                            <div className='grid grid-cols-4 gap-5 mr-3'>
                                {data.map((item, index) => (
                                    <div className='bg-bgclr9 pl-5 pr-1 py-5 rounded-3xl shadow-xl'>
                                        <p className='text-[14px] text-[#A0AEC0]'>{item.name}</p>
                                        <h3 className='text-[18px] text-[#2D3748] font-[700]'>{item.loans}</h3>
                                        <div className='flex items-end mt-5'>
                                            <h2 className='text-[14px] text-[#48BB78] font-[700]'>{item.percent}</h2>
                                            <p className='text-[14px] text-[#A0AEC0] ml-3'>Since last month</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='flex mt-10 gap-5'>
                                <div className='w-[60%] rounded-xl bg-bgclr9 shadow-lg'>
                                    <div className='flex justify-between px-3 py-3 border-b'>
                                        <div className="text-[24px] font-[600] text-bgclr6">
                                            <h1>Notifications</h1>
                                        </div>
                                        <div className='flex gap-4'>
                                            <button className='bg-pink-50 px-3 rounded-md font-[500]'>Notice</button>
                                            <button className='bg-pink-50 px-3 rounded-md font-[500]'>POD</button>
                                            <button className='bg-pink-50 px-3 rounded-md font-[500]'>KYC Default</button>
                                        </div>
                                    </div>
                                    <div>
                                        {notificationData.map((item, index) => (
                                            <div className={`pl-5 pr-1 py-5 border-b ${item.status === 'unread' ? 'bg-bgclr8' : 'bg-bgclr9'}`}>
                                                <div className='flex justify-between'>
                                                    <h2 className='text-[18px] text-bgclr font-[700]'>{item.name}{item.first_name} {item.middle_name} {item.last_name}</h2>
                                                    <p className='text-[14px] text-[#A0AEC0] mx-3'>{item.time}</p>
                                                </div>
                                                <p className='text-[16px] text-[#A0AEC0] mt-3 mr-10'>{item.message}</p>
                                            </div>
                                        ))}
                                        <div className='flex justify-center rounded-b-xl bg-[#E0E6FF]'>
                                            <button className='py-3 text-[16px] text-bgclr font-semibold'>
                                                View All
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-[40%] rounded-xl bg-bgclr9 shadow-lg'>
                                    <div>
                                        <div className="text-[24px] px-3 font-[600] py-3 text-bgclr6">
                                            <h1>Calender</h1>
                                        </div>
                                        <div className='flex justify-center'>
                                            <Calendar
                                                onChange={onChange}
                                                value={value}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-10 bg-bgclr9 p-5 rounded-xl border'>
                                <div className='mb-3'>
                                    <h2 className='text-[24px] font-[500] '>User Statistics</h2>
                                </div>
                                <div>
                                    <ColumnChart />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default BankDashboard